import React from 'react';
import { Row, Col, Typography } from 'antd';

import { COLORS } from '../../constants';
import { TestimonalCard } from '../../components';

const { WHITE } = COLORS;
const { Title } = Typography;

const width = window.innerWidth;

const Testimonials: React.FC = () => {
  return (
    <>
      <Row
        style={{
          padding: '0px 16px',
          marginBottom: 8,
        }}
      >
        <Col span={24} style={{ background: `${WHITE}`, borderRadius: 8, padding: '26px 16px' }}>
          <Title level={3} style={{ fontWeight: 500, marginBottom: 0 }}>
            Testimonials
          </Title>
        </Col>
      </Row>
      <Row style={{ padding: '0px 16px', width: '100%', margin: '0px 0px 8px 0px' }} gutter={width < 768 ? [0, 0] : [8, 0]}>
        <Col
          span={8}
          xxl={{ span: 8 }}
          xl={{ span: 8 }}
          lg={{ span: 8 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ paddingLeft: 0 }}
        >
          <TestimonalCard
            img={'./assets/testimonals1.png'}
            name={'Diana'}
            comment={`Although I was in the middle of my career, I realized that it is never too late to change 
            your course if you really feel that need. ProfLab results confirmed my determination to 
            pursue a new career in marketing. It was a very valuable experience for me to register 
            at ProfLab.`}
          />
          <TestimonalCard
            img={'./assets/testimonals4.png'}
            name={'Anna'}
            comment={`ProfLab gave me a useful insight into my future career path. By answering a series of 
            questions I was able to better understand my past experience, life preferences and 
            career goals. It took me just twenty minutes to fill in the form and answer the test 
            questions. So easy, quick and effective! `}
          />
        </Col>
        <Col
          span={8}
          xxl={{ span: 8 }}
          xl={{ span: 8 }}
          lg={{ span: 8 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ paddingRight: width < 992 ? 0 : 4 }}
        >
          <TestimonalCard
            img={'./assets/testimonals2.jpg'}
            name={'Mickael'}
            comment={`I have always loved my job, but at some point I had a burnout. I needed a change 
              although I did not want to quit medicine. ProfLab suggested that I become a healthcare 
              management specialist. This sounds like a perfect solution for me. I am sure this tool 
              will help lots of people in their search for new career choices.`}
          />
          <TestimonalCard
            img={'./assets/testimonals5.png'}
            name={'Karen'}
            comment={`Engaging with ProfLab career development program has been an eye-opening 
            experience. It helped me identify my strengths and weaknesses and find the perfect 
            career for me. The interface was easy to use and navigate. I really liked the test 
            questions, which made me contemplate my previous career and job experience.`}
          />
        </Col>
        <Col
          span={8}
          xxl={{ span: 8 }}
          xl={{ span: 8 }}
          lg={{ span: 8 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ paddingRight: width < 992 && width > 768 ? 4 : 0, paddingLeft: width < 992 ? 0 : 4 }}
        >
          <TestimonalCard
            img={'./assets/testimonals3.png'}
            name={'Maria'}
            comment={`I had a stressful job and have always dreamt of changing it. ProfLab gave me the right 
            answer at the right time. And now I am learning a new profession that I will enjoy every 
            day. This was truly a life-changing experience for me and I highly recommend it to 
            everyone who wants to make positive changes in their career.`}
          />
          <TestimonalCard
            img={'./assets/testimonals6.png'}
            name={'Helen'}
            comment={`As a woman who recently returned to the workforce after a long maternity leave, I can 
            confidently say that ProfLab has been a game-changer for me. When I decided to 
            change my previous career and explore new opportunities, I felt unsure of where to 
            start. However, ProfLab provided invaluable guidance in choosing my new career path
            by focusing on my strengths and addressing any gaps that needed attention. I highly 
            recommend ProfLab to anyone seeking to make a successful change after a long pause
            in their career.`}
          />
        </Col>
        <Row style={{ height: 0 }}>
          <div
            style={{
              height: 350,
              position: 'relative',
              bottom: 350,
              background: 'linear-gradient(rgba(135, 206, 235, 0) -80%, rgba(135, 206, 235, 0) -47%, rgb(246 248 248) 100%)',
              width: '100vw',
            }}
          ></div>
        </Row>
      </Row>
    </>
  );
};

export default Testimonials;
