import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Space, Typography, Col } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';

import { ProfessionCard, AsnButton } from '../../components';
import { useAuth } from '../../hooks/useAuth';
import { useResendAnswers, useCheckProfession } from '../../api';
import { COLORS, PATHS } from '../../constants';
import { IFinalPhase } from '../../types/chat';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { MainButton } from '../../components/Button';

const { Paragraph } = Typography;
const { WHITE } = COLORS;

const AsnRow = styled(Row)`
  background: linear-gradient(90deg, #634d7c 0%, rgba(104, 70, 142, 0) 100%);
  height: 6px;
  animation: gradient 10s linear infinite;
  background-size: 200% 100%;
  @keyframes gradient {
    0%,
    100% {
      background-position: 100% 0%;
    }
    50% {
      background-position: -100% 0%;
    }
  }
`;

const FinalPhase: React.FC<IFinalPhase> = ({ userId, professions, setSearchParams, isLoading }) => {
  const [expanded, setExpanded] = useState<string>('');
  const { user, addUser } = useAuth();
  const wibdowWidth = window.innerWidth;

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: resendAnswers, isLoading: isLoadingResend } = useResendAnswers();
  const { mutate: checkProfession } = useCheckProfession();

  const checkedProfession = professions?.filter((item) => item.checked === true);

  const handleNext = (): void => {
    if (expanded) {
      checkProfession({ userId, professionId: expanded });
      if (user) {
        addUser({ ...user, hesSelectedProfession: true });
      }
    }

    navigate(`${PATHS.CAREER_PATHS}?menu=My Career Paths`);
  };

  const tryAgain = (): void => {
    const params = { menu: 'Chat', status: 'loading' };
    setSearchParams(params);
    resendAnswers(userId, {
      onSuccess: () => {
        void queryClient.invalidateQueries(['/api/career-coaching/user/:userId/professions']);
      },
      onError: () => {
        void queryClient.invalidateQueries(['/api/career-coaching/user/:userId/professions']);
      },
    });
  };

  const handleCheckProfession = (id: string): void => {
    if (checkedProfession?.length) return;
    setExpanded(id);
  };

  const navigateBack = (): void => {
    const params = { menu: 'Chat' };
    setSearchParams(params);
  };

  return (
    <Row justify={'center'} style={{ paddingTop: 44 }}>
      <Space direction='vertical' size={32} style={{ marginBottom: 16 }}>
        <Paragraph style={{ maxWidth: wibdowWidth < 768 ? 'auto' : '42vw', fontSize: 16, padding: 16 }}>
          Congratulations on reaching the final phase! Our AI-based career coach is currently reviewing your answers. Once the
          analysis is complete, you will receive a selection of four career paths that are most suitable according to your test
          results.
        </Paragraph>
        {!professions?.length && (isLoading || isLoadingResend) && <AsnRow />}
        {!professions?.length && !isLoading && (
          <>
            {!isLoadingResend && (
              <Paragraph style={{ maxWidth: wibdowWidth < 768 ? 'auto' : '42vw', fontSize: 16, textAlign: 'center' }}>
                Oops, something went wrong.
              </Paragraph>
            )}
            <Row justify={'center'}>
              <MainButton text={'Try again'} icon={<DotsSvg />} onClick={tryAgain} />
            </Row>
          </>
        )}
        {!!professions?.length && (
          <Paragraph style={{ maxWidth: wibdowWidth < 768 ? 'auto' : '42vw', fontSize: 16, padding: 16 }}>
            Please, review the suggested four career paths, choose one that you deem is the most compatible with you, and our
            system will generate a selection of relevant learning programs.
          </Paragraph>
        )}
      </Space>
      {!!professions?.length && (
        <Row style={{ background: `${WHITE}`, padding: '32px 2%', width: '100%' }} gutter={[22, 22]} wrap>
          {professions?.map((profession) => (
            <Col key={profession?.id} xl={{ span: 6 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
              <ProfessionCard
                onClick={(): void => handleCheckProfession(profession?.id)}
                className={expanded === profession?.id || profession?.checked ? 'selected' : ''}
              >
                <Paragraph
                  style={{ fontSize: 20, textAlign: 'center' }}
                  // ellipsis={{ rows: expanded === profession?.id || profession?.checked ? 0 : 2 }}
                >
                  {profession?.title}
                </Paragraph>
                <Paragraph
                  style={{ textAlign: 'center', marginBottom: 0 }}
                  // ellipsis={{ rows: expanded === profession?.id || profession?.checked ? 0 : 4 }}
                >
                  {profession?.description}
                </Paragraph>
              </ProfessionCard>
            </Col>
          ))}
        </Row>
      )}
      {wibdowWidth < 768
        ? !!professions?.length && (
            <Row justify={'end'} style={{ padding: '32px 2%', width: '100%', gap: 16 }}>
              <MainButton
                text={'Next'}
                icon={<DotsSvg />}
                disabled={!expanded && !checkedProfession.length}
                onClick={handleNext}
                style={{ width: '100%' }}
              />
              {!!checkedProfession?.length && (
                <AsnButton onClick={navigateBack} style={{ width: '100%' }}>
                  Back
                </AsnButton>
              )}
            </Row>
          )
        : !!professions?.length && (
            <Row justify={'end'} style={{ padding: '32px 2%', width: '100%', gap: 16 }}>
              {!!checkedProfession?.length && <AsnButton onClick={navigateBack}>Back</AsnButton>}
              <MainButton
                text={'Next'}
                icon={<DotsSvg />}
                disabled={!expanded && !checkedProfession.length}
                onClick={handleNext}
              />
            </Row>
          )}
    </Row>
  );
};

export default FinalPhase;
