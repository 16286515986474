import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Space, Input, Typography } from 'antd';

import { useSignIn } from '../../api';
import { AsnButton, AsnForm } from '../../components';
import { COLORS, validate_messages, PATHS } from '../../constants';
import { ISignInValues, IUserData } from '../../types/auth';
import { ReactComponent as LogoSvg } from '../../assets/icons/logoBig.svg';
import { ReactComponent as Logo } from '../../assets/icons/proflab.svg';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { MainButton } from '../../components/Button';

import { LogoWrapper } from './styles';

const { Title } = Typography;
const { LAVENDER } = COLORS;

const SignIn: React.FC = () => {
  const [form] = AsnForm.useForm();
  const navigate = useNavigate();
  const { mutate: signIn } = useSignIn();

  const onFinish = (values: ISignInValues): void => {
    signIn(values, {
      onSuccess: () => {
        const userDataString = localStorage.getItem('user');
        if (userDataString) {
          const userData = JSON.parse(userDataString) as IUserData;
          if (userData.hesSelectedProfession === true) {
            navigate(`${PATHS.CAREER_PATHS}?menu=My%20Career%20Paths`);
          } else {
            navigate(`${PATHS.MY_INFO}?menu=My info`);
          }
        }
      },
    });
  };
  const wibdowWidth = window.innerWidth;

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ width: '100%', height: '100%' }}>
      {wibdowWidth >= 575 ? (
        <LogoWrapper span={12} xs={24} sm={12} md={12} lg={12}>
          <LogoSvg onClick={(): void => navigate(PATHS.LANDING)} style={{ cursor: 'pointer', width: '100%' }} />
        </LogoWrapper>
      ) : (
        <Space direction='horizontal' style={{ padding: '20px 30px', width: '100%', height: 64 }}>
          <Logo onClick={(): void => navigate(PATHS.LOGGED_USER_LANDING)} style={{ cursor: 'pointer' }} />
        </Space>
      )}
      <Col span={12} xs={24} sm={12} md={12} lg={12}>
        <Row style={{ width: '100%', height: '100%' }} justify={'center'} align={'middle'}>
          <AsnForm
            layout='vertical'
            form={form}
            validateMessages={validate_messages}
            style={{ maxWidth: '460px', width: '85vw', padding: ' 0 10px' }}
            onFinish={(values): void => onFinish(values as ISignInValues)}
          >
            <Title level={3} style={{ textAlign: 'center', color: `${LAVENDER}`, marginBottom: '32px' }}>
              Log In
            </Title>
            <AsnForm.Item
              name='email'
              label='Email address'
              rules={[{ required: true, type: 'email', message: 'Invalid email address' }]}
            >
              <Input placeholder='Email address' />
            </AsnForm.Item>
            <AsnForm.Item name='password' label='Password' rules={[{ required: true }]} style={{ marginBottom: 4 }}>
              <Input.Password placeholder='Password' />
            </AsnForm.Item>
            <AsnButton className='text' type='text' onClick={(): void => navigate(`${PATHS.FORGOT_PASSWORD}`)}>
              Forgot Password ?
            </AsnButton>
            <AsnForm.Item>
              <MainButton text={'Log In'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
            </AsnForm.Item>
          </AsnForm>
        </Row>
      </Col>
    </Row>
  );
};

export default SignIn;
