import React from 'react';
import { Space } from 'antd';

import { AsnForm, AsnRadio } from '../../components';
import { COLORS } from '../../constants';
import { IChoicesField } from '../../types/chat';

const { INDIGO } = COLORS;

const RadioField: React.FC<IChoicesField> = ({ edit, name, answers }) => {
  const form = AsnForm.useFormInstance();
  const wibdowWidth = window.innerWidth;

  return (
    <AsnForm.Item name={[name, 'answers', 0]}>
      <AsnRadio.Group
        style={{ maxWidth: wibdowWidth < 768 ? 'auto' : '42vw', width: '100%', marginBottom: 16 }}
        disabled={edit !== form?.getFieldValue(['answers', name, 'questionId'])}
      >
        <Space direction='vertical'>
          {answers?.map((answer) => (
            <AsnRadio key={answer?.id} style={{ color: `${INDIGO}`, fontWeight: 600 }} value={answer?.id}>
              {answer?.title}
            </AsnRadio>
          ))}
        </Space>
      </AsnRadio.Group>
    </AsnForm.Item>
  );
};

export default RadioField;
