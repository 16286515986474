import { useQuery } from '@tanstack/react-query';

import client from '../../client';
import { TUseGetStaticData, TGetConstants } from '../../../types/api/myInfo';

const url = '/api/user/language';

const useGetLanguages: TUseGetStaticData = (language, options) => {
  const result = useQuery({
    queryKey: [url, language],
    queryFn: () => client.post(url, { search: language }),
    ...options,
    select: (data) => data?.data?.result,
  });

  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ([] as TGetConstants[]),
    isLoading,
    isFetching,
  };
};

export default useGetLanguages;
