import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Row, Col, Spin } from 'antd';

import { useGetUserById } from '../../api';
import { useAuth } from '../../hooks/useAuth';
import { PATHS } from '../../constants';
import HomepageLayoutMoabile from '../../components/layouts/HomepageLatoutMobaile';
import { MainButton } from '../../components/Button';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';

import Education from './Education';
import Experience from './Experience';
import Languages from './Languages';
import Skills from './Skills';
import Trainings from './Trainings';
import Licenses from './Licenses';
import UserDetailsMobaile from './UserDetalisMobaile';
import UserDetails from './UserDetails';

// import UploadCv from './UploadCv';

const MyInfo: React.FC = () => {
  const { user, addUser } = useAuth();
  const [field, setField] = useState('userDetails');
  const navigate = useNavigate();
  const wibdowWidth = window.innerWidth;

  const { data, isLoading, isFetching: isFetchingUser } = useGetUserById(user?.id as string, { enabled: Boolean(user?.id) });

  useEffect(() => {
    if (!isFetchingUser && data?.points?.totalPoints <= 100 && user) {
      addUser({ ...user, totalPoints: data?.points?.totalPoints });
    }
  }, [isFetchingUser]);

  return (
    <>
      <Space size={32} style={{ width: '100%', background: '#F6F6F6' }} direction='vertical'>
        {isLoading || (isFetchingUser && field === 'userDetails') ? (
          <Row justify={'center'}>
            <Spin />
          </Row>
        ) : (
          <>
            {wibdowWidth < 768 ? (
              <HomepageLayoutMoabile points={data?.points}>
                <UserDetailsMobaile
                  firstName={data?.firstName}
                  lastName={data?.lastName}
                  email={data?.email}
                  aditional={data?.aditional}
                  id={data?.id}
                  points={data?.points}
                  setField={setField}
                  isLastLogin={user?.isLastLogin as boolean}
                />
              </HomepageLayoutMoabile>
            ) : (
              <UserDetails
                firstName={data?.firstName}
                lastName={data?.lastName}
                email={data?.email}
                aditional={data?.aditional}
                id={data?.id}
                points={data?.points}
                setField={setField}
                isLastLogin={user?.isLastLogin as boolean}
              />
            )}

            <Education
              id={data?.id}
              educations={data?.educations}
              points={data?.points?.education}
              isFetchingUser={field === 'education' ? isFetchingUser : false}
              setField={setField}
            />

            <Experience
              userId={data?.id}
              experiences={data?.experiens}
              points={data?.points?.experience}
              isFetchingUser={field === 'experience' ? isFetchingUser : false}
              setField={setField}
            />
            <Languages
              userId={data?.id}
              languages={data?.languages}
              points={data?.points?.languages}
              isFetchingUser={field === 'languages' ? isFetchingUser : false}
              setField={setField}
            />
            <Skills
              userId={data?.id}
              skillsData={data?.skills}
              points={data?.points?.skills}
              isFetchingUser={field === 'skills' ? isFetchingUser : false}
              setField={setField}
            />
            <Trainings
              userId={data?.id}
              trainings={data?.trainings}
              points={data?.points?.training}
              isFetchingUser={field === 'trainings' ? isFetchingUser : false}
              setField={setField}
            />
            <Licenses
              userId={data?.id}
              licenses={data?.licenses}
              points={data?.points?.licenses}
              isFetchingUser={field === 'licenses' ? isFetchingUser : false}
              setField={setField}
            />
            {/* <UploadCv
              userId={data?.id}
              cvId={data?.cvs[0]?.id}
              points={data?.points?.cvAttachment}
              fileName={data?.cvs[0]?.fileName}
              setField={setField}
            /> */}
            <Row style={{ padding: '0px 4% 32px 4%' }}>
              <Col span={24} xxl={{ span: 21, push: 3 }}>
                <Row justify={'end'} style={{ maxWidth: 1100 }}>
                  <MainButton
                    text={'Open Chat'}
                    icon={<DotsSvg />}
                    disabled={data?.points?.totalPoints > 49 ? false : true}
                    onClick={(): void => navigate(`${PATHS.CHAT}?menu=Chat`)}
                  />
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Space>
    </>
  );
};

export default MyInfo;
