import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/hy_AM';

import { antTheme } from './assets/antd';
import { AuthProvider } from './context';
import { routers } from './routes';

import 'antd/dist/reset.css';

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <ConfigProvider
      theme={antTheme}
      locale={locale}
      getPopupContainer={(node): HTMLElement => {
        if (node) {
          return node.parentNode as HTMLElement;
        }
        return document.body;
      }}
    >
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={routers} />
        </QueryClientProvider>
      </AuthProvider>
    </ConfigProvider>
  );
};

export default App;
