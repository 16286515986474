import React from 'react';
import { Modal, Typography, Space } from 'antd';

import { COLORS } from '../../constants';
import AsnButton, { MainButton } from '../Button';
import { IDeleteModal } from '../../types/components';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';

const { Text } = Typography;
const { INDIGO } = COLORS;

const DeleteModal: React.FC<IDeleteModal> = ({ openDeleteModal, setOpenDeleteModal, title, handleDelete }) => {
  return (
    <Modal
      open={openDeleteModal}
      centered
      footer={false}
      bodyStyle={{ padding: '50px 0px' }}
      onCancel={(): void => setOpenDeleteModal(false)}
    >
      <Space direction='vertical' style={{ width: '100%' }} align='center' size={32}>
        <Text style={{ fontSize: 16, color: `${INDIGO}` }}>{title}</Text>
        <Space size={32}>
          <AsnButton onClick={(): void => setOpenDeleteModal(false)}>No</AsnButton>
          <MainButton text={'Yes'} icon={<DotsSvg />} onClick={(): void => handleDelete()} />
        </Space>
      </Space>
    </Modal>
  );
};

export default DeleteModal;
