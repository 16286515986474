import React, { useState, useEffect } from 'react';
import { Space, Row, Input, RadioChangeEvent, Col } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

import { AsnRadio, AsnCheckbox } from '../../components';
import { COLORS } from '../../constants';
import { ReactComponent as SendSvg } from '../../assets/icons/send.svg';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { IAnswerField } from '../../types/chat';
import { IAnswers } from '../../types/api/chat';
import { normalize } from '../../helpers';
import { MainButton } from '../../components/Button';

const { WHITE, INDIGO, MAIN_BG } = COLORS;

const AnswerField: React.FC<IAnswerField> = ({
  questions,
  questionIndex,
  form,
  setQuestionIndex,
  setAnsweredQuestion,
  loading,
  setLoading,
}) => {
  const [textAnswer, setTextAnswer] = useState<string>('');
  const [choiceIds, setChoicIds] = useState<string | string[]>('');
  const wibdowWidth = window.innerWidth;
  const submitAnswer = (): void => {
    const answers = [...(form.getFieldValue('answers') as IAnswers[] | [])];
    if (textAnswer?.length) {
      form.setFieldValue('answers', [
        ...answers,
        { questionId: questions[questionIndex]?.id, answers: [], textAnswer: textAnswer.trim() },
      ]);
      setQuestionIndex(questionIndex + 1);
      setAnsweredQuestion(questionIndex + 1);
      setTextAnswer('');
    }
    if (choiceIds) {
      const transformedChoices = Array.isArray(choiceIds) ? choiceIds : [choiceIds];
      form.setFieldValue('answers', [
        ...answers,
        {
          questionId: questions[questionIndex]?.id,
          answers: transformedChoices,
          textAnswer: '',
        },
      ]);
      setQuestionIndex(questionIndex + 1);
      setAnsweredQuestion(questionIndex + 1);
      setChoicIds('');
    }
  };

  const onChangeRadio = (e: RadioChangeEvent): void => {
    setChoicIds(e.target.value as string);
  };

  const onChangCheckbox = (e: CheckboxValueType[]): void => {
    setChoicIds(e as string[]);
  };

  useEffect(() => {
    if (questions[questionIndex]?.questionType !== 'SHORT_TEXT') {
      const timeoutId = setTimeout(() => {
        setLoading(false);
      }, 2500);
      return () => {
        clearTimeout(timeoutId);
        setLoading(true);
      };
    }
  }, [questionIndex]);

  return (
    <Space direction='vertical' style={{ width: '100%', position: 'sticky', bottom: 0 }} size={0}>
      <Row
        style={{
          background: `${WHITE}`,
          width: '100%',
          padding: '16px 0px',
          flexDirection: 'column',
        }}
        align={'middle'}
        justify={'center'}
      >
        {questions?.map(
          (question, i) =>
            questionIndex === i &&
            (question?.questionType === 'OPTION' ? (
              <AsnRadio.Group
                key={question?.id}
                style={{ maxWidth: wibdowWidth < 768 ? 'auto' : '42vw', width: '100%', marginBottom: 16 }}
                onChange={onChangeRadio}
              >
                <Space direction='vertical'>
                  {question?.answers?.map(
                    (answer) =>
                      !loading && (
                        <AsnRadio key={answer?.id} style={{ color: `${INDIGO}`, fontWeight: 600 }} value={answer?.id}>
                          {answer?.title}
                        </AsnRadio>
                      ),
                  )}
                </Space>
              </AsnRadio.Group>
            ) : (
              <AsnCheckbox.Group
                key={question?.id}
                style={{ maxWidth: wibdowWidth < 768 ? 'auto' : '42vw', width: '100%', marginBottom: 16 }}
                onChange={onChangCheckbox}
              >
                <Space direction='vertical'>
                  {question?.answers?.map(
                    (answer) =>
                      !loading && (
                        <AsnCheckbox
                          disabled={choiceIds?.length >= question?.answersCount && !choiceIds?.includes(answer?.id)}
                          key={answer?.id}
                          style={{ color: `${INDIGO}`, fontWeight: 600 }}
                          value={answer?.id}
                        >
                          {answer?.title}
                        </AsnCheckbox>
                      ),
                  )}
                </Space>
              </AsnCheckbox.Group>
            )),
        )}
        {wibdowWidth < 768 ? (
          <Col span={24} style={{ width: '100%', padding: 10 }}>
            <input
              type='text'
              placeholder={questions[questionIndex]?.questionType !== 'SHORT_TEXT' ? '' : 'Send a message'}
              value={textAnswer}
              onChange={(e): void => setTextAnswer(normalize(e.target.value))}
              readOnly={questions[questionIndex]?.questionType !== 'SHORT_TEXT'}
              maxLength={100}
              style={{
                width: '100%',
                height: 40,
                border: '1px solid #D1D3D4',
                borderRadius: 8,
                padding: 16,
              }}
            />
            {textAnswer?.length > 0 ? (
              <MainButton
                text={''}
                icon={<DotsSvg />}
                onClick={submitAnswer}
                style={{ cursor: 'pointer', position: 'absolute', right: 21, top: 14 }}
              />
            ) : questions[questionIndex]?.questionType !== 'SHORT_TEXT' ? (
              <MainButton
                text={' Next'}
                icon={<DotsSvg />}
                disabled={!choiceIds?.length}
                onClick={submitAnswer}
                style={{ position: 'absolute', right: 21, top: 14 }}
              />
            ) : (
              <SendSvg style={{ position: 'absolute', right: 21, top: 18 }} />
            )}
          </Col>
        ) : (
          <Input
            placeholder={questions[questionIndex]?.questionType !== 'SHORT_TEXT' ? '' : 'Send a message'}
            suffix={
              textAnswer?.length > 0 ? (
                <MainButton text={''} icon={<DotsSvg />} onClick={submitAnswer} style={{ cursor: 'pointer' }} />
              ) : questions[questionIndex]?.questionType !== 'SHORT_TEXT' ? (
                <MainButton text={''} icon={<DotsSvg />} htmlType='submit' onClick={submitAnswer} disabled={!choiceIds?.length} />
              ) : (
                <SendSvg />
              )
            }
            style={{ width: '100%', maxWidth: wibdowWidth < 768 ? 'auto' : '42vw' }}
            onChange={(e): void => setTextAnswer(normalize(e.target.value))}
            value={textAnswer}
            readOnly={questions[questionIndex]?.questionType !== 'SHORT_TEXT'}
            maxLength={100}
            showCount={questions[questionIndex]?.questionType === 'SHORT_TEXT'}
            onPressEnter={submitAnswer}
          />
        )}
      </Row>
      <Row
        style={{
          background: `${MAIN_BG}`,
          width: '100%',
          position: 'sticky',
          bottom: 0,
          height: '60px',
        }}
      />
    </Space>
  );
};

export default AnswerField;
