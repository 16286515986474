import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../client';
import { TResendAnswers } from '../../types/api/chat';

const url = '/api/career-coaching/user/:userId/professions/resend';

const useResendAnswers: TResendAnswers = () => {
  const mutation = useMutation<AxiosResponse<unknown>, AxiosError, string, unknown>({
    mutationFn: (userId) => {
      return client.post(url.replace(':userId', userId));
    },
  });
  return mutation;
};

export default useResendAnswers;
