import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../client';
import { TSendFeedback, TFeedbackVariables } from '../../types/api/feedback';
import { ISuccesMessage } from '../../types/global';

const url = '/api/feedback/send';

const useSendFeedback: TSendFeedback = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, TFeedbackVariables, unknown>({
    mutationFn: (values) => {
      return client.post(url, values);
    },
  });
  return mutation;
};

export default useSendFeedback;
