import React from 'react';

import { MainLayout, MainLayoutMobaile } from '../../components';

import TryItForFree from './TryItForFree';
import Description from './Description';
import Benefits from './Benefits';
import Testimonials from './Testimonials';
import Resources from './Resources';

const Landing: React.FC<{ logged?: boolean }> = ({ logged }) => {
  const wibdowWidth = window.innerWidth;

  return (
    <>
      {wibdowWidth < 768 ? (
        <MainLayoutMobaile
          points={{
            length: undefined,
            personalInfo: 0,
            totalPoints: 0,
            education: 0,
            experience: 0,
            languages: 0,
            training: 0,
            licenses: 0,
            skills: 0,
            cvAttachment: 0,
          }}
        >
          <>
            <TryItForFree logged={logged} />
            <Description />
            <Benefits />
            <Testimonials />
            <Resources />
          </>
        </MainLayoutMobaile>
      ) : (
        <MainLayout logged={logged}>
          <>
            <TryItForFree logged={logged} />
            <Description />
            <Benefits />
            <Testimonials />
            <Resources />
          </>
        </MainLayout>
      )}
    </>
  );
};

export default Landing;
