import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Typography, Flex } from 'antd';

import { COLORS, PATHS } from '../../constants';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { ReactComponent as DotsLavenderSvg } from '../../assets/icons/dotsLavender.svg';
import { ReactComponent as Sound } from '../../assets/icons/sound.svg';
import { ReactComponent as SoundOff } from '../../assets/icons/soundOff.svg';
import { ReactComponent as LogoSvg } from '../../assets/icons/proflab.svg';
import { MainButton } from '../../components/Button';
import { ProductForCard } from '../../components';

const { Title, Paragraph } = Typography;
const { WHITE, LAVENDER, LAVENDER_100 } = COLORS;

const width = window.innerWidth;

const TryItForFree: React.FC<{ logged?: boolean }> = ({ logged }) => {
  const navigate = useNavigate();
  const [muted, setMuted] = useState(true);

  const toggleMuted = (): void => {
    setMuted(!muted);
  };

  return (
    <>
      <Row style={{ padding: '0px 16px', width: '100%', margin: '0px 0px 8px 0px' }} gutter={[8, 8]}>
        <Col
          sm={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 6 }}
          style={{ background: `${WHITE}`, borderRadius: 8 }}
        >
          <div style={{ padding: '32px 16px' }}>
            <Title level={3} style={{ marginBottom: 24 }}>
              Introducing Revolutionary AI-Based Career Coach
            </Title>
            <Paragraph style={{ color: `${LAVENDER}`, marginBottom: logged ? 0 : 40 }}>
              Find your new professional path with the help of our innovative career development tool
            </Paragraph>
            {!logged && <MainButton text={'Register for free'} icon={<DotsSvg />} onClick={(): void => navigate(PATHS.SIGNUP)} />}
          </div>
        </Col>
        <Col
          sm={{ span: 24 }}
          md={{ span: 16 }}
          lg={{ span: 16 }}
          xl={{ span: 16 }}
          xxl={{ span: 18 }}
          style={{ height: width < 768 ? 305 : 405 }}
        >
          <video
            autoPlay
            muted={muted}
            loop
            style={{ maxHeight: width < 768 ? 305 : 405, height: width < 768 ? 305 : 405, width: '100%', objectFit: 'unset' }}
            playsInline
          >
            <source src='./assets/test.mp4' type='video/mp4' />
          </video>
          <Flex justify='end'>
            <Row onClick={toggleMuted} style={{ position: 'relative', bottom: 75, right: 17, cursor: 'pointer' }}>
              {muted ? <SoundOff /> : <Sound />}
            </Row>
          </Flex>
        </Col>
      </Row>
      <Row style={{ padding: '0px 16px', marginBottom: 8, width: '100%' }} gutter={[8, 8]}>
        <Col
          sm={{ span: 24 }}
          md={{ span: 16 }}
          lg={{ span: 16 }}
          xl={{ span: 14 }}
          xxl={{ span: 16 }}
          style={{ borderRadius: 8 }}
        >
          <video
            autoPlay
            muted
            loop
            style={{ maxHeight: width < 768 ? 305 : 405, height: width < 768 ? 305 : 405, width: '100%', objectFit: 'cover' }}
          >
            <source src='./assets/tryFree.mp4' type='video/mp4' />{' '}
          </video>
        </Col>
        <Col
          sm={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 8 }}
          xl={{ span: 10 }}
          xxl={{ span: 8 }}
          style={{ background: `${WHITE}`, borderRadius: 8 }}
        >
          <Flex align='center' style={{ height: '100%', padding: 10 }}>
            <ul style={{ color: `${LAVENDER}`, fontSize: 18 }}>
              <Flex vertical gap={24}>
                <li>Choose a new profession tailored to your background and skills</li>
                <li>Explore career opportunities highly demanded in the labor market</li>
                <li>Get your personalized career and training suggestions in less than 30 minutes</li>
                <li>Find your answers quickly and for free instead of spending many hours with expensive career coaches</li>
              </Flex>
            </ul>
          </Flex>
        </Col>
      </Row>
      <Row style={{ padding: '0px 20px 0px 16px', marginBottom: 8 }}>
        <Col span={24} style={{ background: `${WHITE}`, padding: '16px', borderRadius: 8 }}>
          <Flex align='center'>
            <LogoSvg />
            <ul style={{ marginBottom: 0, fontSize: 18, fontWeight: 500 }}>
              <li>Who is this product for</li>
            </ul>
          </Flex>
        </Col>
      </Row>
      <Row style={{ padding: '0px 16px', marginBottom: 8, width: '100%' }} gutter={[8, 8]} wrap>
        <Col
          style={{ paddingRight: 0 }}
          xxl={{ flex: '20%' }}
          xl={{ flex: '20%' }}
          lg={{ flex: '33.3%' }}
          md={{ flex: '33.3%' }}
          xs={{ flex: '100%' }}
        >
          <ProductForCard text={'Disappointed in your current career?'} />
        </Col>
        <Col
          style={{ paddingRight: 0 }}
          xxl={{ flex: '20%' }}
          xl={{ flex: '20%' }}
          lg={{ flex: '33.3%' }}
          md={{ flex: '33.3%' }}
          xs={{ flex: '100%' }}
        >
          <ProductForCard text={'Stuck in a boring job?'} />
        </Col>
        <Col
          style={{ paddingRight: 0 }}
          xxl={{ flex: '20%' }}
          xl={{ flex: '20%' }}
          lg={{ flex: '33.3%' }}
          md={{ flex: '33.3%' }}
          xs={{ flex: '100%' }}
        >
          <ProductForCard text={'Experiencing burnout?'} />
        </Col>
        <Col
          style={{ paddingRight: 0 }}
          xxl={{ flex: '20%' }}
          xl={{ flex: '20%' }}
          lg={{ flex: '50%' }}
          md={{ flex: '50%' }}
          xs={{ flex: '100%' }}
        >
          <ProductForCard text={'Dreaming of a more meaningful and satisfying job?'} />
        </Col>
        <Col
          style={{ paddingRight: 0 }}
          xxl={{ flex: '20%' }}
          xl={{ flex: '20%' }}
          lg={{ flex: '50%' }}
          md={{ flex: '50%' }}
          xs={{ flex: '100%' }}
        >
          <Flex align='baseline' style={{ height: '100%', position: 'relative', background: `${LAVENDER_100}`, padding: 8 }}>
            <Flex vertical align='center' style={{ paddingBottom: 20 }}>
              <ul style={{ color: `${WHITE}`, fontSize: width < 768 ? 16 : 20, paddingTop: 50 }}>
                <li>Get ready for a positive change to transform your professional path with the help of ProfLab.</li>
              </ul>
              {!logged && (
                <MainButton
                  text={'Register for free'}
                  icon={<DotsLavenderSvg />}
                  htmlType='submit'
                  style={{ background: `${WHITE}`, color: `${LAVENDER}`, width: 160 }}
                  onClick={(): void => navigate(PATHS.SIGNUP)}
                />
              )}
            </Flex>
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default TryItForFree;
