import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Input, Typography, Checkbox, Space } from 'antd';

import { AsnForm, InfoModal } from '../../components';
import { COLORS, passwordRegExp, validate_messages, PATHS } from '../../constants';
import { useSignUp, useResendEmail } from '../../api';
import { SignUpVariables } from '../../types/api/auth';
import { normalize } from '../../helpers';
import { ReactComponent as LogoSvg } from '../../assets/icons/logoBig.svg';
import { ReactComponent as Logo } from '../../assets/icons/proflab.svg';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { MainButton } from '../../components/Button';

import { LogoWrapper } from './styles';

const { Title, Paragraph, Text } = Typography;
const { LAVENDER, DARK_GRAY } = COLORS;

const SignUp: React.FC = () => {
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [resendButton, setShowResendButton] = useState<boolean>(false);

  const [form] = AsnForm.useForm();
  const { mutate: signUp } = useSignUp();
  const { mutate: resendEmail } = useResendEmail();
  const navigate = useNavigate();

  const onFinish = (values: SignUpVariables): void => {
    signUp(
      { ...values, firstName: values?.firstName?.trimEnd(), lastName: values?.lastName?.trimEnd() },
      {
        onSuccess: () => {
          setEmail(values.email);
          setOpenInfoModal(true);
        },
      },
    );
  };

  const handleResendEmail = (): void => {
    resendEmail(
      { email },
      {
        onSuccess: () => {
          setOpenInfoModal(true);
          form.resetFields();
        },
      },
    );
  };
  const wibdowWidth = window.innerWidth;

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ width: '100%', height: '100%' }}>
        {wibdowWidth >= 575 ? (
          <LogoWrapper span={12} xs={24} sm={12} md={12} lg={12}>
            <LogoSvg onClick={(): void => navigate(PATHS.LANDING)} style={{ cursor: 'pointer', width: '100%' }} />
          </LogoWrapper>
        ) : (
          <Space direction='horizontal' style={{ padding: '20px 30px', width: '100%', height: 64 }}>
            <Logo onClick={(): void => navigate(PATHS.LOGGED_USER_LANDING)} style={{ cursor: 'pointer' }} />
          </Space>
        )}
        <Col span={12} xs={24} sm={12} md={12} lg={12}>
          <Row style={{ width: '100%', height: '100%' }} justify={'center'} align={'middle'}>
            <AsnForm
              layout='vertical'
              form={form}
              validateMessages={validate_messages}
              style={{ maxWidth: '460px', width: '85vw', padding: ' 0 10px' }}
              onFinish={(values): void => onFinish(values as SignUpVariables)}
            >
              <Title level={3} style={{ textAlign: 'center', color: `${LAVENDER}`, marginBottom: '32px' }}>
                Sign Up
              </Title>
              <AsnForm.Item
                name='firstName'
                label='First name'
                rules={[{ required: true }, { min: 3, max: 36 }]}
                normalize={(value: string): string => normalize(value)}
              >
                <Input placeholder='First name' />
              </AsnForm.Item>
              <AsnForm.Item
                name='lastName'
                label='Last name'
                rules={[{ required: true }, { min: 3, max: 36 }]}
                normalize={(value: string): string => normalize(value)}
              >
                <Input placeholder='last name' />
              </AsnForm.Item>
              <AsnForm.Item
                name='email'
                label='Email address'
                rules={[{ required: true, type: 'email', message: 'Invalid email address' }]}
              >
                <Input placeholder='Email address' />
              </AsnForm.Item>
              <AsnForm.Item
                name='password'
                label='Password'
                rules={[{ required: true }, { pattern: passwordRegExp }]}
                style={{ marginBottom: 4 }}
              >
                <Input.Password placeholder='Password' />
              </AsnForm.Item>
              <Paragraph style={{ color: `${DARK_GRAY}`, marginBottom: 32 }}>
                At least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number
              </Paragraph>
              <AsnForm.Item>
                <AsnForm.Item
                  name='privacyPolicy'
                  valuePropName='checked'
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error('Privacy policy must be accepted')),
                    },
                  ]}
                >
                  <Checkbox style={{ color: `${DARK_GRAY}` }}>
                    Agree to the{' '}
                    <Text
                      underline
                      style={{ color: `${DARK_GRAY}` }}
                      onClick={(): Window | null => window.open(PATHS.PRIVACY_POLICY, '_blank')}
                    >
                      Privacy policy
                    </Text>
                  </Checkbox>
                </AsnForm.Item>
                <MainButton
                  text={'Create Account'}
                  icon={<DotsSvg />}
                  htmlType='submit'
                  style={{ width: '100%' }}
                  disabled={!!email}
                />
                {resendButton && (
                  <Paragraph onClick={handleResendEmail} style={{ color: `${DARK_GRAY}`, cursor: 'pointer' }}>
                    Resend the confirmation email
                  </Paragraph>
                )}
              </AsnForm.Item>
            </AsnForm>
          </Row>
        </Col>
      </Row>
      {openInfoModal && (
        <InfoModal
          setShowResendButton={setShowResendButton}
          openInfoModal={openInfoModal}
          setOpenInfoModal={setOpenInfoModal}
          email={email}
          create={resendButton ? false : true}
        />
      )}
    </>
  );
};

export default SignUp;
