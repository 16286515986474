import React, { useState, useEffect } from 'react';
import { Upload, Row, Col, Typography, Space, Progress, Tooltip, Input, Select, DatePicker, Image } from 'antd';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { RcFile } from 'antd/es/upload';
import dayjs, { Dayjs } from 'dayjs';

import { AsnButton, AsnForm, AsnRadio, Points, RadioCard } from '../../components';
import { useGetCountries, useUpdateUser } from '../../api';
import { useAuth } from '../../hooks/useAuth';
import { normalize } from '../../helpers';
import { ReactComponent as AddSvg } from '../../assets/icons/add.svg';
import { ReactComponent as DeleteSvg } from '../../assets/icons/deletePhoto.svg';
import { ReactComponent as ProgressSvg } from '../../assets/icons/progress.svg';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { COLORS, validate_messages, sexOptions, questionsMyInfo, selectProps, ProfileProgressInfo } from '../../constants';
import { IUserDetail, TRequestDataUpdateUser, UpdateUserVariables } from '../../types/myInfo';
import { MainButton } from '../../components/Button';

const { WHITE, ERROR, GRAY, BLACK, SUCCESS } = COLORS;

const { Text } = Typography;

const UploadWrapper = styled(Space)`
  &.ant-space > div:first-child {
    width: 32.7%;
    max-width: 352px;
  }
  &.ant-space > div:nth-child(2) {
    width: 67%;
  }
`;

const ProgressWrapper = styled(Space)`
  .ant-space-item {
    width: 100%;
    max-width: 352px;
  }
`;

const OptionsWrapper = styled(Space)`
  align-items: unset;
  .ant-space-item {
    width: 100%;
    max-width: 352px;
  }
`;
const UserDetails: React.FC<IUserDetail> = ({ firstName, lastName, email, aditional, id, points, setField, isLastLogin }) => {
  const [search, setSearch] = useState<string>('');
  const [edit, setEdit] = useState<boolean>(isLastLogin);
  const [url, setUrl] = useState<string | undefined>(aditional?.photo);

  const { addUser, user } = useAuth();

  const { mutate: updateUser } = useUpdateUser();

  const [form] = AsnForm.useForm();

  const { data: countries, isFetching } = useGetCountries(search, {
    enabled: Boolean(search),
    keepPreviousData: search ? true : false,
    staleTime: search ? 60 * 1000 : 0,
  });

  const onFinish = (): void => {
    const values = form.getFieldsValue(true, (meta) => meta.touched === true) as UpdateUserVariables;
    const keys = Object.keys(values);

    const city = values?.city === '' ? null : values?.city?.trimEnd();
    const countryId = values?.countryId === undefined ? null : values?.countryId;
    const sexId = values?.sexId === undefined ? null : values?.sexId;

    const requsetData = {
      ...(values?.firstName && firstName !== values?.firstName.trimEnd() ? { firstName: values?.firstName?.trimEnd() } : {}),
      ...(values?.lastName && lastName !== values?.lastName?.trimEnd() ? { lastName: values?.lastName?.trimEnd() } : {}),
      aditional: {
        ...(values?.photo ? { photo: values?.photo } : {}),
        ...(keys?.includes('city') && aditional?.city !== city ? { city } : {}),
        ...(keys?.includes('dateOfBirth') &&
        dayjs(aditional?.dateOfBirth)?.format('YYYY-MM-DD') !== dayjs(values?.dateOfBirth)?.format('YYYY-MM-DD')
          ? { dateOfBirth: values?.dateOfBirth }
          : {}),
        ...(keys?.includes('countryId') && aditional?.countryId !== countryId ? { countryId: countryId } : {}),
        ...(keys?.includes('sexId') && aditional?.sexId !== sexId ? { sexId: sexId } : {}),
        ...(keys?.includes('disabilityId') && aditional?.disabilityId !== values?.disabilityId
          ? { disabilityId: values?.disabilityId }
          : {}),
        ...(keys?.includes('employmentId') && aditional?.employmentId !== values?.employmentId
          ? { employmentId: values?.employmentId }
          : {}),
        ...(keys?.includes('maritalId') && aditional?.maritalId !== values?.maritalId ? { maritalId: values?.maritalId } : {}),
      },
    };

    if (requsetData.firstName ?? requsetData.lastName ?? Object.keys(requsetData.aditional).length) {
      updateUser({ id, user: requsetData as TRequestDataUpdateUser });
      setField('');
      if (user) {
        addUser({ ...user, photo: values?.photo !== 'deleted' ? values?.photo : null });
      }
    }
    setEdit(false);
  };

  const onSearchCountries = (value: string): void => {
    if (countries?.length === 0 && search && value?.trim()?.replace(/\s+/g, ' ') > search) return;
    if (isFetching === false) {
      setSearch(value?.trim()?.replace(/\s+/g, ' '));
    }
  };

  const handleDeletePhoto = (e: { stopPropagation: () => void }): void => {
    e.stopPropagation();
    aditional?.photo
      ? form.setFields([{ name: 'photo', touched: true, value: 'deleted' }])
      : form.setFieldValue('photo', undefined);
    setUrl(undefined);
  };

  const handleCancel = (): void => {
    form.resetFields();
    setEdit(false);
    aditional?.photo ? setUrl(aditional?.photo) : setUrl(undefined);
  };

  useEffect(() => {
    if (user?.photo !== aditional?.photo) {
      if (user) {
        addUser({ ...user, photo: aditional?.photo ?? null });
      }
    }
  }, [aditional?.photo]);

  return (
    <Row style={{ background: `${WHITE}`, padding: '32px 4%' }}>
      <Col span={24} xxl={{ span: 21, push: 3 }}>
        <AsnForm
          form={form}
          layout='vertical'
          style={{ width: '100%' }}
          validateMessages={validate_messages}
          disabled={!edit}
          onFinish={onFinish}
        >
          <Space direction='vertical' style={{ width: '100%' }}>
            <UploadWrapper direction='horizontal' style={{ width: '100%' }} align='start' size={16}>
              <Space
                direction='horizontal'
                align='start'
                size={16}
                style={{ marginBottom: 32, paddingTop: 10, width: '100%', maxWidth: '352px' }}
              >
                <Points>{points?.personalInfo ?? 0}/12</Points>
                <Row style={{ position: 'relative' }}>
                  <Image height={196} width={'15vw'} preview={false} src={url ?? './assets/user.png'} rootClassName='image' />
                  <AsnForm.Item
                    name='photo'
                    valuePropName='photo'
                    style={{
                      marginBottom: 0,
                      height: '32px',
                      position: 'absolute',
                      bottom: '-12px',
                      right: '-12px',
                      cursor: 'pointer',
                    }}
                  >
                    <Upload
                      listType='picture'
                      maxCount={1}
                      showUploadList={false}
                      accept='.jpeg, .png, .gif'
                      customRequest={(options: UploadRequestOption<unknown>): void => {
                        const { file } = options;
                        const reader = new FileReader();
                        reader.onload = (): void => {
                          const result = reader.result as string;
                          if (result !== null) {
                            form.setFieldValue('photo', result);
                            setUrl(result);
                          }
                        };
                        reader.readAsDataURL(file as RcFile);
                      }}
                    >
                      <Row>
                        <AsnButton className='circle'>{url ? <DeleteSvg onClick={handleDeletePhoto} /> : <AddSvg />}</AsnButton>
                      </Row>
                    </Upload>
                  </AsnForm.Item>
                </Row>
              </Space>
              <Space direction='vertical' style={{ width: '100%' }}>
                <AsnForm.Item
                  label='Email address'
                  name='email'
                  rules={[{ required: true, message: 'Invalid email address' }]}
                  style={{ marginBottom: 13, maxWidth: 720 }}
                  initialValue={email}
                >
                  <Input disabled />
                </AsnForm.Item>
                <Row gutter={[16, 0]} style={{ maxWidth: 736 }}>
                  <Col span={12}>
                    <AsnForm.Item
                      label='First name'
                      name='firstName'
                      rules={[{ required: true }, { min: 3, max: 36 }]}
                      normalize={(value: string): string => normalize(value)}
                      style={{ marginBottom: 13 }}
                      initialValue={firstName}
                    >
                      <Input autoComplete='off' />
                    </AsnForm.Item>
                  </Col>
                  <Col span={12}>
                    <AsnForm.Item
                      label='Country of residence'
                      name='countryId'
                      rules={[{ required: true }]}
                      style={{ marginBottom: 13 }}
                      initialValue={aditional?.country?.title}
                    >
                      <Select
                        options={countries?.map((item) => {
                          return {
                            label: item?.title,
                            value: item?.id,
                          };
                        })}
                        onSearch={onSearchCountries}
                        loading={isFetching}
                        onClear={(): void => setSearch('')}
                        defaultValue={{
                          label: aditional?.country?.title,
                          value: aditional?.country?.id,
                        }}
                        {...selectProps}
                      />
                    </AsnForm.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]} style={{ maxWidth: 736 }}>
                  <Col span={12}>
                    <AsnForm.Item
                      label='Last name'
                      name='lastName'
                      rules={[{ required: true }, { min: 3, max: 36 }]}
                      normalize={(value: string): string => normalize(value)}
                      style={{ marginBottom: 13 }}
                      initialValue={lastName}
                    >
                      <Input autoComplete='off' />
                    </AsnForm.Item>
                  </Col>
                  <Col span={12}>
                    <AsnForm.Item
                      label='City of residence'
                      name='city'
                      style={{ marginBottom: 13 }}
                      initialValue={aditional?.city}
                    >
                      <Input autoComplete='off' />
                    </AsnForm.Item>
                  </Col>
                </Row>
              </Space>
            </UploadWrapper>
            <ProgressWrapper style={{ width: '100%' }} size={16}>
              <Space
                direction='vertical'
                style={{
                  border: `1px solid ${GRAY}`,
                  padding: ' 5px',
                  marginBottom: '22px',
                  width: '100%',
                  maxWidth: '352px',
                }}
                size={0}
              >
                <Row align={'middle'}>
                  <Text style={{ whiteSpace: 'nowrap', marginRight: '8px' }}>Profile Progress</Text>
                  <Tooltip
                    placement='topLeft'
                    title={ProfileProgressInfo}
                    color={WHITE}
                    overlayInnerStyle={{ color: `${BLACK}` }}
                  >
                    <ProgressSvg />
                  </Tooltip>
                </Row>
                <Row>
                  <Col span={24} style={{ display: 'flex' }}>
                    <Progress
                      style={{ marginBottom: 0 }}
                      percent={points?.totalPoints ?? 0}
                      strokeColor={points?.totalPoints > 49 ? SUCCESS : ERROR}
                      showInfo={false}
                    />
                    <Text style={{ whiteSpace: 'nowrap' }}>{points?.totalPoints} points</Text>
                  </Col>
                </Row>
              </Space>
              <AsnForm.Item
                label='Date of birth'
                name='dateOfBirth'
                rules={[{ required: true }]}
                initialValue={aditional?.dateOfBirth ? dayjs(aditional?.dateOfBirth) : dayjs().subtract(18, 'year')}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  suffixIcon={<DownOutlined />}
                  placeholder=''
                  disabledDate={(current: Dayjs): boolean =>
                    current > dayjs().subtract(18, 'year') || current < dayjs().subtract(100, 'year')
                  }
                  defaultValue={aditional?.dateOfBirth ? dayjs(aditional?.dateOfBirth) : undefined}
                  showToday={false}
                  defaultPickerValue={dayjs().subtract(18, 'year')}
                />
              </AsnForm.Item>
              <AsnForm.Item label='Sex' name='sexId'>
                <Select
                  options={sexOptions}
                  allowClear
                  defaultValue={{ label: aditional?.sex?.title, value: aditional?.sex?.id }}
                />
              </AsnForm.Item>
            </ProgressWrapper>
            <OptionsWrapper style={{ width: '100%' }} size={16}>
              {questionsMyInfo?.map((item, i) => (
                <RadioCard key={i}>
                  <AsnForm.Item
                    label={item?.question}
                    name={item?.name}
                    initialValue={aditional && aditional[item?.name as keyof typeof aditional]}
                  >
                    <AsnRadio.Group style={{ paddingTop: 16 }}>
                      <Space direction='vertical'>
                        {item?.answers?.map((answer) => (
                          <AsnRadio key={answer?.id} value={answer?.id}>
                            {answer?.label}
                          </AsnRadio>
                        ))}
                      </Space>
                    </AsnRadio.Group>
                  </AsnForm.Item>
                </RadioCard>
              ))}
            </OptionsWrapper>
            {edit && (
              <Row style={{ maxWidth: 1089, marginTop: 24, gap: 16 }} justify={'end'}>
                <AsnButton onClick={handleCancel}>Cancel</AsnButton>
                <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' />
              </Row>
            )}
          </Space>
        </AsnForm>
        {!edit && (
          <Row style={{ maxWidth: 1089 }} justify={'end'}>
            <MainButton text={'Edit'} icon={<DotsSvg />} style={{ marginTop: 32 }} onClick={(): void => setEdit(true)} />
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default UserDetails;
