import { Flex, Typography } from 'antd';

import AsnButton from '../Button';
import { COLORS } from '../../constants';
import { ReactComponent as LavenderDotsSvg } from '../../assets/icons/dotsLavender.svg';
import { IResourceCard } from '../../types/components';

const { Title } = Typography;
const { WHITE, LAVENDER } = COLORS;

const width = window.innerWidth;

const ResourceCard: React.FC<IResourceCard> = ({ img, title, link }) => {
  return (
    <div style={{ background: `${WHITE}`, borderRadius: 8 }}>
      <Flex gap={16}>
        <img
          src={img}
          width={width < 576 ? 160 : 256}
          style={{ objectFit: 'cover', borderRadius: '8px 0px 0px 8px' }}
          height={200}
        />
        <Flex vertical style={{ padding: 16, width: '100%' }} justify='space-between'>
          <Title level={width < 576 ? 5 : 3} ellipsis={{ rows: 3 }}>
            {title}
          </Title>
          <AsnButton
            type='primary'
            className='main'
            style={{ background: 'transparent', color: `${LAVENDER}`, boxShadow: 'none' }}
            onClick={(): Window | null => window.open(link, '_blank')}
          >
            <Flex align='center' gap={8} justify='end'>
              Read More <LavenderDotsSvg />
            </Flex>
          </AsnButton>
        </Flex>
      </Flex>
    </div>
  );
};

export default ResourceCard;
