import { useQuery } from '@tanstack/react-query';

import client from '../client';
import { TGetCareerPaths, TUseGetCareerPaths } from '../../types/api/careerPaths';

const url = '/api/career-coaching/user/:userId/recommendation';

const useGetCareerPaths: TUseGetCareerPaths = (userId, options) => {
  const result = useQuery({
    queryKey: [url, userId],
    queryFn: () => client.get(url.replace(':userId', userId)),
    ...options,
    select: (data) => data?.data?.result,
  });

  const { data, isLoading, isSuccess, isFetching, status } = result;
  return {
    data: isSuccess ? data : ({} as TGetCareerPaths),
    isLoading,
    isFetching,
    status,
  };
};

export default useGetCareerPaths;
