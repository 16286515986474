import React, { useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { CollapseProps, Typography, Row, Progress, Col, Spin, message } from 'antd';
import dayjs from 'dayjs';

import { AsnCollapse, Points, UserInfoCard, AsnForm, EducationForm, AsnButton, DeleteModal } from '../../components';
import { useAddEducation, useDeleteEducation, useUpdateEducation } from '../../api';
import { COLORS, validate_messages } from '../../constants';
import { IAddEducationVariables, IEducation, TOnUpdateEducation } from '../../types/myInfo';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrowUp.svg';
import { ReactComponent as EducationSvg } from '../../assets/icons/education.svg';
import { ReactComponent as AddSvg } from '../../assets/icons/add.svg';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { MainButton } from '../../components/Button';

const { Text } = Typography;
const { INDIGO, LAVENDER, GRAY, WHITE } = COLORS;

const EducationMobaile: React.FC<IEducation> = ({ id, educations, points, isFetchingUser, setField }) => {
  const [edit, setEdit] = useState<string | boolean>(false);
  const [create, setCreate] = useState<boolean>(!educations?.length);
  const [openDeleteModalById, setOpenDeleteModalById] = useState<string | boolean>(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();
  const wibdowWidth = window.innerWidth;
  const [formCreate] = AsnForm.useForm();
  const [formUpdate] = AsnForm.useForm();

  const { mutate: addEducation } = useAddEducation();
  const { mutate: deleteEducation } = useDeleteEducation();
  const { mutate: updateEducation } = useUpdateEducation();

  const handleClickAdd = (): void => {
    setEdit(false);
    setCreate(true);
    const newContentElement = scrollRef?.current?.lastElementChild;
    newContentElement?.scrollIntoView({ behavior: 'smooth' });
    formCreate.resetFields();
  };
  const onFinish = (values: IAddEducationVariables): void => {
    const requestData = { ...values, university: values?.university?.trimEnd() };
    addEducation({ id, education: requestData });
    formCreate.resetFields();
    setCreate(false);
    setField('education');
  };

  const handleCancelCreate = (): void => {
    formCreate.resetFields();
    setCreate(false);
  };

  const handleCancelUpdate = (): void => {
    formUpdate.resetFields();
    setEdit(false);
    setCreate(false);
  };

  const handleEdit = (educationId: string): void => {
    setEdit(educationId);
  };

  const handleDelete = (): void => {
    void deleteEducation(
      { userId: id, infoId: openDeleteModalById as string },
      {
        onSuccess: () => {
          if (educations?.length === 1) {
            setCreate(true);
          }
          setField('education');
          setEdit(false);
          setOpenDeleteModalById(false);
          void queryClient.invalidateQueries(['/api/user/:id']);
        },
        onError: (error) => {
          void message.error(error as unknown as string, 2);
        },
      },
    );
  };

  const onUpdateEducation: TOnUpdateEducation = (university, degreeId, fieldId, startDate, endDate, educationId): void => {
    const values = formUpdate.getFieldsValue(true, (meta) => meta.touched === true) as IAddEducationVariables;
    const keys = Object.keys(values);

    const degree = values?.degreeId === undefined ? null : values?.degreeId;

    const requsetData = {
      ...(keys?.includes('university') && university !== values?.university?.trimEnd()
        ? { university: values?.university?.trimEnd() }
        : {}),
      ...(keys?.includes('degreeId') && degreeId !== degree ? { degreeId: degree } : {}),
      ...(keys?.includes('filedId') && fieldId !== values?.filedId ? { filedId: values?.filedId } : {}),
      ...(keys?.includes('startDate') && dayjs(startDate).format('YYYY-MM') !== dayjs(values?.startDate).format('YYYY-MM')
        ? { startDate: values?.startDate }
        : {}),
      ...(keys?.includes('endDate') && dayjs(endDate).format('YYYY-MM') !== dayjs(values?.endDate).format('YYYY-MM')
        ? { endDate: values?.endDate ?? null }
        : {}),
    };

    if (Object.keys(requsetData).length) {
      updateEducation({ id, education: { ...(requsetData as IAddEducationVariables), id: educationId } });
      setField('education');
    }
    setEdit(false);
  };

  const handleCollapseChange = (key: string[] | string): void => {
    if (key?.length) {
      !educations?.length ? setCreate(true) : setCreate(false);
      setEdit(false);
      formCreate.resetFields();
    }
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <Row align={'middle'} style={{ gap: 4 }}>
          <Points
            style={{
              height: wibdowWidth < 768 ? 32 : 54,
              width: wibdowWidth < 768 ? 32 : 54,
              fontSize: wibdowWidth < 768 ? 11 : 16,
            }}
          >
            {points ?? 0}/12
          </Points>
          <EducationSvg />
          <Text style={{ fontSize: wibdowWidth < 768 ? 14 : 20, color: `${INDIGO}` }}>Education</Text>
        </Row>
      ),
      children: isFetchingUser ? (
        <Row justify={'center'}>
          <Spin />
        </Row>
      ) : (
        <>
          <Row align={'middle'} justify={'end'}>
            <AddSvg style={{ cursor: 'pointer' }} onClick={handleClickAdd} />
          </Row>
          <Progress
            style={{ marginBottom: 0 }}
            percent={20}
            strokeColor={LAVENDER}
            showInfo={false}
            size={['100%', 1]}
            trailColor={GRAY}
          />
          {!!educations?.length &&
            educations?.map((education) => (
              <UserInfoCard
                key={education?.id}
                edit={edit as string}
                title={education?.university}
                startDate={education?.startDate}
                endDate={education?.endDate}
                id={education?.id}
                setId={setOpenDeleteModalById}
                handleEdit={(): void => handleEdit(education?.id)}
              >
                {edit === education?.id && (
                  <AsnForm
                    form={formUpdate}
                    layout='vertical'
                    onFinish={(): void =>
                      onUpdateEducation(
                        education?.university,
                        education?.degreeId,
                        education?.filedId,
                        education?.startDate,
                        education?.endDate,
                        education?.id,
                      )
                    }
                    validateMessages={validate_messages}
                  >
                    <EducationForm
                      university={education?.university}
                      degreeField={education?.degree}
                      studyField={education?.field}
                      startDate={education?.startDate}
                      endDate={education?.endDate}
                    />
                    {wibdowWidth < 768 ? (
                      <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                        <Col span={24}>
                          <AsnForm.Item style={{ marginBottom: 0 }}>
                            <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
                          </AsnForm.Item>
                        </Col>
                        <Col span={24}>
                          <AsnButton onClick={(): void => handleCancelUpdate()} style={{ width: '100%' }}>
                            Cancel
                          </AsnButton>
                        </Col>
                      </Row>
                    ) : (
                      <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                        <AsnButton onClick={(): void => handleCancelUpdate()}>Cancel</AsnButton>
                        <AsnForm.Item style={{ marginBottom: 0 }}>
                          <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' />
                        </AsnForm.Item>
                      </Row>
                    )}
                  </AsnForm>
                )}
              </UserInfoCard>
            ))}
          {!edit && create && (
            <AsnForm
              form={formCreate}
              layout='vertical'
              onFinish={(values): void => onFinish(values as IAddEducationVariables)}
              validateMessages={validate_messages}
            >
              <EducationForm />
              {wibdowWidth < 768 ? (
                <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                  <Col span={24}>
                    <AsnForm.Item style={{ marginBottom: 0 }}>
                      <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
                    </AsnForm.Item>
                  </Col>
                  <Col span={24}>
                    <AsnButton onClick={(): void => handleCancelCreate()} style={{ width: '100%' }}>
                      Cancel
                    </AsnButton>
                  </Col>
                </Row>
              ) : (
                <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                  <AsnButton onClick={(): void => handleCancelCreate()}>Cancel</AsnButton>
                  <AsnForm.Item style={{ marginBottom: 0 }}>
                    <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
                  </AsnForm.Item>
                </Row>
              )}
            </AsnForm>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Row style={{ background: `${WHITE}` }} ref={scrollRef}>
        <Col span={24} xxl={{ span: 21, push: 3 }}>
          <AsnCollapse
            items={items}
            bordered={false}
            expandIconPosition={'end'}
            expandIcon={({ isActive }): React.ReactNode => (isActive ? <ArrowUp /> : <ArrowDown />)}
            onChange={handleCollapseChange}
            defaultActiveKey={educations?.length ? ['1'] : []}
          />
        </Col>
      </Row>
      {!!openDeleteModalById && (
        <DeleteModal
          openDeleteModal={!!openDeleteModalById}
          setOpenDeleteModal={setOpenDeleteModalById}
          title={'Are you sure to delete this education?'}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};

export default EducationMobaile;
