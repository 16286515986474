import React from 'react';
import { Row, Col, Typography } from 'antd';

import { useClickTracking } from '../../api';
import { COLORS } from '../../constants';
import { MainCard } from '../../components';
import { ICourses } from '../../types/careerPaths';

const { WHITE } = COLORS;
const { Paragraph, Title } = Typography;

const Courses: React.FC<ICourses> = ({ courses }) => {
  const wibdowWidth = window.innerWidth;
  const { mutate: trackClick } = useClickTracking();

  const handleClick = (): void => {
    trackClick({ type: 'COURSE' });
  };

  return (
    <Row style={{ background: `${WHITE}`, padding: '32px 4%' }}>
      <Col span={24} xxl={{ pull: 2, push: 2, span: 20 }}>
        <Row>
          <Col span={wibdowWidth < 768 ? 24 : 22}>
            <Title level={3}>Courses</Title>
            <Paragraph>
              To enhance your skills in the chosen career and acquire new knowledge, we have prepared a recommendation of relevant
              courses offered by Quick Start.
            </Paragraph>
          </Col>
        </Row>
        <Row gutter={[16, 16]} wrap className='row'>
          {courses?.map(
            (course, i) =>
              i !== 3 && (
                <Col
                  key={i}
                  xxl={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 8 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span onClick={handleClick}>
                    <MainCard
                      title={course?.title}
                      price={course?.price}
                      img_url={course?.img_url}
                      source={course?.source}
                      date={course?.start_date}
                      status={course?.status}
                      url={course?.course_url}
                    />
                  </span>
                </Col>
              ),
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default Courses;
