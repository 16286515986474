import React, { useState } from 'react';
import { Space } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

import { AsnForm, AsnCheckbox } from '../../components';
import { COLORS } from '../../constants';
import { IChoicesField } from '../../types/chat';

const { INDIGO } = COLORS;

const CheckboxField: React.FC<IChoicesField> = ({ name, answers, edit, fieldAnswer, count }) => {
  const [editedCheckboxids, setEditeCheckboxIds] = useState<string[] | undefined>(undefined);
  const form = AsnForm.useFormInstance();
  const wibdowWidth = window.innerWidth;

  const onChangeCheckboxEdit = (e: CheckboxValueType[]): void => {
    setEditeCheckboxIds(e as string[]);

    if (e?.length) {
      form.setFields([
        {
          name: ['answers', name, 'answers'],
          errors: [],
        },
      ]);
    }
  };

  return (
    <AsnForm.Item name={[name, 'answers']}>
      <AsnCheckbox.Group
        style={{ maxWidth: wibdowWidth < 768 ? 'auto' : '42vw', width: '100%', marginBottom: 16 }}
        disabled={edit !== form?.getFieldValue(['answers', name, 'questionId'])}
        onChange={onChangeCheckboxEdit}
      >
        <Space direction='vertical'>
          {answers?.map((answer) => (
            <AsnCheckbox
              key={answer?.id}
              style={{ color: `${INDIGO}`, fontWeight: 600 }}
              value={answer?.id}
              disabled={
                editedCheckboxids
                  ? editedCheckboxids?.length >= (count as number) && !editedCheckboxids?.includes(answer?.id)
                  : (fieldAnswer?.length as number) >= (count as number) && !fieldAnswer?.includes(answer?.id)
              }
            >
              {answer?.title}
            </AsnCheckbox>
          ))}
        </Space>
      </AsnCheckbox.Group>
    </AsnForm.Item>
  );
};

export default CheckboxField;
