import { Collapse } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';

const { WHITE } = COLORS;
const AsnCollapse = styled(Collapse)`
  &.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 4% 16px 4%;
  }
  &.ant-collapse-borderless {
    background: ${WHITE};
    max-width: 1200px;
  }
  :where(.css-dev-only-do-not-override-gu3e6u).ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 22px 5% 23px 8px;
    display: flex;
    align-items: center;
  }
  :where(.css-dev-only-do-not-override-z458e7).ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 4% 12px 12px;
  }
  .ant-typography {
    font-size: 16px;
  }
`;

export default AsnCollapse;
