import React from 'react';
import { Row, Col, Typography, Input, message } from 'antd';

import { COLORS, validate_messages } from '../../constants';
import { AsnForm } from '../../components';
import { useSendFeedback } from '../../api';
import { normalize } from '../../helpers';
import { IFeedbackValues } from '../../types/feedback';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { MainButton } from '../../components/Button';

const { WHITE, LAVENDER } = COLORS;
const { Title, Paragraph } = Typography;

const Feedback: React.FC = () => {
  const { mutate: sendFeedback } = useSendFeedback();
  const [form] = AsnForm.useForm();

  const onFinish = (values: IFeedbackValues): void => {
    sendFeedback(
      {
        subject: values?.subject?.trim(),
        message: values?.message?.trim(),
      },
      {
        onSuccess: () => {
          void message.success('Your feedback has been successfully submitted. Thank you!', 2);
          form.resetFields();
        },
      },
    );
  };
  const wibdowWidth = window.innerWidth;

  return (
    <Row
      style={{
        background: `${WHITE}`,
        margin: wibdowWidth < 768 ? 0 : '32px 0px',
        height: 'calc(100% - 64px)',
        padding: wibdowWidth < 768 ? 12 : 'auto',
      }}
      align={'middle'}
    >
      <Col span={wibdowWidth < 768 ? 24 : 12} push={wibdowWidth < 768 ? 'auto' : 6} pull={wibdowWidth < 768 ? 'auto' : 6}>
        <AsnForm
          layout='vertical'
          style={{ padding: wibdowWidth < 768 ? '0' : '48px 0px' }}
          onFinish={(values): void => onFinish(values as IFeedbackValues)}
          validateMessages={validate_messages}
          form={form}
        >
          <Title level={3} style={{ color: `${LAVENDER}`, marginBottom: 32 }}>
            Feedback
          </Title>
          <Paragraph style={{ marginBottom: 32, fontSize: 16 }}>
            Thank you for taking your time and registering with ProfLab! We greatly value your insights and opinions! As we
            continuously strive to enhance our services, your feedback is very important in shaping the quality of your
            experience. We kindly invite you to take a few moments to share your thoughts in the feedback form. Your input will
            enable us to better understand your needs and make improvements that truly matter to you.
          </Paragraph>
          <AsnForm.Item
            name='subject'
            rules={[{ required: true }, { max: 200 }]}
            normalize={(value: string): string => normalize(value)}
          >
            <Input placeholder='Subject' />
          </AsnForm.Item>
          <AsnForm.Item
            name='message'
            rules={[{ required: true }, { max: 500 }]}
            normalize={(value: string): string => normalize(value)}
          >
            <Input.TextArea placeholder='Send a message' style={{ height: '18vh' }} />
          </AsnForm.Item>
          <AsnForm.Item>
            <Row justify={'end'}>
              <MainButton
                text={'Send'}
                icon={<DotsSvg />}
                htmlType='submit'
                style={{ width: wibdowWidth < 768 ? '100%' : 'auto' }}
              />
            </Row>
          </AsnForm.Item>
        </AsnForm>
      </Col>
    </Row>
  );
};

export default Feedback;
