import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Row, Spin, Col } from 'antd';

import { useGetCareerPaths } from '../../api';
import { useAuth } from '../../hooks/useAuth';
import { PATHS } from '../../constants';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { MainButton } from '../../components/Button';

import Professions from './Professions';
import ProfessionDetails from './ProfessionDetails';
import Skills from './Skills';
import Sugesstion from './Suggestion';
import Courses from './Courses';

const CareerPaths: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { data, isFetching } = useGetCareerPaths(user?.id as string, { enabled: Boolean(user?.id) });

  return (
    <>
      {isFetching ? (
        <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
          <Spin />
        </Row>
      ) : (
        <Space size={32} style={{ width: '100%', paddingTop: 32, padding: 20 }} direction='vertical'>
          <Professions professions={data?.professions} />
          <ProfessionDetails
            profession={data?.professions?.filter((item) => item?.checked === true)[0]}
            points={data?.total_score}
          />
          <Skills skills={data?.skills} evaluations={data?.evaluation} />
          <Sugesstion suggestion={data?.suggestion} />
          <Courses courses={data?.recommendation?.courses} />
          <Row style={{ padding: '32px 4%' }}>
            <Col span={24} xxl={{ pull: 2, push: 2, span: 20 }}>
              <Row justify={'end'}>
                <MainButton
                  text={'Leave Feedback'}
                  icon={<DotsSvg />}
                  onClick={(): void => navigate(`${PATHS.FEEDBACK}?menu=Feedback`)}
                />
              </Row>
            </Col>
          </Row>
        </Space>
      )}
    </>
  );
};

export default CareerPaths;
