import React from 'react';
import { Row, Col, Typography, Space, List } from 'antd';

import { COLORS } from '../../constants';

const { Title, Text } = Typography;
const { LIGHT_VIOLET, SOLITUDE, WHITE } = COLORS;
const wibdowWidth = window.innerWidth;

const Instruction: React.FC = () => {
  return (
    <Row style={{ background: `${WHITE}`, padding: wibdowWidth < 768 ? 0 : '60px 0px' }}>
      <Col span={wibdowWidth < 768 ? 24 : 16} pull={wibdowWidth < 768 ? 0 : 4} push={wibdowWidth < 768 ? 0 : 4}>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Row style={{ background: `${LIGHT_VIOLET}`, padding: 16 }}>
            <Title level={3} style={{ textAlign: 'center', width: '100%' }}>
              Instruction
            </Title>
            <Text style={{ textAlign: 'center', fontSize: 16 }}>
              We greatly appreciate your time and effort in completing this profile, that is why each field you complete will earn
              you certain points. This will be reflected in the <b>Profile Progress</b>. Once you reach a <b>50% completion </b>{' '}
              of the Profile Progress, you will be eligible to proceed to the <b>AI Career Coach</b>
              test. This benchmark is important for us to analyze your profile effectively. Please note that providing more
              information will generate more realistic results.
            </Text>
          </Row>
          <Row style={{ background: `${SOLITUDE}`, padding: 16 }}>
            <Title level={3} style={{ width: '100%' }} underline>
              My info
            </Title>
            <ul style={{ paddingInlineStart: 12, lineHeight: 1.57, margin: 0 }}>
              <Space direction='vertical' style={{ fontSize: 16 }}>
                <List.Item>
                  Navigate to the <b>“My Info”</b> section in the menu on your left. Fields marked with a star sign (*) are
                  mandatory.
                </List.Item>
                <List.Item>
                  You may <b>upload your photo</b> in JPEG and PNG formats.
                </List.Item>
                <List.Item>
                  In the “My Info” section enter your <b>personal details</b>, answer three short questions and fill in the
                  necessary information in all fields as accurately as possible. You may add as many entries as you wish simply by
                  clicking on the plus sign (+) in the upper right corner of each section.
                </List.Item>
                <List.Item>
                  Please note that the end and start dates in <b>“Education”</b> and <b>“Experience”</b> fields may be entered in
                  months and years only, without the need for the exact day.
                </List.Item>
                <List.Item>
                  <b>Languages</b>. You may choose the level of proficiency in your mentioned language from the drop-down menu.
                </List.Item>
                <List.Item>
                  <b>Skills</b>. This is a very important section of our questionnaire, it carries the highest score in
                  determining Profile Progress. You may enter the first letters of your skills and choose from the drop-down menu.
                </List.Item>
                <List.Item>
                  <b>Licenses and Certifications</b>. Fill in the information about your professional licenses or certifications,
                  for example PMP, CPA.
                </List.Item>
              </Space>
            </ul>
          </Row>
          <Row style={{ background: `${LIGHT_VIOLET}`, padding: 16 }}>
            <Title level={3} style={{ width: '100%' }} underline>
              Chat
            </Title>
            <Text style={{ fontSize: 16 }}>
              The <b>AI Career Coach</b> test will take approximately 25-30 minutes to complete. It includes both multiple-choice
              and open-end questions. Please read the questions and multiple-choice answers carefully, and ensure maximum accuracy
              in your responses. Once you click on “Submit My Answers”, you will not be able to edit your responses.
            </Text>
          </Row>
          <Row style={{ background: `${SOLITUDE}`, padding: 16 }}>
            <Title level={3} style={{ width: '100%' }} underline>
              My Career Paths
            </Title>
            <Text style={{ fontSize: 16 }}>
              After completing the test, you will receive <b>four career paths options</b> along with descriptions explaining why
              these professions are relevant to you. You can choose the one that corresponds to your preferences. ProfLab AI Coach
              will then analyze the relevance of your current profile to your chosen career path and provide a{' '}
              <b>scoring system of 100 points</b>, representing your level of attainment based on your education, background,
              experience, and test results. ProfLab AI Coach will present a list of the most in-demand and necessary skills for
              your chosen career path. <b>Each skill will be ranked</b> by importance using star signs from 1 to 5. Our system
              will recommend which skills you should develop in order to pursue your chosen profession.
            </Text>
            <Title level={5} style={{ width: '100%' }} underline>
              Courses
            </Title>
            <Text style={{ fontSize: 16 }}>
              To enhance your skills in the chosen career and acquire new knowledge, we will prepare a recommendation of relevant
              courses offered by <b>Quick Start</b>.
            </Text>
            <Title level={5} style={{ width: '100%' }} underline>
              Jobs
            </Title>
            <Text style={{ fontSize: 16 }}>
              Additionally, to provide insights into the demand for this profession in the labor market, the system will select
              real job announcements from the <b>Job.am</b> website.
            </Text>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};

export default Instruction;
