import React from 'react';
import { Row, Col, Typography } from 'antd';

import { COLORS } from '../../constants';
import { ResourceCard } from '../../components';

const { WHITE } = COLORS;
const { Title } = Typography;

const width = window.innerWidth;

const Resources: React.FC = () => {
  return (
    <>
      <Row
        style={{
          padding: '0px 16px',
          marginBottom: 8,
        }}
        id='resources'
      >
        <Col span={24} style={{ background: `${WHITE}`, borderRadius: 8, padding: '26px 16px' }}>
          <Title level={3} style={{ fontWeight: 500, marginBottom: 0 }}>
            Resources
          </Title>
        </Col>
      </Row>
      <Row
        gutter={width < 768 ? [0, 8] : [8, 8]}
        style={{
          padding: '0px 16px',
          margin: '0px 0px 64px 0px',
          width: '100%',
        }}
      >
        <Col
          span={12}
          xxl={{ span: 12 }}
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ paddingLeft: 0 }}
        >
          <ResourceCard
            img={'./assets/resources4.webp'}
            title={`19 highly successful people who prove it's never too late to change careers`}
            link={'https://www.businessinsider.com/successful-people-who-made-a-big-career-change-2016-4'}
          />
        </Col>
        <Col
          span={12}
          xxl={{ span: 12 }}
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ paddingRight: 0 }}
        >
          <ResourceCard
            img={'./assets/resources2.jpeg'}
            title={`How To Change Career When You've No Idea What To Do Next`}
            link={'https://www.careershifters.org/expert-advice/how-to-change-career-when-youve-no-idea-what-to-do-next'}
          />
        </Col>
        <Col
          span={12}
          xxl={{ span: 12 }}
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ paddingLeft: 0 }}
        >
          <ResourceCard
            img={'./assets/resources3.png'}
            title={'Career Change at 40: Moving Into Your Next Stage'}
            link={' https://www.coursera.org/articles/career-change-at-40'}
          />
        </Col>
        <Col
          span={12}
          xxl={{ span: 12 }}
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ paddingRight: 0 }}
        >
          <ResourceCard
            img={'./assets/resources5.webp'}
            title={'Switching Careers After 40: Three Success Stories'}
            link={
              'https://www.forbes.com/sites/learnvest/2013/03/27/switching-careers-after-40-three-success-stories/?sh=2241c9d2504e'
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default Resources;
