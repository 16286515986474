import React from 'react';
import { Space, Row, Typography } from 'antd';
import styled from 'styled-components';

import { AsnCheckbox, AsnForm, AsnRadio, AsnButton } from '../../components';
import { COLORS } from '../../constants';
import { IResponsesView } from '../../types/chat';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { MainButton } from '../../components/Button';

const { WHITE, INDIGO } = COLORS;

const { Text } = Typography;

const QuestionWrapper = styled(Row)`
  width: 100%;
  padding: 32px 0px;
`;

const AnswerWrapper = styled(Row)`
  width: 100%;
  padding: 16px 0px;
`;

const ResponsesView: React.FC<IResponsesView> = ({ responses, setSearchParams, setStart }) => {
  const navigateNext = (): void => {
    const params = { menu: 'Chat', status: 'submit' };
    setSearchParams(params);
  };
  const wibdowWidth = window.innerWidth;

  return (
    <>
      <AsnForm disabled>
        <Space direction='vertical' style={{ width: '100%' }}>
          {responses &&
            responses?.map((response, i) =>
              response?.question?.questionType === 'SHORT_TEXT' ? (
                <React.Fragment key={i}>
                  <QuestionWrapper align={'top'} justify={'center'}>
                    <Text
                      style={{
                        maxWidth: wibdowWidth < 768 ? 'auto' : '42vw',
                        width: '100%',
                        color: `${INDIGO}`,
                        fontWeight: 600,
                        padding: wibdowWidth < 768 ? '0px 10px' : '32px 0px',
                      }}
                    >
                      {response?.question?.title}
                    </Text>
                  </QuestionWrapper>
                  <AnswerWrapper
                    align={'top'}
                    justify={'center'}
                    style={{ background: wibdowWidth < 768 ? '#f6f6f6' : `${WHITE}` }}
                  >
                    <Text
                      style={{
                        maxWidth: wibdowWidth < 768 ? 'auto' : '42vw',
                        width: '100%',
                        color: `${INDIGO}`,
                        fontWeight: 600,
                        background: wibdowWidth < 768 ? `${WHITE}` : 'inherit',
                        padding: wibdowWidth < 768 ? '0px 10px' : 'auto',
                      }}
                    >
                      {response?.textAnswer}
                    </Text>
                  </AnswerWrapper>
                </React.Fragment>
              ) : response?.question?.questionType === 'OPTION' ? (
                <React.Fragment key={i}>
                  <QuestionWrapper
                    style={{ width: '100%', padding: wibdowWidth < 768 ? '0px 10px' : '32px 0px' }}
                    align={'top'}
                    justify={'center'}
                  >
                    <Text
                      style={{
                        maxWidth: wibdowWidth < 768 ? 'auto' : '42vw',
                        width: '100%',
                        color: `${INDIGO}`,
                        fontWeight: 600,
                      }}
                    >
                      {response?.question?.title}
                    </Text>
                  </QuestionWrapper>
                  <AnswerWrapper
                    align={'top'}
                    justify={'center'}
                    style={{ background: wibdowWidth < 768 ? '#f6f6f6' : `${WHITE}` }}
                  >
                    <Row
                      style={{
                        maxWidth: wibdowWidth < 768 ? 'auto' : '42vw',
                        width: '100%',
                        marginBottom: 16,
                        background: wibdowWidth < 768 ? `${WHITE}` : 'inherit',
                        padding: wibdowWidth < 768 ? '0px 10px' : 'auto',
                      }}
                    >
                      <Space direction='vertical'>
                        {response?.answers?.map((answer) => (
                          <AsnRadio
                            key={answer?.id}
                            style={{ color: `${INDIGO}`, fontWeight: 600 }}
                            defaultChecked={answer?.checked}
                          >
                            {answer?.title}
                          </AsnRadio>
                        ))}
                      </Space>
                    </Row>
                  </AnswerWrapper>
                </React.Fragment>
              ) : (
                <React.Fragment key={i}>
                  <QuestionWrapper
                    style={{ width: '100%', padding: wibdowWidth < 768 ? '0px 10px' : '32px 0px' }}
                    align={'top'}
                    justify={'center'}
                  >
                    <Text
                      style={{
                        maxWidth: wibdowWidth < 768 ? 'auto' : '42vw',
                        width: '100%',
                        color: `${INDIGO}`,
                        fontWeight: 600,
                      }}
                    >
                      {response?.question?.title}
                    </Text>
                  </QuestionWrapper>
                  <AnswerWrapper
                    align={'top'}
                    justify={'center'}
                    style={{ background: wibdowWidth < 768 ? '#f6f6f6' : `${WHITE}` }}
                  >
                    <Row
                      style={{
                        maxWidth: wibdowWidth < 768 ? 'auto' : '42vw',
                        width: '100%',
                        marginBottom: 16,
                        background: wibdowWidth < 768 ? `${WHITE}` : 'inherit',
                        padding: wibdowWidth < 768 ? '0px 10px' : 'auto',
                      }}
                    >
                      <Space direction='vertical'>
                        {response?.answers?.map((answer) => (
                          <AsnCheckbox
                            key={answer?.id}
                            style={{ color: `${INDIGO}`, fontWeight: 600 }}
                            defaultChecked={answer?.checked}
                          >
                            {answer?.title}
                          </AsnCheckbox>
                        ))}
                      </Space>
                    </Row>
                  </AnswerWrapper>
                </React.Fragment>
              ),
            )}
        </Space>
      </AsnForm>
      <Row justify={'end'} style={{ width: '100%', padding: 32 }} gutter={[10, 10]}>
        <AsnButton
          style={{ marginRight: wibdowWidth < 768 ? 'auto' : 16, width: wibdowWidth < 768 ? '100%' : 'auto' }}
          onClick={(): void => setStart(false)}
        >
          Back
        </AsnButton>
        <MainButton
          text={'Next'}
          icon={<DotsSvg />}
          onClick={navigateNext}
          style={{ width: wibdowWidth < 768 ? '100%' : 'auto' }}
        />
      </Row>
    </>
  );
};
export default ResponsesView;
