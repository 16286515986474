import { Checkbox } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';

const { LAVENDER, WHITE } = COLORS;

const AsnCheckbox = styled(Checkbox)`
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background: ${LAVENDER};
    &::after {
      border-color: ${WHITE};
    }
  }
`;

export default AsnCheckbox;
