import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { message } from 'antd';

import { useAuth } from '../../hooks/useAuth';
import client from '../client';
import { TUseSignIn, SignInResponse, SignInvariables } from '../../types/api/auth';

const url = '/api/auth/sign-in';

const useSignIn: TUseSignIn = () => {
  const { login } = useAuth();

  const mutation = useMutation<AxiosResponse<SignInResponse>, AxiosError, SignInvariables, unknown>({
    mutationFn: (user) => {
      return client.post(url, user);
    },
    onSuccess: (data) => {
      login({
        user: data?.data,
        access_token: data?.data?.accessToken,
      });
    },
    onError: (error) => {
      void message.error(error as unknown as string, 2);
    },
  });

  return mutation;
};

export default useSignIn;
