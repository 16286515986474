import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Space, Input, Typography } from 'antd';

import { useForgotPassword } from '../../api';
import { AsnButton, AsnForm } from '../../components';
import { COLORS, validate_messages, PATHS } from '../../constants';
import { ReactComponent as KeySvg } from '../../assets/icons/key.svg';
import { ReactComponent as Logo } from '../../assets/icons/proflab.svg';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { MainButton } from '../../components/Button';

const { Title, Paragraph } = Typography;
const { LAVENDER } = COLORS;

const ForgotPasswordMobaile: React.FC = () => {
  const [form] = AsnForm.useForm();
  const navigate = useNavigate();
  const { mutate: forgotPassword } = useForgotPassword();

  const onFinish = (values: { email: string }): void => {
    forgotPassword(values);
  };

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ margin: '0 auto', width: '100%', height: '100%' }}>
      <Col span={12} xs={24} sm={12} md={12} lg={12}>
        <Space direction='horizontal' style={{ padding: '20px 30px', width: '100%', height: 64 }}>
          <Logo onClick={(): void => navigate(PATHS.LOGGED_USER_LANDING)} style={{ cursor: 'pointer' }} />
        </Space>
        <Row style={{ width: '100%', height: '100%' }} justify={'center'} align={'middle'}>
          <AsnForm
            layout='vertical'
            form={form}
            validateMessages={validate_messages}
            style={{ maxWidth: '460px', width: '85vw', padding: ' 0 10px' }}
            onFinish={(values): void => onFinish(values as { email: string })}
          >
            <Row justify={'center'} style={{ marginBottom: 48 }}>
              <KeySvg />
            </Row>
            <Title level={3} style={{ textAlign: 'center', color: `${LAVENDER}`, marginBottom: '32px' }}>
              Forgot Password
            </Title>
            <Paragraph style={{ marginBottom: '32px', fontSize: 16 }}>
              Please enter the email you use to sign in to ProfLab.
            </Paragraph>
            <AsnForm.Item
              name='email'
              label='Email address'
              rules={[{ required: true, type: 'email', message: 'Invalid email address' }]}
            >
              <Input placeholder='Email address' />
            </AsnForm.Item>
            <AsnForm.Item>
              <MainButton text={'Reset Password'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
              <AsnButton type='text' className='text' onClick={(): void => navigate(PATHS.SIGNIN)}>
                Back To Log In
              </AsnButton>
            </AsnForm.Item>
          </AsnForm>
        </Row>
      </Col>
    </Row>
  );
};

export default ForgotPasswordMobaile;
