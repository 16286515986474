import React from 'react';
import { Flex } from 'antd';

import { COLORS } from '../../constants';
import { ReactComponent as BigDotsSvg } from '../../assets/icons/dots.svg';

const { LAVENDER, WHITE } = COLORS;

const width = window.innerWidth;

const ProductForCard: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Flex align='baseline' style={{ height: '100%', position: 'relative', background: `${WHITE}`, padding: 8 }}>
      <ul
        style={{
          color: `${LAVENDER}`,
          fontSize: width < 768 ? 16 : 20,
          // position: 'absolute',
          // top: '40%',
          // marginBottom: '0',
          paddingTop: 50,
        }}
      >
        <li>{text}</li>
      </ul>
      {width > 768 && (
        <div style={{ position: 'absolute', bottom: 30, right: 20 }}>
          <BigDotsSvg />
        </div>
      )}
    </Flex>
  );
};

export default ProductForCard;
