import React from 'react';
import { Row, Col, Typography } from 'antd';

import { COLORS } from '../../constants';
import { ProfessionCard } from '../../components';
import { IProfessions } from '../../types/careerPaths';

const { WHITE } = COLORS;
const { Paragraph } = Typography;

const Professions: React.FC<IProfessions> = ({ professions }) => {
  const wibdowWidth = window.innerWidth;

  return (
    <Row style={{ background: `${WHITE}`, padding: '32px 4%' }}>
      <Col span={24} xxl={{ pull: 2, push: 2, span: 20 }}>
        <Row gutter={[22, 22]} wrap justify={'center'} align={'middle'}>
          {professions?.map((profession) => (
            <Col
              key={profession?.id}
              xl={{ span: 6 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
              style={{ width: wibdowWidth < 768 ? 260 : 'auto' }}
            >
              <ProfessionCard content={'career'} className={profession?.checked ? 'selected' : ''}>
                <Paragraph style={{ fontSize: 20, textAlign: 'center', marginBottom: 0 }} ellipsis={{ rows: 3 }}>
                  {profession?.title}
                </Paragraph>
              </ProfessionCard>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default Professions;
