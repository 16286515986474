import styled from 'styled-components';
import { Typography, Col } from 'antd';

import { COLORS } from '../../constants';

const { Title } = Typography;
const { INDIGO } = COLORS;

export const AsnTitle = styled(Title)`
  &.ant-typography {
    font-size: 48px;
    color: ${INDIGO};
    white-space: nowrap;
    margin-bottom: 0;
    font-weight: 500;
  }
`;

export const LogoWrapper = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
