import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import { PATHS } from '../constants';
import { HomepageLayout } from '../components';
import HomepageLayoutMoabile from '../components/layouts/HomepageLatoutMobaile';

export const PrivateRoutes: React.FC = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();

  if (!user) {
    return <Navigate to={PATHS.LANDING} />;
  }
  const wibdowWidth = window.innerWidth;

  return (
    <>
      {wibdowWidth < 768 && pathname !== '/myinfo' ? (
        <HomepageLayoutMoabile
          points={{
            length: undefined,
            personalInfo: 0,
            totalPoints: 0,
            education: 0,
            experience: 0,
            languages: 0,
            training: 0,
            licenses: 0,
            skills: 0,
            cvAttachment: 0,
          }}
        >
          <Outlet />
        </HomepageLayoutMoabile>
      ) : wibdowWidth < 768 && pathname === '/myinfo' ? (
        <Outlet />
      ) : (pathname === '/home' || pathname === '/privacy') && wibdowWidth > 768 ? (
        <Outlet />
      ) : (
        <HomepageLayout>
          <Outlet />
        </HomepageLayout>
      )}
    </>
  );
};
