import { Row } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';

const { MAGNOLIA } = COLORS;

const RadioCard = styled(Row)`
  width: 100%;
  height: 100%;
  background: ${MAGNOLIA};
  border-radius: 10px;
  border: 0.2px solid rgba(104, 70, 142, 0.2);
  max-width: 352px;
  padding: 32px 15%;
`;

export default RadioCard;
