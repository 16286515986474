import { useQuery } from '@tanstack/react-query';

import client from '../client';
import { TGetProfessions, TUseGetProfessions } from '../../types/api/chat';

const url = '/api/career-coaching/user/:userId/professions';

const useGetProfessions: TUseGetProfessions = (userId, options) => {
  const result = useQuery({
    queryKey: [url, userId],
    queryFn: () => client.get(url.replace(':userId', userId)),
    ...options,
    select: (data) => data?.data?.result,
  });

  const { data, isLoading, isSuccess, isFetching, status } = result;
  return {
    data: isSuccess ? data : ([] as TGetProfessions[]),
    isLoading,
    isFetching,
    status,
  };
};

export default useGetProfessions;
