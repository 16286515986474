import { useQuery } from '@tanstack/react-query';

import client from '../client';
import { TGetQuestions, TUseGetQuestions } from '../../types/api/chat';

const url = '/api/questions';

const useGetQuestions: TUseGetQuestions = (options) => {
  const result = useQuery({
    queryKey: [url],
    queryFn: () => client.get(url),
    ...options,
    select: (data) => data?.data?.result,
  });

  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ([] as TGetQuestions[]),
    isLoading,
    isFetching,
  };
};

export default useGetQuestions;
