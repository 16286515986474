import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../client';
import { CheckTokenResponse, CheckTokenVariables, TUseCheckToken } from '../../types/api/auth';
const url = '/api/auth/token/check';

const useCheckToken: TUseCheckToken = () => {
  const mutation = useMutation<AxiosResponse<CheckTokenResponse>, AxiosError, CheckTokenVariables, unknown>({
    mutationFn: (token) => {
      return client.post(url, token);
    },
  });

  return mutation;
};

export default useCheckToken;
