import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Space, Typography, Row, Popover, Image } from 'antd';
import styled from 'styled-components';

import AsnMenu from '../../Menu';
import Points from '../../Points';
import { useAuth } from '../../../hooks/useAuth';
import { IMainLayout } from '../../../types/global';
import { COLORS, menuItemsHome, PATHS } from '../../../constants';
import { ReactComponent as Logo } from '../../../assets/icons/proflab.svg';
const { WHITE, GRAY, INDIGO } = COLORS;

const { Header, Content, Sider } = Layout;
const { Paragraph } = Typography;

const AsnSider = styled(Sider)`
  &.ant-layout-sider {
    background: ${WHITE};
    border-right: 1px solid ${GRAY};
  }
`;

const AsnHeader = styled(Header)`
  &.ant-layout-header {
    background-color: ${WHITE};
    border-bottom: 1px solid ${GRAY};
  }
`;

const HomepageLayout: React.FC<IMainLayout> = ({ children }) => {
  const [showPopover, setShowPoppover] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const title = searchParams.get('menu');

  const { logout, answeredQuestion, user } = useAuth();

  const handleMenuChange = (info: { key: string }): void => {
    const { key } = info;
    if ((user?.totalPoints ?? 0) < 49 && key === '/chat?menu=Chat') return;
    if (user?.hesSelectedProfession === false && key === '/career?menu=My Career Paths') return;
    navigate(key);
  };

  const navigateHomepage = (): void => {
    navigate(PATHS.LOGGED_USER_LANDING);
  };

  return (
    <Layout style={{ height: '100%', overflow: 'hidden' }} hasSider>
      <AsnSider>
        <Space
          direction='horizontal'
          style={{ padding: 15, width: '100%', borderBottom: `1px solid ${GRAY}`, height: 64, marginBottom: 24 }}
        >
          <Logo onClick={navigateHomepage} style={{ cursor: 'pointer' }} />
        </Space>
        <AsnMenu
          selectedKeys={
            pathname === '/chat' && search?.includes('status')
              ? [pathname + '?menu=Chat']
              : [pathname + decodeURIComponent(search)]
          }
          onSelect={(info): void => handleMenuChange(info)}
          className='homepage'
          items={menuItemsHome}
          mode='inline'
          inlineIndent={12}
        />
      </AsnSider>
      <Layout>
        <AsnHeader>
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Space>
              <Paragraph style={{ margin: 0, fontSize: 20, color: `${INDIGO}` }}>{title}</Paragraph>
              {pathname === '/chat' && <Points>{answeredQuestion}/28</Points>}
            </Space>
            <Row>
              <Popover
                content={
                  <Space direction='vertical' onClick={(): void => setShowPoppover(false)}>
                    <Paragraph
                      style={{ cursor: 'pointer', marginBottom: 0 }}
                      onClick={(): void => navigate(`${PATHS.MY_INFO}?menu=My info`)}
                    >
                      My info
                    </Paragraph>
                    <Paragraph
                      style={{ cursor: 'pointer', marginBottom: 0 }}
                      onClick={(): void => navigate(`${PATHS.CHANGE_PASSWORD}?menu=Change Password`)}
                    >
                      Change password
                    </Paragraph>
                    <Paragraph style={{ cursor: 'pointer', marginBottom: 0 }} onClick={(): void => logout()}>
                      Log out
                    </Paragraph>
                  </Space>
                }
                trigger='click'
                arrow={false}
                open={showPopover}
                onOpenChange={(open: boolean): void => setShowPoppover(open)}
              >
                <Row>
                  <Image
                    preview={false}
                    src={user?.photo ?? './assets/user.png'}
                    style={{ width: 42, height: 42, borderRadius: '50%', cursor: 'pointer' }}
                  />
                </Row>
              </Popover>
            </Row>
          </Space>
        </AsnHeader>
        <Content style={{ overflow: 'auto' }}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default HomepageLayout;
