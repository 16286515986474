import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../client';
import { TClickTrackVariables, TUseClickTracking } from '../../types/api/careerPaths';

const url = '/api/clicks/increment';

const useClickTracking: TUseClickTracking = () => {
  const mutation = useMutation<AxiosResponse<unknown>, AxiosError, TClickTrackVariables, unknown>({
    mutationFn: (type) => {
      return client.post(url, type);
    },
  });
  return mutation;
};

export default useClickTracking;
