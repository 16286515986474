import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { message } from 'antd';

import client from '../client';
import { TUseResendEmail, ResendEmailVariables } from '../../types/api/auth';
import { ISuccesMessage } from '../../types/global';

const url = '/api/auth/password/forget';

const useForgotPassword: TUseResendEmail = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, ResendEmailVariables, unknown>({
    mutationFn: (email) => {
      return client.post(url, email);
    },
    onSuccess: (data) => {
      void message.success(data?.data?.result as unknown as string, 4);
    },
    onError: (error) => {
      void message.error(error as unknown as string, 4);
    },
  });

  return mutation;
};

export default useForgotPassword;
