import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../client';
import { TUseCheckProfessions } from '../../types/api/chat';

const url = '/api/career-coaching/user/:userId/professions/:professionId/recommendation';

const useCheckProfession: TUseCheckProfessions = () => {
  const mutation = useMutation<AxiosResponse<unknown>, AxiosError, { userId: string; professionId: string }, unknown>({
    mutationFn: (values) => {
      return client.get(url.replace(':userId', values?.userId).replace(':professionId', values?.professionId));
    },
  });
  return mutation;
};

export default useCheckProfession;
