import styled from 'styled-components';
import { Row, Typography, Flex } from 'antd';

import { COLORS } from '../../constants';
import { IBenefitCard } from '../../types/components';

const { WHITE, LAVENDER } = COLORS;
const { Paragraph, Title } = Typography;

const Card = styled(Row)`
  height: 100%;
  border-radius: 8px;
  background-color: ${WHITE};
  padding: 24px;
`;

const BenefitCard: React.FC<IBenefitCard> = ({ text, src, title }) => {
  return (
    <Card>
      <Flex gap={32}>
        <img src={src} width={104} height={104} />
        <Flex vertical>
          <Title style={{ fontSize: 24, letterSpacing: '-1.3px', fontWeight: 400, maxWidth: 350 }}>{title}</Title>
          <Paragraph style={{ color: `${LAVENDER}`, fontSize: 16 }}>{text}</Paragraph>
        </Flex>
      </Flex>
    </Card>
  );
};

export default BenefitCard;
