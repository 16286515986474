import React, { useEffect } from 'react';
import { Input } from 'antd';

import AsnForm from '../Form';
import { ITrainingsForm } from '../../types/myInfo';
import { validateForm, normalize } from '../../helpers';

const TrainingsForm: React.FC<ITrainingsForm> = ({ title, description }) => {
  const formUpdate = AsnForm.useFormInstance();

  useEffect(() => {
    formUpdate.resetFields();
  }, []);

  return (
    <>
      <AsnForm.Item
        name='title'
        label='Title'
        rules={[
          { required: true },
          {
            validator: (_, value: string) => validateForm(_, value, 'Title', 3, 50),
          },
        ]}
        normalize={(value: string): string => normalize(value)}
        initialValue={title}
      >
        <Input autoComplete='off' />
      </AsnForm.Item>
      <AsnForm.Item
        name='description'
        label='Description'
        rules={[
          {
            validator: (_, value: string) =>
              value?.trim()?.length > 100
                ? Promise.reject(new Error('Description must be between 0-100 characters'))
                : Promise.resolve(),
          },
        ]}
        normalize={(value: string): string => normalize(value)}
        initialValue={description}
      >
        <Input.TextArea autoComplete='off' />
      </AsnForm.Item>
    </>
  );
};

export default TrainingsForm;
