import React from 'react';
import { Row, Col, Typography } from 'antd';

import { COLORS } from '../../constants';
import { BenefitCard } from '../../components';

const { Title } = Typography;
const { WHITE } = COLORS;

const width = window.innerWidth;

const Benefits: React.FC = () => {
  return (
    <>
      <Row
        style={{
          padding: '0px 16px',
          marginBottom: 8,
        }}
        id='benefits'
      >
        <Col span={24} style={{ background: `${WHITE}`, borderRadius: 8, padding: '26px 16px' }}>
          <Title level={3} style={{ fontWeight: 500, marginBottom: 0 }}>
            What you get
          </Title>
        </Col>
      </Row>
      <Row style={{ padding: '0px 16px', width: '100%', margin: '0px 0px 8px 0px' }} gutter={width < 992 ? [0, 8] : [8, 8]}>
        <Col
          xxl={{ span: 12 }}
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          style={{ paddingLeft: 0 }}
        >
          <BenefitCard
            src={'./assets/rocket.gif'}
            title='Completely Free Career Development Coach'
            text={
              'Our fist AI-based career development coach is completely free for users. AI technology will analyze your personal information and test results and generate personalized recommendations tailored to your strengths, areas for improvement, and goals. '
            }
          />
        </Col>
        <Col
          xxl={{ span: 12 }}
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          style={{ paddingRight: 0 }}
        >
          <BenefitCard
            src={'./assets/book.gif'}
            title='Course Matching'
            text={'ProfLab will suggest a selection of relevant courses to acquire the skills needed for your new career path.'}
          />
        </Col>
        <Col
          xxl={{ span: 12 }}
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          style={{ paddingLeft: 0 }}
        >
          <BenefitCard
            src={'./assets/data-processing.gif'}
            title='Data-Driven New Career Suggestions'
            text={
              'Based on your data analysis, ProfLab will suggest four matching career paths. This data-driven approach will help you make informed decisions and develop a career strategy grounded in factual information.'
            }
          />
        </Col>
        <Col
          xxl={{ span: 12 }}
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          style={{ paddingRight: 0 }}
        >
          <BenefitCard
            src={'./assets/database.gif'}
            title='Scoring System'
            text={
              'This system demonstrates the level of matching to the chosen career path based on analysis of different aspects of your background. This visual representation of your progress will motivate you and provide a clear understanding of your advancement.'
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default Benefits;
