import React, { useEffect, useState } from 'react';
import { Row, Col, Input, DatePicker, Checkbox } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';

import AsnForm from '../Form';
import { IExperienceForm } from '../../types/myInfo';
import { validateForm, normalize } from '../../helpers';
import { COLORS } from '../../constants';

const { DISABLED } = COLORS;

const AsnDatePicker = styled(DatePicker)`
  &.ant-picker.ant-picker-disabled {
    background: ${DISABLED};
  }
`;

const ExperienceForm: React.FC<IExperienceForm> = ({ position, companyName, description, startDate, endDate }) => {
  const [checked, setChecked] = useState<boolean>(endDate === null ? true : false);
  const formUpdate = AsnForm.useFormInstance();
  const wibdowWidth = window.innerWidth;

  const handleCheckboxChange = (e: { target: { checked: boolean } }): void => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      void formUpdate.validateFields(['endDate']);
      formUpdate.setFields([{ name: 'endDate', touched: true, value: undefined }]);
    }
  };

  const disabledDateStart: RangePickerProps['disabledDate'] = (current) => {
    const endDateValue = formUpdate.getFieldValue('endDate') as Dayjs;
    return current && ((endDateValue ? current > dayjs(endDateValue) : false) || current > dayjs().endOf('day'));
  };

  const disabledDateEnd: RangePickerProps['disabledDate'] = (current) => {
    const startDateValue = formUpdate.getFieldValue('startDate') as Dayjs;
    return current && (startDateValue ? current < dayjs(startDateValue) : false);
  };

  useEffect(() => {
    formUpdate.resetFields();
    if (endDate !== null) {
      setChecked(false);
    }
  }, []);

  return (
    <>
      <Row gutter={[32, 0]}>
        <Col span={wibdowWidth < 768 ? 24 : 12}>
          <AsnForm.Item
            name='position'
            label='Position'
            style={{ marginBottom: 16 }}
            rules={[
              { required: true },
              {
                validator: (_, value: string) => validateForm(_, value, 'Position', 3, 50),
              },
            ]}
            normalize={(value: string): string => normalize(value)}
            initialValue={position}
          >
            <Input autoComplete='off' />
          </AsnForm.Item>
        </Col>
        <Col span={wibdowWidth < 768 ? 24 : 12}>
          <AsnForm.Item
            name='companyName'
            label='Company Name, location'
            style={{ marginBottom: 16 }}
            rules={[
              {
                validator: (_, value: string) => validateForm(_, value, 'Company Name', 0, 50),
              },
            ]}
            normalize={(value: string): string => normalize(value)}
            initialValue={companyName}
          >
            <Input autoComplete='off' />
          </AsnForm.Item>
        </Col>
      </Row>
      <Checkbox style={{ marginBottom: 32 }} onChange={handleCheckboxChange} defaultChecked={endDate === null}>
        Current employment
      </Checkbox>
      <Row gutter={[32, 0]}>
        <Col span={wibdowWidth < 768 ? 24 : 12}>
          <AsnForm.Item
            name='startDate'
            label='Start date'
            rules={[{ required: true }]}
            initialValue={startDate ? dayjs(startDate) : undefined}
          >
            <DatePicker
              picker='month'
              style={{ width: '100%' }}
              suffixIcon={<DownOutlined />}
              placeholder=''
              disabledDate={disabledDateStart}
              defaultValue={startDate ? dayjs(startDate) : undefined}
            />
          </AsnForm.Item>
        </Col>
        <Col span={wibdowWidth < 768 ? 24 : 12}>
          <AsnForm.Item
            name='endDate'
            label='End date (or expected)'
            rules={[{ required: !checked }]}
            initialValue={endDate ? dayjs(endDate) : undefined}
          >
            <AsnDatePicker
              picker='month'
              disabled={checked}
              style={{ width: '100%' }}
              suffixIcon={<DownOutlined />}
              placeholder=''
              disabledDate={disabledDateEnd}
              defaultValue={endDate ? dayjs(endDate) : undefined}
            />
          </AsnForm.Item>
        </Col>
      </Row>
      <AsnForm.Item
        name='description'
        label='Description'
        rules={[
          {
            validator: (_, value: string) =>
              value?.trim()?.length > 200
                ? Promise.reject(new Error('Description must be between 0-100 characters'))
                : Promise.resolve(),
          },
        ]}
        normalize={(value: string): string => normalize(value)}
        initialValue={description}
      >
        <Input.TextArea autoComplete='off' />
      </AsnForm.Item>
    </>
  );
};

export default ExperienceForm;
