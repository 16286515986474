import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Input, DatePicker } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';

import { useGetDegrees, useGetFieldOfStudy } from '../../api';
import { selectProps } from '../../constants';
import AsnForm from '../Form';
import { IEducationForm } from '../../types/myInfo';
import { validateForm, normalize } from '../../helpers';

import { ColDatePicker } from './style';

const EducationForm: React.FC<IEducationForm> = ({ university, degreeField, studyField, startDate, endDate }) => {
  const [degree, setDegree] = useState<string>('');
  const [study, setStudy] = useState<string>('');

  const formUpdate = AsnForm.useFormInstance();
  const wibdowWidth = window.innerWidth;

  const { data: degrees, isFetching: isFetchingDegreees } = useGetDegrees(degree, {
    enabled: Boolean(degree),
    keepPreviousData: degree ? true : false,
    staleTime: degree ? 60 * 1000 : 0,
  });

  const { data: fieldOfStudies, isFetching: isFetchingFieldOfStudies } = useGetFieldOfStudy(study, {
    enabled: Boolean(study),
    keepPreviousData: study ? true : false,
    staleTime: study ? 60 * 1000 : 0,
  });

  const onSearchDegrees = (value: string): void => {
    if (degrees?.length === 0 && degree && value?.trim()?.replace(/\s+/g, ' ') > degree) return;
    if (isFetchingDegreees === false) {
      setDegree(value?.trim()?.replace(/\s+/g, ' '));
    }
  };

  const onSearchFieldOfStudies = (value: string): void => {
    if (fieldOfStudies?.length === 0 && study && value?.trim()?.replace(/\s+/g, ' ') > study) return;
    if (isFetchingFieldOfStudies === false) {
      setStudy(value?.trim()?.replace(/\s+/g, ' '));
    }
  };

  const disabledDateStart: RangePickerProps['disabledDate'] = (current) => {
    const endDateValue = formUpdate.getFieldValue('endDate') as Dayjs;
    return current && ((endDateValue ? current > dayjs(endDateValue) : false) || current > dayjs().endOf('day'));
  };

  const disabledDateEnd: RangePickerProps['disabledDate'] = (current) => {
    const startDateValue = formUpdate.getFieldValue('startDate') as Dayjs;
    return current && (startDateValue ? current < dayjs(startDateValue) : false);
  };

  useEffect(() => {
    formUpdate.resetFields();
  }, []);

  return (
    <>
      <AsnForm.Item
        name='university'
        label='University, location'
        rules={[
          { required: true },
          {
            validator: (_, value: string) => validateForm(_, value, 'University', 3, 50),
          },
        ]}
        initialValue={university}
        normalize={(value: string): string => normalize(value)}
      >
        <Input autoComplete='off' />
      </AsnForm.Item>
      <Row gutter={[32, 0]}>
        <Col span={wibdowWidth < 768 ? 24 : 12}>
          <AsnForm.Item name='degreeId' label='Degree' initialValue={degreeField?.title} rules={[{ required: true }]}>
            <Select
              options={degrees?.map((item) => {
                return {
                  label: item?.title,
                  value: item?.id,
                };
              })}
              onSearch={onSearchDegrees}
              loading={isFetchingDegreees}
              onClear={(): void => setDegree('')}
              defaultValue={{
                label: degreeField?.title,
                value: degreeField?.id,
              }}
              {...selectProps}
            />
          </AsnForm.Item>
        </Col>
        <Col span={wibdowWidth < 768 ? 24 : 12}>
          <AsnForm.Item name='filedId' label='Field of study' rules={[{ required: true }]} initialValue={studyField?.title}>
            <Select
              options={fieldOfStudies?.map((item) => {
                return {
                  label: item?.title,
                  value: item?.id,
                };
              })}
              onSearch={onSearchFieldOfStudies}
              loading={isFetchingFieldOfStudies}
              onClear={(): void => setStudy('')}
              defaultValue={{
                label: studyField?.title,
                value: studyField?.id,
              }}
              {...selectProps}
            />
          </AsnForm.Item>
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        {wibdowWidth < 768 ? (
          <>
            <ColDatePicker span={wibdowWidth < 768 ? 24 : 12}>
              <AsnForm.Item name='startDate' label='Start date' initialValue={startDate ? dayjs(startDate) : undefined}>
                <DatePicker
                  picker='month'
                  style={{ width: '100%' }}
                  suffixIcon={<DownOutlined />}
                  placeholder=''
                  disabledDate={disabledDateStart}
                  defaultValue={startDate ? dayjs(startDate) : undefined}
                />
              </AsnForm.Item>
            </ColDatePicker>
            <ColDatePicker span={wibdowWidth < 768 ? 24 : 12}>
              <AsnForm.Item name='endDate' label='End date (or expected)' initialValue={endDate ? dayjs(endDate) : undefined}>
                <DatePicker
                  picker='month'
                  style={{ width: '100%' }}
                  suffixIcon={<DownOutlined />}
                  placeholder=''
                  disabledDate={disabledDateEnd}
                  defaultValue={endDate ? dayjs(endDate) : undefined}
                />
              </AsnForm.Item>
            </ColDatePicker>
          </>
        ) : (
          <>
            {' '}
            <Col span={wibdowWidth < 768 ? 24 : 12}>
              <AsnForm.Item name='startDate' label='Start date' initialValue={startDate ? dayjs(startDate) : undefined}>
                <DatePicker
                  picker='month'
                  style={{ width: '100%' }}
                  suffixIcon={<DownOutlined />}
                  placeholder=''
                  disabledDate={disabledDateStart}
                  defaultValue={startDate ? dayjs(startDate) : undefined}
                  className='efefrefrfr'
                />
              </AsnForm.Item>
            </Col>
            <Col span={wibdowWidth < 768 ? 24 : 12}>
              <AsnForm.Item name='endDate' label='End date (or expected)' initialValue={endDate ? dayjs(endDate) : undefined}>
                <DatePicker
                  picker='month'
                  style={{ width: '100%' }}
                  suffixIcon={<DownOutlined />}
                  placeholder=''
                  disabledDate={disabledDateEnd}
                  defaultValue={endDate ? dayjs(endDate) : undefined}
                />
              </AsnForm.Item>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default EducationForm;
