import styled from 'styled-components';
import { Tag } from 'antd';

import { COLORS } from '../../constants';

const { INDIGO } = COLORS;

const AsnTag = styled(Tag)`
  &.ant-tag {
    padding: 4px 8px;
    display: inline-flex;
  }
  svg {
    fill: ${INDIGO};
  }
`;

export default AsnTag;
