import styled from 'styled-components';
import { Upload } from 'antd';

import { COLORS } from '../../constants';

const { Dragger } = Upload;
const { WHITE, DARK_GRAY } = COLORS;

const AsnDragger = styled(Dragger)`
  width: 100%;
  &.ant-upload-wrapper .ant-upload-drag .ant-upload-drag-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.ant-upload-wrapper .ant-upload-drag {
    background: ${WHITE};
  }
  &.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: ${DARK_GRAY};
  }
`;

export default AsnDragger;
