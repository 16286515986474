import React, { useState } from 'react';
import { Row, Col, CollapseProps, Typography, Progress, Space, Select, Spin, message } from 'antd';
import styled from 'styled-components';

import { AsnButton, AsnCollapse, AsnForm, Points, AsnTag } from '../../components';
import { COLORS, selectProps } from '../../constants';
import { useGetSkills, useAddSkills, useUpdateSkills } from '../../api';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrowUp.svg';
import { ReactComponent as SkillsSvg } from '../../assets/icons/skills.svg';
import { ReactComponent as AddSvg } from '../../assets/icons/add.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { ISkill, ISkills, ISkillsFormItem } from '../../types/myInfo';
import { MainButton } from '../../components/Button';

const { INDIGO, LAVENDER, GRAY, WHITE, DARK_GRAY } = COLORS;

const { Text } = Typography;

const SelectWrapper = styled(Space)`
  &.ant-space > div:first-child {
    width: 100%;
  }
`;

const Skills: React.FC<ISkills> = ({ userId, skillsData, points, isFetchingUser, setField }) => {
  const [edit, setEdit] = useState<boolean>(!skillsData.length);
  const [skill, setSkill] = useState<string>('');
  const [selectetdSkill, setSelectedSkill] = useState<ISkill | undefined>();

  const [form] = AsnForm.useForm();

  const { data: skills, isFetching: isFetchingSkills } = useGetSkills(skill, {
    enabled: Boolean(skill),
    keepPreviousData: skill ? true : false,
    staleTime: skill ? 60 * 1000 : 0,
  });

  const { mutate: addSkills } = useAddSkills();
  const { mutate: updateSkills } = useUpdateSkills();
  const wibdowWidth = window.innerWidth;
  const onSearchSkills = (value: string): void => {
    if (skills?.length === 0 && skill && value?.trim()?.replace(/\s+/g, ' ') > skill) return;
    if (isFetchingSkills === false) {
      setSkill(value?.trim()?.replace(/\s+/g, ' '));
    }
  };

  const onFinish = (): void => {
    const values = [...(form.getFieldValue('skills') as ISkillsFormItem[])];
    const requestData = values?.map((item) => {
      return { skillId: item.id };
    });

    if (skillsData?.length) {
      updateSkills({ id: userId, skills: requestData });
    } else {
      addSkills({ id: userId, skills: requestData });
    }
    values?.length ? setEdit(false) : setEdit(true);
    setField('skills');
  };

  const onSelect = (value: string, option: ISkill): void => {
    setSelectedSkill(option);
  };

  const onClearSelect = (): void => {
    setSkill('');
    setSelectedSkill(undefined);
    form?.setFieldValue('skill', undefined);
  };

  const onAddSkill = (): void => {
    const skills = form?.getFieldValue('skills') as ISkillsFormItem[];

    const doublicatedSkills = skills?.find((skill) => skill.id === selectetdSkill?.value)?.id;
    if (skills?.length >= 12) {
      void message.error('Maximum skills count exceeded', 4);
      return;
    }
    if (selectetdSkill) {
      form.resetFields();
    }

    if (selectetdSkill && !doublicatedSkills) {
      form.setFieldValue('skills', [...skills, { id: selectetdSkill?.value, title: selectetdSkill?.label }]);
    }
    if (!selectetdSkill) {
      form.setFields([
        {
          name: 'skill',
          errors: ['Please enter skill'],
        },
      ]);
    }
  };

  const onDeletTag = (index: number): void => {
    const skills = [...(form.getFieldValue('skills') as ISkillsFormItem[])];
    skills?.splice(index, 1);

    form.setFieldValue('skills', skills);
  };

  const handleCancel = (): void => {
    setEdit(false);
    form.resetFields();
  };

  const handleAdd = (): void => {
    setEdit(true);
    form.resetFields();
  };

  const handleCollapseChange = (key: string[] | string): void => {
    if (key?.length) {
      !skillsData?.length ? setEdit(true) : setEdit(false);
      form.resetFields();
    }
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <Row align={'middle'} style={{ gap: 4 }}>
          <Points
            style={{
              height: wibdowWidth < 768 ? 32 : 54,
              width: wibdowWidth < 768 ? 32 : 54,
              fontSize: wibdowWidth < 768 ? 11 : 16,
            }}
          >
            {points ?? 0}/36
          </Points>
          <SkillsSvg />
          <Text style={{ fontSize: wibdowWidth < 768 ? 14 : 20, color: `${INDIGO}` }}>
            Skills (hard and soft skills, up to 12)
          </Text>
        </Row>
      ),
      children: isFetchingUser ? (
        <Row justify={'center'}>
          <Spin />
        </Row>
      ) : (
        <>
          <Row align={'middle'} justify={'end'}>
            <AddSvg style={{ cursor: 'pointer' }} onClick={handleAdd} />
          </Row>
          <Progress
            style={{ marginBottom: 0 }}
            percent={20}
            strokeColor={LAVENDER}
            showInfo={false}
            size={['100%', 1]}
            trailColor={GRAY}
          />
          <Space
            direction='vertical'
            style={{
              width: '100%',
              padding: '16px 0px',
              marginBottom: 32,
              borderBottom: skillsData?.length && !edit ? `2px solid ${DARK_GRAY}` : '',
            }}
          >
            <Space
              direction='horizontal'
              style={{
                width: '100%',
                justifyContent: 'space-between',
              }}
              align='center'
            >
              {!!skillsData?.length && <Text style={{ color: `${DARK_GRAY}` }}>Skills</Text>}
              {!!skillsData?.length && !edit && <EditSvg style={{ cursor: 'pointer' }} onClick={(): void => setEdit(true)} />}
            </Space>
            <AsnForm form={form} layout='vertical' onFinish={onAddSkill}>
              <AsnForm.List name='skills' initialValue={skillsData ?? []}>
                {(fields): JSX.Element => (
                  <>
                    <Space align='baseline' direction='horizontal' wrap>
                      {fields.map(({ key, name, ...restField }) => (
                        <AsnForm.Item
                          {...restField}
                          name={[name, 'id']}
                          key={key}
                          style={{ marginBottom: wibdowWidth < 768 ? 5 : 'auto' }}
                        >
                          <AsnTag closable={skillsData?.length ? edit : true} onClose={(): void => onDeletTag(name)}>
                            {form?.getFieldValue(['skills', name, 'title'])}
                          </AsnTag>
                        </AsnForm.Item>
                      ))}
                    </Space>
                  </>
                )}
              </AsnForm.List>
              {edit &&
                (wibdowWidth < 768 ? (
                  <Row gutter={[0, 10]}>
                    <Col span={24}>
                      <AsnForm.Item
                        name='skill'
                        label={'Skill'}
                        style={{ width: '100%', marginRight: 16, marginBottom: 0 }}
                        rules={[{ required: true }]}
                      >
                        <SelectWrapper direction='horizontal' style={{ width: '100%' }}>
                          <Select
                            options={skills?.map((item) => {
                              return {
                                label: item?.title,
                                value: item?.id,
                              };
                            })}
                            onSearch={onSearchSkills}
                            loading={isFetchingSkills}
                            onClear={onClearSelect}
                            onSelect={onSelect}
                            {...selectProps}
                          />
                        </SelectWrapper>
                      </AsnForm.Item>
                    </Col>
                    <Col span={24}>
                      {' '}
                      <MainButton text={'Add'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
                    </Col>
                    <Col span={24} style={{ borderBottom: `1px solid ${DARK_GRAY}`, marginBottom: 30 }}></Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={12}>
                      <AsnForm.Item
                        name='skill'
                        label={'Skill'}
                        style={{ width: '100%', marginRight: 16, marginBottom: 0 }}
                        rules={[{ required: true }]}
                      >
                        <SelectWrapper direction='horizontal' style={{ width: '100%' }}>
                          <Select
                            options={skills?.map((item) => {
                              return {
                                label: item?.title,
                                value: item?.id,
                              };
                            })}
                            onSearch={onSearchSkills}
                            loading={isFetchingSkills}
                            onClear={onClearSelect}
                            onSelect={onSelect}
                            {...selectProps}
                          />
                          <MainButton text={'Add'} icon={<DotsSvg />} htmlType='submit' />
                        </SelectWrapper>
                      </AsnForm.Item>
                    </Col>
                  </Row>
                ))}
              {edit &&
                (wibdowWidth < 768 ? (
                  <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                    <Col span={24}>
                      <AsnForm.Item style={{ marginBottom: 0 }}>
                        <MainButton
                          text={'Save'}
                          icon={<DotsSvg />}
                          htmlType='submit'
                          style={{ width: '100%' }}
                          onClick={onFinish}
                        />
                      </AsnForm.Item>
                    </Col>
                    <Col span={24}>
                      <AsnButton onClick={(): void => handleCancel()} style={{ width: '100%' }}>
                        Cancel
                      </AsnButton>
                    </Col>
                  </Row>
                ) : (
                  <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                    <AsnButton onClick={(): void => handleCancel()}>Cancel</AsnButton>
                    <AsnForm.Item style={{ marginBottom: 0 }}>
                      <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' onClick={onFinish} />
                    </AsnForm.Item>
                  </Row>
                ))}
            </AsnForm>
          </Space>
        </>
      ),
    },
  ];

  return (
    <Row style={{ background: `${WHITE}` }}>
      <Col span={24} xxl={{ span: 21, push: 3 }}>
        <AsnCollapse
          items={items}
          bordered={false}
          expandIconPosition={'end'}
          expandIcon={({ isActive }): React.ReactNode => (isActive ? <ArrowUp /> : <ArrowDown />)}
          onChange={handleCollapseChange}
          defaultActiveKey={skillsData?.length ? ['1'] : []}
        />
      </Col>
    </Row>
  );
};
export default Skills;
