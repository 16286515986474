import React from 'react';
import { Space, Typography } from 'antd';
import dayjs from 'dayjs';

import { COLORS } from '../../constants';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { ReactComponent as CloseSvg } from '../../assets/icons/close.svg';
import { IUserInfoCard } from '../../types/components';

const { Text } = Typography;
const { DARK_GRAY } = COLORS;

const UserInfoCard: React.FC<IUserInfoCard> = ({ title, startDate, endDate, children, handleEdit, setId, info, edit, id }) => {
  return (
    <Space direction='vertical' style={{ width: '100%' }} size={0}>
      <Space
        direction='horizontal'
        style={{
          width: '100%',
          justifyContent: 'space-between',
          padding: '16px 0px',
          borderBottom: `2px solid ${DARK_GRAY}`,
          marginBottom: 32,
        }}
        align='center'
      >
        <Space direction='vertical' size={4}>
          <Text style={{ color: `${DARK_GRAY}` }}>{title}</Text>
          <Text style={{ color: `${DARK_GRAY}` }}>
            {info
              ? info
              : `${dayjs(startDate).format('MMMM YYYY')} - ${endDate ? dayjs(endDate).format('MMMM YYYY') : 'present'}`}
          </Text>
        </Space>
        <Space direction='horizontal'>
          {edit !== id && <EditSvg style={{ cursor: 'pointer' }} onClick={(): void => handleEdit()} />}
          <CloseSvg style={{ cursor: 'pointer' }} onClick={(): void => setId(id)} />
        </Space>
      </Space>
      {children}
    </Space>
  );
};

export default UserInfoCard;
