import React, { useRef, useState, useEffect } from 'react';
import { Row, Space, Typography, InputRef, Spin } from 'antd';
import styled from 'styled-components';
import { TypeAnimation } from 'react-type-animation';

import { AsnForm, AsnButton } from '../../components';
import { COLORS } from '../../constants';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { IAnswer, IFormList } from '../../types/chat';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { MainButton } from '../../components/Button';

import CheckboxField from './CheckboxField';
import InputTextArea from './InputTextArea';
import RadioField from './RadioField';

const { WHITE, INDIGO } = COLORS;
const { Text } = Typography;

const AsnRow = styled(Row)`
  position: relative;
  &.ant-row {
    svg {
      position: absolute;
      right: 15%;
      top: 16px;
      visibility: hidden;
    }

    &:hover {
      svg {
        visibility: visible;
        cursor: pointer;
        right: 8%;
        top: 6px;
      }
    }
  }
`;

const FormList: React.FC<IFormList> = ({ questions, questionIndex, scrollRef }) => {
  const [fieldAnswer, setFieldAnswer] = useState<string[] | string>('');
  const [edit, setEdit] = useState<boolean | string>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const form = AsnForm.useFormInstance();
  const wibdowWidth = window.innerWidth;

  const inputRef = useRef<InputRef | null>(null);

  const handleEdit = (field: IAnswer): void => {
    setEdit(field?.questionId);
    if (field?.answers?.length) {
      setFieldAnswer(field?.answers);
    } else {
      setFieldAnswer(field?.textAnswer);
    }
    setTimeout(() => {
      inputRef.current?.focus({
        cursor: 'end',
      });
    }, 0);
  };

  const handleCancelEdit = (name: number, type: string): void => {
    if (type === 'SHORT_TEXT') {
      const value = form.getFieldValue(['answers', name, 'textAnswer']) as string;
      fieldAnswer !== value ? form.setFieldValue(['answers', name, 'textAnswer'], fieldAnswer) : false;
    }
    if (type === 'OPTION') {
      const value = form.getFieldValue(['answers', name, 'answers', 0]) as string;
      fieldAnswer[0] !== value ? form.setFieldValue(['answers', name, 'answers', 0], fieldAnswer[0]) : '';
    }
    if (type === 'CHECKBOX') {
      const value = form.getFieldValue(['answers', name, 'answers']) as string[];
      JSON.stringify(fieldAnswer) !== JSON.stringify(value) ? form.setFieldValue(['answers', name, 'answers'], fieldAnswer) : '';
    }
    setEdit(false);
  };

  const handleSave = (name: number, type: string): void => {
    const textField = form.getFieldValue(['answers', name, 'textAnswer']) as string;
    const checkboxField = form.getFieldValue(['answers', name, 'answers']) as string[];
    setTimeout(() => {
      inputRef.current?.focus({
        cursor: 'end',
      });
    }, 0);
    if (type === 'SHORT_TEXT' && !textField?.length) return;
    if (type === 'SHORT_TEXT' && textField?.length && textField !== fieldAnswer) {
      form.setFieldValue(['answers', name, 'textAnswer'], textField.trim());
    }
    if (type === 'CHECKBOX' && !checkboxField?.length) {
      form.setFields([
        {
          name: ['answers', name, 'answers'],
          errors: ['Please select field'],
        },
      ]);
      return;
    }
    setEdit(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 800);
    return () => {
      clearTimeout(timeoutId);
      setLoading(true);
    };
  }, [questionIndex]);

  return (
    <AsnForm.List name='answers' initialValue={[]}>
      {(fields): JSX.Element => (
        <>
          <Space direction='vertical' style={{ width: '100%' }} ref={scrollRef}>
            {questions?.map(
              (question, i) =>
                questionIndex >= i && (
                  <React.Fragment key={question?.id}>
                    <Row style={{ width: '100%', padding: wibdowWidth < 768 ? 0 : '32px 0px' }} align={'top'} justify={'center'}>
                      {questionIndex === i && loading ? (
                        <Spin />
                      ) : (
                        <Text
                          style={{
                            maxWidth: wibdowWidth < 768 ? 'auto' : '42vw',
                            width: '100%',
                            color: `${INDIGO}`,
                            fontWeight: 600,
                          }}
                        >
                          {questionIndex === i ? <TypeAnimation sequence={[question?.title]} speed={80} /> : question?.title}
                        </Text>
                      )}
                    </Row>
                    {fields?.map(
                      ({ name }) =>
                        question?.id === form?.getFieldValue(['answers', name, 'questionId']) && (
                          <AsnRow
                            style={{
                              background: `${WHITE}`,
                              width: '100%',
                              padding: wibdowWidth < 768 ? '20px 0px' : '16px 0px',
                            }}
                            align={'top'}
                            justify={'center'}
                            key={i}
                          >
                            {edit !== form?.getFieldValue(['answers', name, 'questionId']) && (
                              <EditSvg
                                onClick={(): void => handleEdit(form?.getFieldValue(['answers', name]) as IAnswer)}
                                style={{ zIndex: 1 }}
                              />
                            )}
                            <Space direction='vertical' style={{ width: '100%', maxWidth: wibdowWidth < 768 ? 'auto' : '42vw' }}>
                              {question?.questionType === 'SHORT_TEXT' ? (
                                <InputTextArea name={name} inputRef={inputRef} edit={edit} />
                              ) : question?.questionType === 'OPTION' ? (
                                <RadioField name={name} edit={edit} answers={question?.answers} />
                              ) : (
                                <CheckboxField
                                  fieldAnswer={fieldAnswer as string[]}
                                  name={name}
                                  edit={edit}
                                  answers={question?.answers}
                                  count={question?.answersCount}
                                />
                              )}
                              {edit === form?.getFieldValue(['answers', name, 'questionId']) && (
                                <Row
                                  style={{ maxWidth: wibdowWidth < 768 ? 'auto' : '42vw', width: '100%', gap: 16 }}
                                  justify={'end'}
                                  className='editQuestion'
                                >
                                  <MainButton
                                    text={'Next'}
                                    icon={<DotsSvg />}
                                    onClick={(): void => handleSave(name, question?.questionType)}
                                    style={{ width: wibdowWidth < 768 ? '100%' : 90 }}
                                  />
                                  <AsnButton
                                    onClick={(): void => handleCancelEdit(name, question?.questionType)}
                                    style={{ width: wibdowWidth < 768 ? '100%' : 'auto' }}
                                  >
                                    Cancel
                                  </AsnButton>
                                </Row>
                              )}
                            </Space>
                          </AsnRow>
                        ),
                    )}
                  </React.Fragment>
                ),
            )}
          </Space>
        </>
      )}
    </AsnForm.List>
  );
};

export default FormList;
