import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Row, Col, CollapseProps, Typography, Progress, Spin, message } from 'antd';

import { AsnButton, AsnCollapse, AsnForm, Points, UserInfoCard, TrainingsForm, DeleteModal } from '../../components';
import { COLORS, validate_messages } from '../../constants';
import { useAddTraining, useUpdateTraining, useDeleteTraining } from '../../api';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrowUp.svg';
import { ReactComponent as TrainingsSvg } from '../../assets/icons/trainings.svg';
import { ReactComponent as AddSvg } from '../../assets/icons/add.svg';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { ITrainingVariables, ITrainings, TOnUpdateTraining } from '../../types/myInfo';
import { MainButton } from '../../components/Button';

const { INDIGO, LAVENDER, GRAY, WHITE } = COLORS;

const { Text } = Typography;

const Trainings: React.FC<ITrainings> = ({ userId, trainings, points, isFetchingUser, setField }) => {
  const [edit, setEdit] = useState<string | boolean>(false);
  const [create, setCreate] = useState<boolean>(!trainings?.length);
  const [openDeleteModalById, setOpenDeleteModalById] = useState<string | boolean>(false);

  const queryClient = useQueryClient();
  const wibdowWidth = window.innerWidth;
  const [formCreate] = AsnForm.useForm();
  const [formUpdate] = AsnForm.useForm();

  const { mutate: addTraining } = useAddTraining();
  const { mutate: deleteTraining } = useDeleteTraining();
  const { mutate: updateTraining } = useUpdateTraining();

  const onFinish = (values: ITrainingVariables): void => {
    const requestData = { title: values?.title?.trimEnd(), description: values?.description?.trimEnd() };
    addTraining({ id: userId, training: requestData });
    setCreate(false);
    setField('trainings');
    formCreate.resetFields();
  };

  const handleClickAdd = (): void => {
    setEdit(false);
    setCreate(true);
    formCreate.resetFields();
  };

  const handleCancelCreate = (): void => {
    formCreate.resetFields();
    setCreate(false);
  };

  const handleCancelUpdate = (): void => {
    formUpdate.resetFields();
    setEdit(false);
    setCreate(false);
  };

  const handleEdit = (trainingId: string): void => {
    setEdit(trainingId);
  };

  const handleDelete = (): void => {
    void deleteTraining(
      { userId, infoId: openDeleteModalById as string },
      {
        onSuccess: () => {
          if (trainings?.length === 1) {
            setCreate(true);
          }
          setEdit(false);
          setField('trainings');
          setOpenDeleteModalById(false);
          void queryClient.invalidateQueries(['/api/user/:id']);
        },
        onError: (error) => {
          void message.error(error as unknown as string, 2);
        },
      },
    );
  };

  const onUpdateTraining: TOnUpdateTraining = (title, description, trainingId): void => {
    const values = formUpdate.getFieldsValue(true, (meta) => meta.touched === true) as ITrainingVariables;
    const descriptionField = values?.description === '' ? null : values?.description?.trimEnd();

    const keys = Object.keys(values);
    const requsetData = {
      ...(keys?.includes('title') && title !== values?.title?.trimEnd() ? { title: values?.title?.trimEnd() } : {}),
      ...(keys?.includes('description') && description !== descriptionField ? { description: descriptionField } : {}),
    };

    if (Object.keys(requsetData).length) {
      updateTraining({
        id: userId,
        training: { ...(requsetData as ITrainingVariables), id: trainingId },
      });
      setField('trainings');
    }
    setEdit(false);
  };

  const handleCollapseChange = (key: string[] | string): void => {
    if (key?.length) {
      !trainings?.length ? setCreate(true) : setCreate(false);
      setEdit(false);
      formCreate.resetFields();
    }
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <Row align={'middle'} style={{ gap: 4 }}>
          <Points
            style={{
              height: wibdowWidth < 768 ? 32 : 54,
              width: wibdowWidth < 768 ? 32 : 54,
              fontSize: wibdowWidth < 768 ? 11 : 16,
            }}
          >
            {points ?? 0}/10
          </Points>
          <TrainingsSvg />
          <Text style={{ fontSize: wibdowWidth < 768 ? 14 : 20, color: `${INDIGO}` }}>Trainings (up to 5 trainings)</Text>
        </Row>
      ),
      children: isFetchingUser ? (
        <Row justify={'center'}>
          <Spin />
        </Row>
      ) : (
        <>
          <Row align={'middle'} justify={'end'}>
            <AddSvg style={{ cursor: 'pointer' }} onClick={handleClickAdd} />
          </Row>
          <Progress
            style={{ marginBottom: 0 }}
            percent={20}
            strokeColor={LAVENDER}
            showInfo={false}
            size={['100%', 1]}
            trailColor={GRAY}
          />
          {!!trainings?.length &&
            trainings?.map((training) => (
              <UserInfoCard
                id={training?.id as string}
                key={training?.id}
                edit={edit as string}
                title={'Training'}
                info={training?.title}
                setId={setOpenDeleteModalById}
                handleEdit={(): void => handleEdit(training?.id as string)}
              >
                {edit === training?.id && (
                  <AsnForm
                    form={formUpdate}
                    layout='vertical'
                    onFinish={(): void => onUpdateTraining(training?.title, training?.description, training?.id as string)}
                    validateMessages={validate_messages}
                  >
                    <TrainingsForm title={training?.title} description={training?.description} />
                    {wibdowWidth < 768 ? (
                      <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                        <Col span={24}>
                          <AsnForm.Item style={{ marginBottom: 0 }}>
                            <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
                          </AsnForm.Item>
                        </Col>
                        <Col span={24}>
                          <AsnButton onClick={(): void => handleCancelUpdate()} style={{ width: '100%' }}>
                            Cancel
                          </AsnButton>
                        </Col>
                      </Row>
                    ) : (
                      <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                        <AsnButton onClick={(): void => handleCancelUpdate()}>Cancel</AsnButton>
                        <AsnForm.Item style={{ marginBottom: 0 }}>
                          <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' />
                        </AsnForm.Item>
                      </Row>
                    )}
                  </AsnForm>
                )}
              </UserInfoCard>
            ))}
          {!edit && create && (
            <AsnForm
              form={formCreate}
              layout='vertical'
              validateMessages={validate_messages}
              onFinish={(values): void => onFinish(values as ITrainingVariables)}
            >
              <TrainingsForm />

              {wibdowWidth < 768 ? (
                <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                  <Col span={24}>
                    <AsnForm.Item style={{ marginBottom: 0 }}>
                      <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
                    </AsnForm.Item>
                  </Col>
                  <Col span={24}>
                    <AsnButton onClick={(): void => handleCancelCreate()} style={{ width: '100%' }}>
                      Cancel
                    </AsnButton>
                  </Col>
                </Row>
              ) : (
                <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                  <AsnButton onClick={(): void => handleCancelCreate()}>Cancel</AsnButton>
                  <AsnForm.Item style={{ marginBottom: 0 }}>
                    <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' />
                  </AsnForm.Item>
                </Row>
              )}
            </AsnForm>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Row style={{ background: `${WHITE}` }}>
        <Col span={24} xxl={{ span: 21, push: 3 }}>
          <AsnCollapse
            items={items}
            bordered={false}
            expandIconPosition={'end'}
            expandIcon={({ isActive }): React.ReactNode => (isActive ? <ArrowUp /> : <ArrowDown />)}
            onChange={handleCollapseChange}
            defaultActiveKey={trainings?.length ? ['1'] : []}
          />
        </Col>
      </Row>
      {!!openDeleteModalById && (
        <DeleteModal
          openDeleteModal={!!openDeleteModalById}
          setOpenDeleteModal={setOpenDeleteModalById}
          title={'Are you sure to delete this training?'}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};
export default Trainings;
