import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Row, Col, Input, Typography, Spin } from 'antd';
import { Rule, RuleObject } from 'antd/es/form';

import { useResetPassword, useCheckToken } from '../../api';
import { AsnForm, ExpiredLink } from '../../components';
import { COLORS, passwordRegExp, validate_messages } from '../../constants';
import { ResetPasswordVariables } from '../../types/api/auth';
import { ReactComponent as LogoSvg } from '../../assets/icons/logoBig.svg';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { MainButton } from '../../components/Button';

import { LogoWrapper } from './styles';
import ResetPasswordMobaile from './ResetPasswordMobaile';

const { Title, Paragraph } = Typography;
const { LAVENDER } = COLORS;

const ResetPassword: React.FC = () => {
  const [isValidToken, setIsValidToken] = useState<boolean | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);

  const [form] = AsnForm.useForm();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') as string;

  const { mutate: resetpassword } = useResetPassword();
  const { mutate: chechkToken } = useCheckToken();
  const wibdowWidth = window.innerWidth;

  const rulesConfirmPassword: Rule[] = [
    {
      required: true,
    },
    {
      pattern: passwordRegExp,
    },
    ({ getFieldValue }): RuleObject => ({
      async validator(_, value): Promise<void> {
        if (getFieldValue('password') === value) {
          return await Promise.resolve();
        }
        return await Promise.reject(new Error('The two passwords that you entered do not match!'));
      },
    }),
  ];

  const onFinish = (values: ResetPasswordVariables): void => {
    resetpassword({
      ...values,
      token,
    });
  };

  useEffect(() => {
    if (token) {
      chechkToken(
        { token },
        {
          onSuccess: (data) => {
            setIsValidToken(data?.data?.valid);
            if (!data?.data?.valid) {
              setEmail(data?.data?.email as string);
            }
          },
        },
      );
    }
  }, [token]);

  if (isValidToken === undefined) {
    return (
      <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
        <Spin />
      </Row>
    );
  }

  return (
    <>
      {wibdowWidth >= 575 ? (
        <>
          {isValidToken ? (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ width: '100%', height: '100%' }}>
              <Col span={12} xs={24} sm={12} md={12} lg={12}>
                <Row style={{ width: '100%', height: '100%' }} justify={'center'} align={'middle'}>
                  <AsnForm
                    layout='vertical'
                    form={form}
                    validateMessages={validate_messages}
                    style={{ maxWidth: '460px', width: '85vw', padding: ' 0 10px' }}
                    onFinish={(values): void => onFinish(values as ResetPasswordVariables)}
                  >
                    <Title level={3} style={{ textAlign: 'center', color: `${LAVENDER}`, marginBottom: '32px' }}>
                      Reset Password
                    </Title>
                    <Paragraph style={{ marginBottom: '32px', fontSize: 16 }}>
                      At least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number
                    </Paragraph>
                    <AsnForm.Item name='password' label='New Password' rules={[{ required: true }, { pattern: passwordRegExp }]}>
                      <Input.Password placeholder='Password' />
                    </AsnForm.Item>
                    <AsnForm.Item
                      name='repeatPassword'
                      label='Confirm Password'
                      rules={rulesConfirmPassword}
                      dependencies={['password']}
                    >
                      <Input.Password placeholder='Password' />
                    </AsnForm.Item>
                    <AsnForm.Item>
                      <MainButton text={'Save Password'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
                    </AsnForm.Item>
                  </AsnForm>
                </Row>
              </Col>
              <LogoWrapper span={12} xs={24} sm={12} md={12} lg={12}>
                <LogoSvg style={{ width: '100%' }} />
              </LogoWrapper>
            </Row>
          ) : (
            <ExpiredLink
              text={` Your password reset link is expired. Click on Resend to receive a new link.`}
              buttonText='Resend'
              email={email}
              isValidToken={isValidToken}
              reset={true}
            />
          )}
        </>
      ) : (
        <ResetPasswordMobaile />
      )}
    </>
  );
};

export default ResetPassword;
