import React, { useEffect } from 'react';
import { Row, Col, Input, DatePicker } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';

import AsnForm from '../Form';
import { ILicensesForm } from '../../types/myInfo';
import { validateForm, normalize } from '../../helpers';

const LicensesForm: React.FC<ILicensesForm> = ({ title, organization, startDate, endDate, credentialId }) => {
  const formUpdate = AsnForm.useFormInstance();
  const wibdowWidth = window.innerWidth;

  const disabledDateStart: RangePickerProps['disabledDate'] = (current) => {
    const endDateValue = formUpdate.getFieldValue('endDate') as Dayjs;
    return current && ((endDateValue ? current > dayjs(endDateValue) : false) || current > dayjs().endOf('day'));
  };

  const disabledDateEnd: RangePickerProps['disabledDate'] = (current) => {
    const startDateValue = formUpdate.getFieldValue('startDate') as Dayjs;
    return current && (startDateValue ? current < dayjs(startDateValue) : false);
  };

  useEffect(() => {
    formUpdate.resetFields();
  }, []);

  return (
    <>
      <Row gutter={[32, 0]}>
        <Col span={wibdowWidth < 768 ? 24 : 12}>
          <AsnForm.Item
            name='title'
            label='Name'
            rules={[
              { required: true },
              {
                validator: (_, value: string) => validateForm(_, value, 'Name', 3, 30),
              },
            ]}
            normalize={(value: string): string => normalize(value)}
            initialValue={title}
          >
            <Input autoComplete='off' />
          </AsnForm.Item>
        </Col>
        <Col span={wibdowWidth < 768 ? 24 : 12}>
          <AsnForm.Item
            name='organization'
            label='Issuing organization'
            rules={[
              { required: true },
              {
                validator: (_, value: string) => validateForm(_, value, 'Issuing organization', 3, 30),
              },
            ]}
            normalize={(value: string): string => normalize(value)}
            initialValue={organization}
          >
            <Input autoComplete='off' />
          </AsnForm.Item>
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col span={wibdowWidth < 768 ? 24 : 12}>
          <AsnForm.Item
            name='startDate'
            label='Issue date'
            rules={[{ required: true }]}
            initialValue={startDate ? dayjs(startDate) : undefined}
          >
            <DatePicker
              picker='month'
              style={{ width: '100%' }}
              suffixIcon={<DownOutlined />}
              placeholder=''
              disabledDate={disabledDateStart}
              defaultValue={startDate ? dayjs(startDate) : undefined}
            />
          </AsnForm.Item>
        </Col>
        <Col span={wibdowWidth < 768 ? 24 : 12}>
          <AsnForm.Item
            name='endDate'
            label='Expiration date'
            rules={[{ required: true }]}
            initialValue={endDate ? dayjs(endDate) : undefined}
          >
            <DatePicker
              picker='month'
              style={{ width: '100%' }}
              suffixIcon={<DownOutlined />}
              placeholder=''
              disabledDate={disabledDateEnd}
              defaultValue={endDate ? dayjs(endDate) : undefined}
            />
          </AsnForm.Item>
        </Col>
      </Row>
      <AsnForm.Item
        name='credentialId'
        label='Credential ID'
        rules={[
          {
            validator: (_, value: string) => validateForm(_, value, 'Credential ID', 0, 30),
          },
        ]}
        normalize={(value: string): string => normalize(value)}
        initialValue={credentialId}
      >
        <Input autoComplete='off' />
      </AsnForm.Item>
    </>
  );
};

export default LicensesForm;
