import React, { useState, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Row, Col, CollapseProps, Typography, Progress, Spin, message } from 'antd';
import dayjs from 'dayjs';

import { AsnButton, AsnCollapse, AsnForm, Points, UserInfoCard, ExperienceForm, DeleteModal } from '../../components';
import { useAddExperience, useDeleteExperience, useUpdateExperience } from '../../api';
import { COLORS, validate_messages } from '../../constants';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrowUp.svg';
import { ReactComponent as ExperienceSvg } from '../../assets/icons/experience.svg';
import { ReactComponent as AddSvg } from '../../assets/icons/add.svg';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { IExperience, IExperienceVariables, TOnUpdateExperience } from '../../types/myInfo';
import { MainButton } from '../../components/Button';

const { INDIGO, LAVENDER, GRAY, WHITE } = COLORS;

const { Text } = Typography;

const Experience: React.FC<IExperience> = ({ userId, experiences, points, isFetchingUser, setField }) => {
  const [edit, setEdit] = useState<string | boolean>(false);
  const [create, setCreate] = useState<boolean>(!experiences?.length);
  const [openDeleteModalById, setOpenDeleteModalById] = useState<string | boolean>(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();
  const wibdowWidth = window.innerWidth;
  const [formCreate] = AsnForm.useForm();
  const [formUpdate] = AsnForm.useForm();

  const { mutate: addExperience } = useAddExperience();
  const { mutate: deleteExperience } = useDeleteExperience();
  const { mutate: updateExperience } = useUpdateExperience();

  const onFinish = (values: IExperienceVariables): void => {
    const requestData = {
      ...values,
      position: values?.position?.trimEnd(),
      companyName: values?.companyName?.trimEnd(),
      description: values?.description?.trimEnd(),
    };
    addExperience({ id: userId, experience: requestData });
    formCreate.resetFields();
    setCreate(false);
    setField('experience');
  };

  const handleClickAdd = (): void => {
    setEdit(false);
    setCreate(true);
    const newContentElement = scrollRef?.current?.lastElementChild;
    newContentElement?.scrollIntoView({ behavior: 'smooth' });
    formCreate.resetFields();
  };

  const handleCancelCreate = (): void => {
    formCreate.resetFields();
    setCreate(false);
  };
  const handleCancelUpdate = (): void => {
    formUpdate.resetFields();
    setEdit(false);
    setCreate(false);
  };

  const handleEdit = (experienceId: string): void => {
    setEdit(experienceId);
  };

  const handleDelete = (): void => {
    void deleteExperience(
      { userId, infoId: openDeleteModalById as string },
      {
        onSuccess: () => {
          if (experiences?.length === 1) {
            setCreate(true);
          }
          setEdit(false);
          setField('experience');
          setOpenDeleteModalById(false);
          void queryClient.invalidateQueries(['/api/user/:id']);
        },
        onError: (error) => {
          void message.error(error as unknown as string, 2);
        },
      },
    );
  };

  const onUpdateExperience: TOnUpdateExperience = (position, companyName, startDate, endDate, description, educationId): void => {
    const values = formUpdate.getFieldsValue(true, (meta) => meta.touched === true) as IExperienceVariables;

    const companyNameField = values?.companyName === '' ? null : values?.companyName?.trimEnd();
    const descriptionField = values?.description === '' ? null : values?.description?.trimEnd();

    const keys = Object.keys(values);
    const requsetData = {
      ...(keys?.includes('position') && position !== values?.position?.trimEnd()
        ? { position: values?.position?.trimEnd() }
        : {}),
      ...(keys?.includes('companyName') && companyName !== companyNameField ? { companyName: companyNameField } : {}),
      ...(keys?.includes('description') && description !== descriptionField ? { description: descriptionField } : {}),
      ...(keys?.includes('startDate') && dayjs(startDate).format('YYYY-MM') !== dayjs(values?.startDate).format('YYYY-MM')
        ? { startDate: values?.startDate }
        : {}),
      ...(keys?.includes('endDate') && dayjs(endDate).format('YYYY-MM') !== dayjs(values?.endDate).format('YYYY-MM')
        ? { endDate: values?.endDate ?? null }
        : {}),
    };

    if (Object.keys(requsetData).length) {
      updateExperience({
        id: userId,
        experience: { ...(requsetData as IExperienceVariables), id: educationId },
      });
      setField('experience');
    }
    setEdit(false);
  };
  const handleCollapseChange = (key: string[] | string): void => {
    if (key?.length) {
      !experiences?.length ? setCreate(true) : setCreate(false);
      setEdit(false);
      formCreate.resetFields();
    }
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <Row align={'middle'} style={{ gap: 4 }}>
          <Points
            style={{
              height: wibdowWidth < 768 ? 32 : 54,
              width: wibdowWidth < 768 ? 32 : 54,
              fontSize: wibdowWidth < 768 ? 11 : 16,
            }}
          >
            {points ?? 0}/12
          </Points>
          <ExperienceSvg />
          <Text style={{ fontSize: wibdowWidth < 768 ? 14 : 20, color: `${INDIGO}` }}>Experience</Text>
        </Row>
      ),
      children: isFetchingUser ? (
        <Row justify={'center'}>
          <Spin />
        </Row>
      ) : (
        <>
          <Row align={'middle'} justify={'end'}>
            <AddSvg style={{ cursor: 'pointer' }} onClick={handleClickAdd} />
          </Row>
          <Progress
            style={{ marginBottom: 0 }}
            percent={20}
            strokeColor={LAVENDER}
            showInfo={false}
            size={['100%', 1]}
            trailColor={GRAY}
          />
          {!!experiences?.length &&
            experiences?.map((experience) => (
              <UserInfoCard
                key={experience?.id}
                edit={edit as string}
                id={experience?.id as string}
                title={experience?.companyName}
                startDate={experience?.startDate}
                endDate={experience?.endDate}
                setId={setOpenDeleteModalById}
                handleEdit={(): void => handleEdit(experience?.id as string)}
              >
                {edit === experience?.id && (
                  <AsnForm
                    form={formUpdate}
                    layout='vertical'
                    onFinish={(): void =>
                      onUpdateExperience(
                        experience?.position,
                        experience?.companyName,
                        experience?.startDate,
                        experience?.endDate,
                        experience?.description,
                        experience?.id as string,
                      )
                    }
                    validateMessages={validate_messages}
                  >
                    <ExperienceForm
                      position={experience?.position}
                      companyName={experience?.companyName}
                      description={experience?.description}
                      startDate={experience?.startDate}
                      endDate={experience?.endDate}
                    />
                    {wibdowWidth < 768 ? (
                      <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                        <Col span={24}>
                          <AsnForm.Item style={{ marginBottom: 0 }}>
                            <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
                          </AsnForm.Item>
                        </Col>
                        <Col span={24}>
                          <AsnButton onClick={(): void => handleCancelUpdate()} style={{ width: '100%' }}>
                            Cancel
                          </AsnButton>
                        </Col>
                      </Row>
                    ) : (
                      <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                        <AsnButton onClick={(): void => handleCancelUpdate()}>Cancel</AsnButton>
                        <AsnForm.Item style={{ marginBottom: 0 }}>
                          <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' />
                        </AsnForm.Item>
                      </Row>
                    )}
                  </AsnForm>
                )}
              </UserInfoCard>
            ))}
          {!edit && create && (
            <AsnForm
              form={formCreate}
              layout='vertical'
              validateMessages={validate_messages}
              onFinish={(values): void => onFinish(values as IExperienceVariables)}
            >
              <ExperienceForm />
              {wibdowWidth < 768 ? (
                <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                  <Col span={24}>
                    <AsnForm.Item style={{ marginBottom: 0 }}>
                      <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
                    </AsnForm.Item>
                  </Col>
                  <Col span={24}>
                    <AsnButton onClick={(): void => handleCancelCreate()} style={{ width: '100%' }}>
                      Cancel
                    </AsnButton>
                  </Col>
                </Row>
              ) : (
                <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                  <AsnButton onClick={(): void => handleCancelCreate()}>Cancel</AsnButton>
                  <AsnForm.Item style={{ marginBottom: 0 }}>
                    <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' />
                  </AsnForm.Item>
                </Row>
              )}
            </AsnForm>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Row style={{ background: `${WHITE}` }} ref={scrollRef}>
        <Col span={24} xxl={{ span: 21, push: 3 }}>
          <AsnCollapse
            items={items}
            bordered={false}
            expandIconPosition={'end'}
            expandIcon={({ isActive }): React.ReactNode => (isActive ? <ArrowUp /> : <ArrowDown />)}
            onChange={handleCollapseChange}
            defaultActiveKey={experiences?.length ? ['1'] : []}
          />
        </Col>
      </Row>
      {!!openDeleteModalById && (
        <DeleteModal
          openDeleteModal={!!openDeleteModalById}
          setOpenDeleteModal={setOpenDeleteModalById}
          title={'Are you sure to delete this experience?'}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};
export default Experience;
