import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

import { PATHS } from '../constants';
import { useAuth } from '../hooks/useAuth';

export const PublicRoutes: React.FC = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();

  if (user && pathname === '/policy') {
    return <Navigate to={PATHS.PRIVACY_POLICY_LOGGED} />;
  }

  if (user) {
    return <Navigate to={`${PATHS.MY_INFO}?menu=My info`} />;
  }

  return <Outlet />;
};
