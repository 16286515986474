import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { message } from 'antd';

import client from '../../client';
import { ISuccesMessage } from '../../../types/global';
import { TUseUpdateUser, TUpdateUserVariables } from '../../../types/api/myInfo';

const url = '/api/user/:id';

const useUpdateUser: TUseUpdateUser = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, TUpdateUserVariables, unknown>({
    mutationFn: (values) => {
      return client.put(url.replace(':id', values.id), values.user);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(['/api/user/:id']);
    },
    onError: (error) => {
      void message.error(error as unknown as string, 2);
    },
  });

  return mutation;
};

export default useUpdateUser;
