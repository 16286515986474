import { createBrowserRouter } from 'react-router-dom';

import { PATHS } from '../constants';
import Landing from '../pages/Landing';
import SignIn from '../pages/Auth/SignIn';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import ResetPassword from '../pages/Auth/ResetPassword';
import SignUp from '../pages/Auth/SignUp';
import MyInfo from '../pages/MyInfo';
import VerifyEmail from '../pages/Auth/VerifyEmail';
import ChangePassword from '../pages/Auth/ChangePassword';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Instruction from '../pages/Instruction';
import Chat from '../pages/Chat';
import CareerPaths from '../pages/CareerPaths';
import Feedback from '../pages/Feedback';
import LoggedUserLandingPage from '../pages/LoggedUserLandingPage';

import { PublicRoutes } from './PublicRoutes';
import { PrivateRoutes } from './PrivateRoutes';

export const routers = createBrowserRouter([
  {
    element: <PublicRoutes />,
    children: [
      {
        path: PATHS.LANDING,
        element: <Landing />,
      },
      {
        path: PATHS.SIGNIN,
        element: <SignIn />,
      },
      {
        path: PATHS.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: PATHS.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: PATHS.SIGNUP,
        element: <SignUp />,
      },
      {
        path: PATHS.VERIFY,
        element: <VerifyEmail />,
      },
      {
        path: PATHS.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
      },
    ],
  },
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: PATHS.MY_INFO,
        element: <MyInfo />,
      },
      {
        path: PATHS.CHANGE_PASSWORD,
        element: <ChangePassword />,
      },
      {
        path: PATHS.INSTRUCTION,
        element: <Instruction />,
      },
      {
        path: PATHS.CHAT,
        element: <Chat />,
      },
      {
        path: PATHS.CAREER_PATHS,
        element: <CareerPaths />,
      },
      {
        path: PATHS.FEEDBACK,
        element: <Feedback />,
      },
      {
        path: PATHS.LOGGED_USER_LANDING,
        element: <LoggedUserLandingPage />,
      },
      {
        path: PATHS.PRIVACY_POLICY_LOGGED,
        element: <PrivacyPolicy />,
      },
    ],
  },
]);
