import { Col } from 'antd';
import styled from 'styled-components';

export const ColDatePicker = styled(Col)`
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel table {
    tr {
      display: grid !important;
    }
  }
  .ant-picker-body {
    height: 200px !important;
    overflow: auto;
  }
`;
