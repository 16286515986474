import { Result } from 'antd';
import styled from 'styled-components';

const AsnResult = styled(Result)`
  &.ant-result {
    padding: 32px;
    .ant-result-icon {
      margin-bottom: 0;
    }
  }
  svg {
    height: 40px;
    width: 40px;
  }
`;

export default AsnResult;
