import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Space, Typography } from 'antd';

import { useVerifyEmail, useResendEmail, useForgotPassword } from '../../api';
import { PATHS } from '../../constants';
import { IExpiredLink } from '../../types/components';
import { MainButton } from '../Button';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';

const { Paragraph } = Typography;

const ExpiredLink: React.FC<IExpiredLink> = ({ text, buttonText, isValidToken, email, reset }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') as string;

  const navigate = useNavigate();

  const { mutate: verifyEmail } = useVerifyEmail();
  const { mutate: resendEmail } = useResendEmail();
  const { mutate: forgotPassword } = useForgotPassword();

  const handleAction = (): void => {
    if (isValidToken) {
      verifyEmail(
        { token },
        {
          onSuccess: () => {
            navigate(PATHS.SIGNIN);
          },
        },
      );
    } else if (reset && !isValidToken) {
      forgotPassword({ email: email as string });
    } else {
      resendEmail({ email: email as string });
    }
  };

  return (
    <Space direction='vertical' align='center' style={{ width: '100%', height: '100%', justifyContent: 'center' }} size={60}>
      <Paragraph style={{ fontSize: 16, maxWidth: 460 }}>{text}</Paragraph>
      <MainButton
        text={buttonText}
        icon={<DotsSvg />}
        htmlType='submit'
        style={{ maxWidth: 460, width: '41vw' }}
        onClick={handleAction}
      />
    </Space>
  );
};

export default ExpiredLink;
