import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../../client';
import { ISuccesMessage } from '../../../types/global';
import { TUseDeleteById, TUseDeleteInfo } from '../../../types/api/myInfo';

const url = '/api/user/:id/experience/:experienceId';

const useDeleteExperience: TUseDeleteInfo = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, TUseDeleteById, unknown>({
    mutationFn: (values) => {
      return client.delete(url.replace(':id', values.userId).replace(':experienceId', values.infoId));
    },
  });

  return mutation;
};

export default useDeleteExperience;
