import { Radio } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';

const { LAVENDER, WHITE, BLACK } = COLORS;

const AsnRadio = styled(Radio)`
  &.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: ${WHITE};
    border-color: ${LAVENDER};
  }
  .ant-radio-inner::after {
    background-color: ${LAVENDER};
  }
  &.ant-radio-wrapper:hover .ant-radio-inner {
    border-color: ${LAVENDER};
  }
  &.ant-radio-wrapper-disabled {
    color: ${BLACK};
    .ant-radio-inner {
      background-color: ${WHITE};
    }
  }
  &.ant-radio-wrapper .ant-radio-disabled .ant-radio-inner::after {
    background-color: ${LAVENDER};
  }
`;

export default AsnRadio;
