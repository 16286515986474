import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../client';
import { TSubmitAnswers, TSubmitAnswersVariables } from '../../types/api/chat';

const url = '/api/questions/user-responses/:userId';

const useSubmitAnswers: TSubmitAnswers = () => {
  const mutation = useMutation<AxiosResponse<unknown>, AxiosError, TSubmitAnswersVariables, unknown>({
    mutationFn: (values) => {
      return client.post(url.replace(':userId', values.userId), values.answers);
    },
  });
  return mutation;
};

export default useSubmitAnswers;
