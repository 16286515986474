import styled from 'styled-components';
import { Card } from 'antd';

import { COLORS } from '../../constants';

const { LAVENDER, LIGHT_VIOLET, WHITE, GRAY_CARD } = COLORS;

const ProfessionCard = styled(Card)`
  &.ant-card {
    cursor: pointer;
    border: 1px solid ${LAVENDER};
    height: ${(props): string => (props.content === 'career' ? '100px' : 'auto')};
    padding: 8px;
    &:before {
      content: '';
      position: absolute;
      width: 25%;
      height: 50%;
      bottom: -3px;
      left: -2px;
      border-bottom: 4px solid ${LAVENDER}; /* Replace with your desired border color */
      border-left: 4px solid ${LAVENDER}; /* Replace with your desired border color */
      border-bottom-left-radius: 8px;
    }
    &:after {
      content: '';
      position: absolute;
      width: 25%;
      height: 50%;
      top: -2px;
      right: -2px;
      border-top: 4px solid ${LAVENDER}; /* Replace with your desired border color */
      border-right: 4px solid ${LAVENDER}; /* Replace with your desired border color */
      border-top-right-radius: 8px;
    }
    .ant-card-body {
      padding: 8px;
      height: 100%;
      background: ${(props): string => (props.content === 'career' ? `${GRAY_CARD}` : '')};
      display: ${(props): string => (props.content === 'career' ? 'flex' : '')};
      align-items: ${(props): string => (props.content === 'career' ? 'center' : '')};
      justify-content: ${(props): string => (props.content === 'career' ? 'center' : '')};
      &:hover {
        background-color: ${(props): string => (props.content === 'career' ? `${GRAY_CARD}` : `${LIGHT_VIOLET}`)};
      }
    }
  }
  &.selected {
    &.ant-card {
      height: ${(props): string => (props.content === 'career' ? '100px' : '100%')};
    }
    .ant-card-body {
      background-color: ${LAVENDER};
      &:hover {
        background-color: ${LAVENDER};
      }
      > div {
        color: ${WHITE};
      }
    }
  }
`;

export default ProfessionCard;
