import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, Typography, Row, Col, Flex } from 'antd';
import { AlignLeftOutlined, CloseOutlined } from '@ant-design/icons';

import { COLORS, PATHS, menuItems } from '../../../constants';
import { ReactComponent as Logo } from '../../../assets/icons/proflab.svg';
// import { ReactComponent as Fb } from '../../../assets/icons/fb.svg';
// import { ReactComponent as Twitter } from '../../../assets/icons/twitter.svg';
// import { ReactComponent as YouTube } from '../../../assets/icons/youtube.svg';
import { ReactComponent as LogoSvg } from '../../../assets/icons/logoFooter.svg';
import { ReactComponent as DotsSvg } from '../../../assets/icons/button.svg';
import AsnButton from '../../Button';
import { IMainLayout } from '../../../types/global';
import AsnMenu from '../../Menu';
import { useAuth } from '../../../hooks/useAuth';

const { WHITE, AMETHYST, LAVENDER_20, PEACOAT, GREY_200 } = COLORS;

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;

const MainLayoutMobaile: React.FC<IMainLayout> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const toggleCollapsed = (): void => {
    setCollapsed(!collapsed);
  };

  const navigatePolicy = (): void => {
    if (user) {
      window.open(PATHS.PRIVACY_POLICY_LOGGED, '_blank');
    } else {
      window.open(PATHS.PRIVACY_POLICY, '_blank');
    }
  };

  return (
    <Layout style={{ backgroundImage: 'url(./assets/landingBg.png)', backgroundSize: 'cover' }}>
      {pathname === '/home' ? (
        <></>
      ) : (
        <Header
          style={{ paddingInline: '16px', backgroundColor: 'transparent', borderBottom: `1px solid ${WHITE}`, marginBottom: 16 }}
        >
          <Row justify={'space-between'} gutter={[5, 5]}>
            <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
              <Logo style={{ marginRight: '10%' }} />
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <AsnButton
                className='secondary'
                style={{ color: `${AMETHYST}`, marginRight: '16px' }}
                onClick={(): void => navigate(PATHS.SIGNIN)}
              >
                Log in
              </AsnButton>

              <AsnButton type='primary' className='main' onClick={(): void => navigate(PATHS.SIGNUP)}>
                <Flex align='center' gap={8}>
                  Sign up <DotsSvg />
                </Flex>
              </AsnButton>
            </Col>
            <Col>
              <AsnButton
                type='primary'
                onClick={toggleCollapsed}
                style={{ marginBottom: 16, background: 'inherit', boxShadow: 'none', color: '#444444' }}
              >
                {collapsed ? <CloseOutlined /> : <AlignLeftOutlined />}
              </AsnButton>
              {collapsed ? (
                <AsnMenu
                  inlineCollapsed={!collapsed}
                  className='landing'
                  items={menuItems}
                  expandIcon={false}
                  style={{ width: 130, position: 'absolute', right: 0, zIndex: 1, background: `${WHITE}`, color: `${AMETHYST}` }}
                  mode='inline'
                  theme='dark'
                />
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Header>
      )}

      <Content>{children}</Content>
      <Footer id='contacts' style={{ backgroundColor: `${PEACOAT}`, padding: '24px 16px' }}>
        <div style={{ marginBottom: 16 }}>
          <LogoSvg />
        </div>
        <Flex justify='space-between' vertical>
          <Flex vertical style={{ width: '100%' }} gap={16}>
            <Title style={{ color: `${LAVENDER_20}`, fontSize: 18, marginBottom: 0 }}>About</Title>
            <Paragraph style={{ color: `${WHITE}`, marginBottom: 0 }}>
              Find your new professional path with the help of our innovative career development tool
            </Paragraph>
            <Title style={{ color: `${LAVENDER_20}`, fontSize: 18, cursor: 'pointer', margin: 0 }} onClick={navigatePolicy}>
              Privacy policy
            </Title>
            <Paragraph style={{ color: `${GREY_200}` }}>© ProfLab 2024</Paragraph>
          </Flex>
          <Flex vertical>
            <Title style={{ color: `${LAVENDER_20}`, fontSize: 18 }}>Email</Title>
            <Paragraph style={{ color: `${WHITE}` }}>info@proflab.ai</Paragraph>
          </Flex>
          {/* <Flex vertical>
            <Title style={{ color: `${LAVENDER_20}`, fontSize: 18 }}>Phone number:</Title>
            <Paragraph style={{ color: `${WHITE}` }}> +37493635500</Paragraph>
          </Flex> */}
          <Flex vertical>
            <Title style={{ color: `${LAVENDER_20}`, fontSize: 18 }}>Address:</Title>
            <Paragraph style={{ color: `${WHITE}` }}>45 M. Mashtots Ave. 5, 0009 Yerevan, Armenia</Paragraph>
          </Flex>
          {/* <Flex>
            <Fb />
            <Twitter />
            <YouTube />
          </Flex> */}
          <Title style={{ color: `${LAVENDER_20}`, fontSize: 18, cursor: 'pointer', margin: 0 }} onClick={navigatePolicy}>
            Privacy policy
          </Title>
        </Flex>
      </Footer>
    </Layout>
  );
};

export default MainLayoutMobaile;
