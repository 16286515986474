import { useQuery } from '@tanstack/react-query';

import client from '../../client';
import { TResponseUser, TUseGetUser } from '../../../types/api/myInfo';

const url = '/api/user/:id';

const useGetUserById: TUseGetUser = (id, options) => {
  const result = useQuery({
    queryKey: [url],
    queryFn: () => client.get(url.replace(':id', id)),
    ...options,
    select: (data) => data?.data,
  });

  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ({} as TResponseUser),
    isLoading,
    isFetching,
  };
};

export default useGetUserById;
