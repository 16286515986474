import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Spin, Row } from 'antd';

import { ExpiredLink } from '../../components';
import { useCheckToken } from '../../api';

const VerifyEmail: React.FC = () => {
  const [isValidToken, setIsValidToken] = useState<boolean | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') as string;

  const { mutate: chechkToken } = useCheckToken();

  useEffect(() => {
    if (token) {
      chechkToken(
        { token },
        {
          onSuccess: (data) => {
            setIsValidToken(data?.data?.valid);
            if (!data?.data?.valid) {
              setEmail(data?.data?.email as string);
            }
          },
        },
      );
    }
  }, [token]);

  if (isValidToken === undefined) {
    return (
      <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
        <Spin />
      </Row>
    );
  }

  return (
    <ExpiredLink
      text={
        isValidToken
          ? 'Please verify your email address one more time.'
          : `Your confirmation link is expired. Click on Resend to receive a new confirmation email`
      }
      buttonText={isValidToken ? 'Verify' : 'Resend'}
      isValidToken={isValidToken}
      email={email}
    />
  );
};

export default VerifyEmail;
