import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Typography } from 'antd';

import { COLORS } from '../../constants';
import AsnResult from '../Result';
import { TInfoModal } from '../../types/components';

const { Paragraph } = Typography;
const { INDIGO } = COLORS;

const InfoModal: React.FC<TInfoModal> = ({ setOpenInfoModal, openInfoModal, email, setShowResendButton, create }) => {
  const navigate = useNavigate();

  const onCancel = (): void => {
    setOpenInfoModal(false);
    if (create) {
      setShowResendButton(true);
    } else {
      setShowResendButton(false);
    }
  };
  const wibdowWidth = window.innerWidth;
  return (
    <Modal
      centered
      open={openInfoModal}
      footer={false}
      width={wibdowWidth < 768 ? '90vw' : '50vw'}
      bodyStyle={{ padding: '10%' }}
      onCancel={onCancel}
    >
      {create && <AsnResult status={'success'} />}
      <Paragraph style={{ color: `${INDIGO}`, fontSize: 16 }}>
        {create ? (
          <span>
            A confirmation email is sent to <u>{email}</u>. If you don&apos;t see the email, please ensure to check your junk or
            promotional folders.
          </span>
        ) : (
          <span>
            Kindly review your email address once more, ensuring to thoroughly check your inbox, promotions, and junk folder. If
            your email address is correct and you did&apos;t receive the confirmation email{' '}
            <u style={{ cursor: 'pointer' }} onClick={(): void => navigate('/#footer')}>
              Contact us
            </u>
          </span>
        )}
      </Paragraph>
    </Modal>
  );
};

export default InfoModal;
