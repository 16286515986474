import React from 'react';
import { Row, Input, Typography } from 'antd';
import { Rule, RuleObject } from 'antd/es/form';

import { AsnForm } from '../../components';
import { validate_messages, passwordRegExp, COLORS } from '../../constants';
import { useChangePassword } from '../../api';
import { ChangePasswordVariables } from '../../types/api/auth';
import { MainButton } from '../../components/Button';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';

const { Title, Paragraph } = Typography;
const { LAVENDER } = COLORS;

const ChangePassword: React.FC = () => {
  const [form] = AsnForm.useForm();
  const { mutate: changePassword } = useChangePassword();
  const wibdowWidth = window.innerWidth;

  const onFinish = (values: ChangePasswordVariables): void => {
    changePassword(values);
  };

  const rulesConfirmPassword: Rule[] = [
    {
      required: true,
    },
    {
      pattern: passwordRegExp,
    },
    ({ getFieldValue }): RuleObject => ({
      async validator(_, value): Promise<void> {
        if (getFieldValue('newPassword') === value) {
          return await Promise.resolve();
        }
        return await Promise.reject(new Error('The two passwords that you entered do not match!'));
      },
    }),
  ];

  return (
    <Row style={{ width: '100%', height: '100%' }} align={'middle'} justify={'center'}>
      <AsnForm
        layout='vertical'
        form={form}
        validateMessages={validate_messages}
        style={{ maxWidth: '460px', width: wibdowWidth < 768 ? '98vw' : '35vw' }}
        onFinish={(values): void => onFinish(values as ChangePasswordVariables)}
      >
        <Title level={3} style={{ textAlign: 'center', color: `${LAVENDER}`, marginBottom: '32px' }}>
          Change Password
        </Title>
        <Paragraph style={{ marginBottom: '32px', fontSize: 16 }}>
          At least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number
        </Paragraph>
        <AsnForm.Item name='oldPassword' label='Old Password' rules={[{ required: true }, { pattern: passwordRegExp }]}>
          <Input.Password placeholder='Password' />
        </AsnForm.Item>
        <AsnForm.Item name='newPassword' label='New Password' rules={[{ required: true }, { pattern: passwordRegExp }]}>
          <Input.Password placeholder='Password' />
        </AsnForm.Item>
        <AsnForm.Item name='repeatPassword' label='Confirm Password' dependencies={['newPassword']} rules={rulesConfirmPassword}>
          <Input.Password placeholder='Password' />
        </AsnForm.Item>
        <AsnForm.Item>
          <MainButton text={'Save Password'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
        </AsnForm.Item>
      </AsnForm>
    </Row>
  );
};

export default ChangePassword;
