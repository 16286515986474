import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { message } from 'antd';

import client from '../../client';
import { ISuccesMessage } from '../../../types/global';
import { TTraining, TTrainingsVariables } from '../../../types/api/myInfo';

const url = '/api/user/:id/training';

const useAddTraining: TTraining = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, TTrainingsVariables, unknown>({
    mutationFn: (values) => {
      return client.post(url.replace(':id', values.id), [values.training]);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(['/api/user/:id']);
    },
    onError: (error) => {
      void message.error(error as unknown as string, 2);
    },
  });

  return mutation;
};

export default useAddTraining;
