import React, { useState, useEffect } from 'react';
import { Row, Col, Select } from 'antd';

import { useGetLanguages } from '../../api';
import AsnForm from '../Form';
import { proficiencyOptions, selectProps } from '../../constants';
import { ILanguagesForm } from '../../types/myInfo';

const LanguagesForm: React.FC<ILanguagesForm> = ({ languageField, proficiency }) => {
  const [language, setLanguage] = useState<string>('');

  const formUpdate = AsnForm.useFormInstance();
  const wibdowWidth = window.innerWidth;

  const { data: languages, isFetching: isFetchingLanguages } = useGetLanguages(language, {
    enabled: Boolean(language),
    keepPreviousData: language ? true : false,
    staleTime: language ? 60 * 1000 : 0,
  });

  const onSearchLanguages = (value: string): void => {
    if (languages?.length === 0 && language && value?.trim()?.replace(/\s+/g, ' ') > language) return;
    if (isFetchingLanguages === false) {
      setLanguage(value?.trim()?.replace(/\s+/g, ' '));
    }
  };
  useEffect(() => {
    formUpdate.resetFields();
  }, []);

  return (
    <>
      <Row gutter={[32, 0]}>
        <Col span={wibdowWidth < 768 ? 24 : 12}>
          <AsnForm.Item name='languageId' label='Language' rules={[{ required: true }]} initialValue={languageField?.title}>
            <Select
              options={languages?.map((item) => {
                return {
                  label: item?.title,
                  value: item?.id,
                };
              })}
              onSearch={onSearchLanguages}
              loading={isFetchingLanguages}
              onClear={(): void => setLanguage('')}
              defaultValue={{
                label: languageField?.title,
                value: languageField?.id,
              }}
              {...selectProps}
            />
          </AsnForm.Item>
        </Col>
        <Col span={wibdowWidth < 768 ? 24 : 12}>
          <AsnForm.Item name='proficiencyId' label='Proficiency' initialValue={proficiency?.title}>
            <Select
              options={proficiencyOptions}
              allowClear
              defaultValue={{ label: proficiency?.title, value: proficiency?.id }}
            />
          </AsnForm.Item>
        </Col>
      </Row>
    </>
  );
};

export default LanguagesForm;
