import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Row, Col, CollapseProps, Typography, Progress, Spin, message } from 'antd';
import dayjs from 'dayjs';

import { AsnButton, AsnCollapse, AsnForm, Points, UserInfoCard, LicensesForm, DeleteModal } from '../../components';
import { COLORS, validate_messages } from '../../constants';
import { useAddLicense, useUpdateLicense, useDeleteLicense } from '../../api';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrowUp.svg';
import { ReactComponent as LicensesSvg } from '../../assets/icons/licenses.svg';
import { ReactComponent as AddSvg } from '../../assets/icons/add.svg';
import { ILicenseVariables, ILicenses, TOnUpdateLicense } from '../../types/myInfo';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { MainButton } from '../../components/Button';

const { INDIGO, LAVENDER, GRAY, WHITE } = COLORS;

const { Text } = Typography;

const Licenses: React.FC<ILicenses> = ({ userId, licenses, points, isFetchingUser, setField }) => {
  const [edit, setEdit] = useState<string | boolean>(false);
  const [create, setCreate] = useState<boolean>(!licenses?.length);
  const [openDeleteModalById, setOpenDeleteModalById] = useState<string | boolean>(false);

  const queryClient = useQueryClient();
  const wibdowWidth = window.innerWidth;
  const [formCreate] = AsnForm.useForm();
  const [formUpdate] = AsnForm.useForm();

  const { mutate: addLicense } = useAddLicense();
  const { mutate: deleteLicense } = useDeleteLicense();
  const { mutate: updateLicense } = useUpdateLicense();

  const onFinish = (values: ILicenseVariables): void => {
    const requestData = {
      ...values,
      title: values?.title?.trimEnd(),
      organization: values?.organization?.trimEnd(),
      credentialId: values?.credentialId?.trimEnd(),
    };
    addLicense({ id: userId, license: requestData });
    setCreate(false);
    setField('licenses');
    formCreate.resetFields();
  };

  const handleClickAdd = (): void => {
    setEdit(false);
    setCreate(true);
    formCreate.resetFields();
  };

  const handleCancelCreate = (): void => {
    formCreate.resetFields();
    setCreate(false);
  };

  const handleCancelUpdate = (): void => {
    formUpdate.resetFields();
    setEdit(false);
    setCreate(false);
  };

  const handleEdit = (licenseId: string): void => {
    setEdit(licenseId);
  };

  const handleDelete = (): void => {
    void deleteLicense(
      { userId, infoId: openDeleteModalById as string },
      {
        onSuccess: () => {
          if (licenses?.length === 1) {
            setCreate(true);
          }
          setEdit(false);
          setField('licenses');
          setOpenDeleteModalById(false);
          void queryClient.invalidateQueries(['/api/user/:id']);
        },
        onError: (error) => {
          void message.error(error as unknown as string, 2);
        },
      },
    );
  };

  const onUpdateLicense: TOnUpdateLicense = (title, organization, startDate, endDate, credentialId, id): void => {
    const values = formUpdate.getFieldsValue(true, (meta) => meta.touched === true) as ILicenseVariables;

    const credentialIdField = values?.credentialId === '' ? null : values?.credentialId?.trimEnd();

    const keys = Object.keys(values);
    const requsetData = {
      ...(keys?.includes('title') && title !== values?.title?.trimEnd() ? { title: values?.title?.trimEnd() } : {}),
      ...(keys?.includes('organization') && organization !== values?.organization?.trimEnd()
        ? { organization: values?.organization?.trimEnd() }
        : {}),
      ...(keys?.includes('startDate') && dayjs(startDate).format('YYYY-MM') !== dayjs(values?.startDate).format('YYYY-MM')
        ? { startDate: values?.startDate }
        : {}),
      ...(keys?.includes('endDate') && dayjs(endDate).format('YYYY-MM') !== dayjs(values?.endDate).format('YYYY-MM')
        ? { endDate: values?.endDate }
        : {}),
      ...(keys?.includes('credentialId') && credentialId !== credentialIdField ? { credentialId: credentialIdField } : {}),
    };

    if (Object.keys(requsetData).length) {
      updateLicense({
        id: userId,
        license: { ...(requsetData as ILicenseVariables), id },
      });
      setField('licenses');
    }
    setEdit(false);
  };
  const handleCollapseChange = (key: string[] | string): void => {
    if (key?.length) {
      !licenses?.length ? setCreate(true) : setCreate(false);
      setEdit(false);
      formCreate.resetFields();
    }
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <Row align={'middle'} style={{ gap: 4 }}>
          {wibdowWidth < 768 ? (
            <>
              <Points style={{ height: 32, width: 32, fontSize: 11 }}>{points ?? 0}/12</Points>
              <LicensesSvg />
              <Text style={{ fontSize: 14, color: `${INDIGO}` }}>Licenses & Certifications</Text>{' '}
            </>
          ) : (
            <>
              {' '}
              <Points>{points ?? 0}/6</Points>
              <LicensesSvg />
              <Text style={{ fontSize: 20, color: `${INDIGO}` }}>Licenses & Certifications</Text>
            </>
          )}
        </Row>
      ),
      children: isFetchingUser ? (
        <Row justify={'center'}>
          <Spin />
        </Row>
      ) : (
        <>
          <Row align={'middle'} justify={'end'}>
            <AddSvg style={{ cursor: 'pointer' }} onClick={handleClickAdd} />
          </Row>
          <Progress
            style={{ marginBottom: 0 }}
            percent={20}
            strokeColor={LAVENDER}
            showInfo={false}
            size={['100%', 1]}
            trailColor={GRAY}
          />
          {!!licenses?.length &&
            licenses?.map((license) => (
              <UserInfoCard
                id={license?.id as string}
                key={license?.id}
                edit={edit as string}
                title={'Licenses or Certifications'}
                info={license?.title}
                setId={setOpenDeleteModalById}
                handleEdit={(): void => handleEdit(license?.id as string)}
              >
                {edit === license?.id && (
                  <AsnForm
                    form={formUpdate}
                    layout='vertical'
                    onFinish={(): void =>
                      onUpdateLicense(
                        license?.title,
                        license?.organization,
                        license?.startDate,
                        license?.endDate,
                        license?.credentialId,
                        license?.id as string,
                      )
                    }
                    validateMessages={validate_messages}
                  >
                    <LicensesForm
                      title={license?.title}
                      organization={license?.organization}
                      startDate={license?.startDate}
                      endDate={license?.endDate}
                      credentialId={license?.credentialId}
                    />

                    {wibdowWidth < 768 ? (
                      <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                        <Col span={24}>
                          <AsnForm.Item style={{ marginBottom: 0 }}>
                            <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
                          </AsnForm.Item>
                        </Col>
                        <Col span={24}>
                          <AsnButton onClick={(): void => handleCancelUpdate()} style={{ width: '100%' }}>
                            Cancel
                          </AsnButton>
                        </Col>
                      </Row>
                    ) : (
                      <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                        <AsnButton onClick={(): void => handleCancelUpdate()}>Cancel</AsnButton>
                        <AsnForm.Item style={{ marginBottom: 0 }}>
                          <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' />
                        </AsnForm.Item>
                      </Row>
                    )}
                  </AsnForm>
                )}
              </UserInfoCard>
            ))}
          {!edit && create && (
            <AsnForm
              form={formCreate}
              layout='vertical'
              validateMessages={validate_messages}
              onFinish={(values): void => onFinish(values as ILicenseVariables)}
            >
              <LicensesForm />
              {wibdowWidth < 768 ? (
                <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                  <Col span={24}>
                    <AsnForm.Item style={{ marginBottom: 0 }}>
                      <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
                    </AsnForm.Item>
                  </Col>
                  <Col span={24}>
                    <AsnButton onClick={(): void => handleCancelCreate()} style={{ width: '100%' }}>
                      Cancel
                    </AsnButton>
                  </Col>
                </Row>
              ) : (
                <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                  <AsnButton onClick={(): void => handleCancelCreate()}>Cancel</AsnButton>
                  <AsnForm.Item style={{ marginBottom: 0 }}>
                    <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' />
                  </AsnForm.Item>
                </Row>
              )}
            </AsnForm>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Row style={{ background: `${WHITE}` }}>
        <Col span={24} xxl={{ span: 21, push: 3 }}>
          <AsnCollapse
            items={items}
            bordered={false}
            expandIconPosition={'end'}
            expandIcon={({ isActive }): React.ReactNode => (isActive ? <ArrowUp /> : <ArrowDown />)}
            onChange={handleCollapseChange}
            defaultActiveKey={licenses?.length ? ['1'] : []}
          />
        </Col>
      </Row>
      {!!openDeleteModalById && (
        <DeleteModal
          openDeleteModal={!!openDeleteModalById}
          setOpenDeleteModal={setOpenDeleteModalById}
          title={'Are you sure to delete this License & certification?'}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};
export default Licenses;
