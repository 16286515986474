import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Row, Col, CollapseProps, Typography, Progress, Spin, message } from 'antd';

import { AsnButton, AsnCollapse, AsnForm, Points, UserInfoCard, LanguagesForm, DeleteModal } from '../../components';
import { COLORS, validate_messages } from '../../constants';
import { useAddLanguage, useUpdateLanguage, useDeleteLanguage } from '../../api';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrowUp.svg';
import { ReactComponent as LanguageSvg } from '../../assets/icons/language.svg';
import { ReactComponent as AddSvg } from '../../assets/icons/add.svg';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { ILanguageVariables, ILanguages, TOnUpdateLanguage } from '../../types/myInfo';
import { MainButton } from '../../components/Button';

const { INDIGO, LAVENDER, GRAY, WHITE } = COLORS;

const { Text } = Typography;

const Languages: React.FC<ILanguages> = ({ userId, languages, points, isFetchingUser, setField }) => {
  const [edit, setEdit] = useState<string | boolean>(false);
  const [create, setCreate] = useState<boolean>(!languages?.length);
  const [openDeleteModalById, setOpenDeleteModalById] = useState<string | boolean>(false);

  const queryClient = useQueryClient();
  const wibdowWidth = window.innerWidth;
  const [formCreate] = AsnForm.useForm();
  const [formUpdate] = AsnForm.useForm();

  const { mutate: addLanguage } = useAddLanguage();
  const { mutate: deleteLanguage } = useDeleteLanguage();
  const { mutate: updateLanguage } = useUpdateLanguage();

  const onFinish = (values: ILanguageVariables): void => {
    addLanguage({ id: userId, language: values });
    formCreate.resetFields();
    setCreate(false);
    setField('languages');
  };

  const handleClickAdd = (): void => {
    setEdit(false);
    setCreate(true);
    formCreate.resetFields();
  };

  const handleCancelCreate = (): void => {
    formCreate.resetFields();
    setCreate(false);
  };

  const handleCancelUpdate = (): void => {
    formUpdate.resetFields();
    setEdit(false);
    setCreate(false);
  };

  const handleEdit = (languageId: string): void => {
    setEdit(languageId);
  };

  const handleDelete = (): void => {
    void deleteLanguage(
      { userId, infoId: openDeleteModalById as string },
      {
        onSuccess: () => {
          if (languages?.length === 1) {
            setCreate(true);
          }
          setEdit(false);
          setField('languages');
          setOpenDeleteModalById(false);
          void queryClient.invalidateQueries(['/api/user/:id']);
        },
        onError: (error) => {
          void message.error(error as unknown as string, 2);
        },
      },
    );
  };

  const onUpdateLanguage: TOnUpdateLanguage = (languageId, proficiencyId, id): void => {
    const values = formUpdate.getFieldsValue(true, (meta) => meta.touched === true) as ILanguageVariables;

    const proficiencyIdField = values?.proficiencyId === undefined ? null : values?.proficiencyId;

    const keys = Object.keys(values);
    const requsetData = {
      ...(keys?.includes('languageId') && languageId !== values?.languageId ? { languageId: values?.languageId ?? null } : {}),
      ...(keys?.includes('proficiencyId') && proficiencyId !== proficiencyIdField ? { proficiencyId: proficiencyIdField } : {}),
    };

    if (Object.keys(requsetData).length) {
      updateLanguage({
        id: userId,
        language: { ...(requsetData as ILanguageVariables), id },
      });
      setField('languages');
    }
    setEdit(false);
  };

  const handleCollapseChange = (key: string[] | string): void => {
    if (key?.length) {
      !languages?.length ? setCreate(true) : setCreate(false);
      setEdit(false);
      formCreate.resetFields();
    }
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <Row align={'middle'} style={{ gap: 4 }}>
          <Points
            style={{
              height: wibdowWidth < 768 ? 32 : 54,
              width: wibdowWidth < 768 ? 32 : 54,
              fontSize: wibdowWidth < 768 ? 11 : 16,
            }}
          >
            {points ?? 0}/12
          </Points>
          <LanguageSvg />
          <Text style={{ fontSize: wibdowWidth < 768 ? 14 : 20, color: `${INDIGO}` }}>Languages</Text>
        </Row>
      ),
      children: isFetchingUser ? (
        <Row justify={'center'}>
          <Spin />
        </Row>
      ) : (
        <>
          <Row align={'middle'} justify={'end'}>
            <AddSvg style={{ cursor: 'pointer' }} onClick={handleClickAdd} />
          </Row>
          <Progress
            style={{ marginBottom: 0 }}
            percent={20}
            strokeColor={LAVENDER}
            showInfo={false}
            size={['100%', 1]}
            trailColor={GRAY}
          />
          {!!languages?.length &&
            languages?.map((language) => (
              <UserInfoCard
                key={language?.id}
                edit={edit as string}
                id={language?.id}
                title={'Language'}
                info={language?.language?.title}
                setId={setOpenDeleteModalById}
                handleEdit={(): void => handleEdit(language?.id)}
              >
                {edit === language?.id && (
                  <AsnForm
                    form={formUpdate}
                    layout='vertical'
                    onFinish={(): void => onUpdateLanguage(language?.languageId, language?.proficiencyId, language?.id)}
                    validateMessages={validate_messages}
                  >
                    <LanguagesForm languageField={language?.language} proficiency={language?.proficiency} />

                    {wibdowWidth < 768 ? (
                      <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                        <Col span={24}>
                          <AsnForm.Item style={{ marginBottom: 0 }}>
                            <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
                          </AsnForm.Item>
                        </Col>
                        <Col span={24}>
                          <AsnButton onClick={(): void => handleCancelUpdate()} style={{ width: '100%' }}>
                            Cancel
                          </AsnButton>
                        </Col>
                      </Row>
                    ) : (
                      <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                        <AsnButton onClick={(): void => handleCancelUpdate()}>Cancel</AsnButton>
                        <AsnForm.Item style={{ marginBottom: 0 }}>
                          <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' />
                        </AsnForm.Item>
                      </Row>
                    )}
                  </AsnForm>
                )}
              </UserInfoCard>
            ))}
          {!edit && create && (
            <AsnForm
              form={formCreate}
              layout='vertical'
              validateMessages={validate_messages}
              onFinish={(values): void => onFinish(values as ILanguageVariables)}
            >
              <LanguagesForm />
              {wibdowWidth < 768 ? (
                <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                  <Col span={24}>
                    <AsnForm.Item style={{ marginBottom: 0 }}>
                      <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' style={{ width: '100%' }} />
                    </AsnForm.Item>
                  </Col>
                  <Col span={24}>
                    <AsnButton onClick={(): void => handleCancelCreate()} style={{ width: '100%' }}>
                      Cancel
                    </AsnButton>
                  </Col>
                </Row>
              ) : (
                <Row align={'middle'} justify={'end'} style={{ gap: '16px' }}>
                  <AsnButton onClick={(): void => handleCancelCreate()}>Cancel</AsnButton>
                  <AsnForm.Item style={{ marginBottom: 0 }}>
                    <MainButton text={'Save'} icon={<DotsSvg />} htmlType='submit' />
                  </AsnForm.Item>
                </Row>
              )}
            </AsnForm>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Row style={{ background: `${WHITE}` }}>
        <Col span={24} xxl={{ span: 21, push: 3 }}>
          <AsnCollapse
            items={items}
            bordered={false}
            expandIconPosition={'end'}
            expandIcon={({ isActive }): React.ReactNode => (isActive ? <ArrowUp /> : <ArrowDown />)}
            onChange={handleCollapseChange}
            defaultActiveKey={languages?.length ? ['1'] : []}
          />
        </Col>
      </Row>
      {!!openDeleteModalById && (
        <DeleteModal
          openDeleteModal={!!openDeleteModalById}
          setOpenDeleteModal={setOpenDeleteModalById}
          title={'Are you sure to delete this language?'}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};
export default Languages;
