import React from 'react';
import { Row, Col, Typography } from 'antd';

import { COLORS } from '../../constants';
import { IProfessionDetails } from '../../types/careerPaths';
import { Points } from '../../components';

const { Title, Paragraph } = Typography;
const { WHITE } = COLORS;

const ProfessionDetails: React.FC<IProfessionDetails> = ({ profession, points }) => {
  const wibdowWidth = window.innerWidth;
  return (
    <Row style={{ background: `${WHITE}`, padding: '32px 4%' }}>
      {wibdowWidth < 768 ? (
        <Col span={24}>
          <Row justify={'space-between'} gutter={[10, 10]}>
            <Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
              <Points content='profession'>{points ?? 0}/100</Points>
            </Col>
            <Col span={24}>
              <Title level={5}>{profession?.title}</Title>
              <Paragraph style={{ fontSize: 16 }}>{profession?.description}</Paragraph>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col span={24} xxl={{ pull: 2, push: 2, span: 20 }}>
          <Row justify={'space-between'}>
            <Col span={22}>
              <Title level={5}>{profession?.title}</Title>
              <Paragraph style={{ fontSize: 16 }}>{profession?.description}</Paragraph>
            </Col>
            <Col>
              <Points content='profession'>{points ?? 0}/100</Points>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default ProfessionDetails;
