import React from 'react';
import { Input } from 'antd';

import { AsnForm } from '../../components';
import { COLORS } from '../../constants';
import { IInputTextArea } from '../../types/chat';
import { normalize, validateForm } from '../../helpers';

const { BLACK } = COLORS;

const InputTextArea: React.FC<IInputTextArea> = ({ name, edit, inputRef }) => {
  const form = AsnForm.useFormInstance();
  const wibdowWidth = window.innerWidth;

  return (
    <AsnForm.Item
      name={[name, 'textAnswer']}
      rules={[
        { required: true, message: 'Please fill field' },
        {
          validator: (_, value: string) => validateForm(_, value, 'Field', 1, 100),
        },
      ]}
      normalize={(value: string): string => normalize(value)}
    >
      <Input.TextArea
        ref={form?.getFieldValue(['answers', name, 'questionId']) === edit ? inputRef : null}
        disabled={edit !== form?.getFieldValue(['answers', name, 'questionId'])}
        autoSize
        style={{
          maxWidth: wibdowWidth < 768 ? 'auto' : '42vw',
          width: '100%',
          color: `${BLACK}`,
          fontWeight: 600,
          padding: 0,
        }}
        bordered={false}
      />
    </AsnForm.Item>
  );
};

export default InputTextArea;
