import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Space, Typography, Row, Popover, Image, Col, Tooltip, Progress, Menu } from 'antd';
import styled from 'styled-components';

import AsnMenu from '../../Menu';
import Points from '../../Points';
import { useAuth } from '../../../hooks/useAuth';
import { IMainLayout } from '../../../types/global';
import { COLORS, menuItemsHome, PATHS, ProfileProgressInfo } from '../../../constants';
import { ReactComponent as ProgressSvg } from '../../../assets/icons/progress.svg';
import { ReactComponent as Logo } from '../../../assets/icons/proflab.svg';
const { WHITE, GRAY, ERROR, SUCCESS, BLACK } = COLORS;

const { Header, Content, Footer } = Layout;
const { Paragraph, Text } = Typography;

const AsnSider = styled(Col)`
  ul {
    display: flex;
    border-bottom: 1px solid rgb(199, 199, 199);
    li {
      padding-inline: 0px !important;
      height: auto !important;
      align-items: start !important;
    }
  }
`;

const AsnHeader = styled(Header)`
  &.ant-layout-header {
    background-color: ${WHITE};
    border-bottom: 1px solid ${GRAY};
  }
`;

const HomepageLayoutMoabile: React.FC<IMainLayout> = ({ children, points }) => {
  const [showPopover, setShowPoppover] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { logout, answeredQuestion, user } = useAuth();

  const handleMenuChange = (info: { key: string }): void => {
    const { key } = info;
    if ((user?.totalPoints ?? 0) < 49 && key === '/chat?menu=Chat') return;
    if (user?.hesSelectedProfession === false && key === '/career?menu=My Career Paths') return;
    navigate(key);
  };

  return (
    <Layout style={{ flexDirection: 'column' }}>
      <AsnHeader style={{ height: 77, padding: '0 16px' }}>
        <Row style={{ width: '100%', justifyContent: 'space-between' }} align={'middle'}>
          <Row gutter={[20, 0]}>
            <Col>
              <Logo
                style={{ paddingTop: 27, height: 52, cursor: 'pointer' }}
                onClick={(): void => navigate(PATHS.LOGGED_USER_LANDING)}
              />
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
              {pathname === '/chat' ? (
                <Col span={20}>
                  <Points style={{ height: 32, width: 32, fontSize: 11, lineHeight: 0 }}>{answeredQuestion}/28</Points>
                </Col>
              ) : (
                <Row>
                  {' '}
                  {pathname === '/chat' ||
                  pathname === '/instruction' ||
                  pathname === '/career' ||
                  pathname === '/feedback' ||
                  pathname === '/home' ||
                  pathname === '/myinfo' ||
                  pathname === '/change-password' ? (
                    <Row></Row>
                  ) : (
                    <Space
                      direction='vertical'
                      style={{
                        border: `1px solid ${GRAY}`,
                        padding: ' 5px',
                        width: 'calc(100vw - 114px)',
                      }}
                      size={0}
                    >
                      <Row>
                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip
                            placement='topLeft'
                            title={ProfileProgressInfo}
                            color={WHITE}
                            overlayInnerStyle={{ color: `${BLACK}` }}
                          >
                            <ProgressSvg style={{ minWidth: 16 }} />
                          </Tooltip>
                          <Progress
                            style={{ marginBottom: 0, minWidth: '5vw' }}
                            percent={points?.totalPoints ?? (0 as number)}
                            strokeColor={(points?.totalPoints as number) > 49 ? SUCCESS : ERROR}
                            showInfo={false}
                          />
                          <Text style={{ whiteSpace: 'nowrap' }}>{points?.totalPoints} points</Text>
                        </Col>
                      </Row>
                    </Space>
                  )}
                </Row>
              )}
            </Col>
          </Row>

          <Row>
            <Popover
              content={
                <Space direction='vertical' onClick={(): void => setShowPoppover(false)}>
                  <Paragraph
                    style={{ cursor: 'pointer', marginBottom: 0 }}
                    onClick={(): void => navigate(`${PATHS.MY_INFO}?menu=My info`)}
                  >
                    My info
                  </Paragraph>
                  <Paragraph
                    style={{ cursor: 'pointer', marginBottom: 0 }}
                    onClick={(): void => navigate(`${PATHS.CHANGE_PASSWORD}?menu=Change Password`)}
                  >
                    Change password
                  </Paragraph>
                  <Paragraph style={{ cursor: 'pointer', marginBottom: 0 }} onClick={(): void => logout()}>
                    Log out
                  </Paragraph>
                </Space>
              }
              trigger='click'
              arrow={false}
              open={showPopover}
              onOpenChange={(open: boolean): void => setShowPoppover(open)}
            >
              <Row>
                <Image
                  preview={false}
                  src={user?.photo ?? './assets/user.png'}
                  style={{ width: 32, height: 32, borderRadius: '50%', cursor: 'pointer' }}
                />
              </Row>
            </Popover>
          </Row>
        </Row>
      </AsnHeader>
      <Content>
        <AsnSider>
          <AsnMenu
            selectedKeys={
              pathname === '/chat' && search?.includes('status')
                ? [pathname + '?menu=Chat']
                : [pathname + decodeURIComponent(search)]
            }
            onSelect={(info): void => handleMenuChange(info)}
            className='homepage homepageMobaile'
            mode='inline'
            inlineIndent={12}
          >
            {menuItemsHome.map((item) => (
              <Menu.Item key={item.key}>
                <>
                  <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    {item.icon}
                  </Col>
                  <div
                    style={{
                      wordWrap: 'break-word',
                      whiteSpace: 'normal',
                      lineHeight: '15px',
                      fontSize: '12px',
                      textAlign: 'center',
                    }}
                  >
                    {item.label}
                  </div>
                </>
              </Menu.Item>
            ))}
          </AsnMenu>
        </AsnSider>
      </Content>
      <Footer style={{ padding: '0' }}>
        <Content style={{ overflow: 'auto' }}>{children}</Content>
      </Footer>
    </Layout>
  );
};

export default HomepageLayoutMoabile;
