import { MenuProps } from 'antd';

import { ReactComponent as PrivacySvg } from '../assets/icons/privacy.svg';
import { ReactComponent as InfoSvg } from '../assets/icons/info.svg';
import { ReactComponent as ChatSvg } from '../assets/icons/chat.svg';
import { ReactComponent as CareerSvg } from '../assets/icons/career.svg';
import { ReactComponent as FeedBackSvg } from '../assets/icons/feedback.svg';

export const PATHS = {
  LANDING: '/',
  MY_INFO: '/myinfo',
  SIGNIN: '/sign-in',
  SIGNUP: 'sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/restore-password',
  VERIFY: '/confirm-email',
  CHANGE_PASSWORD: '/change-password',
  PRIVACY_POLICY: '/privacy-policy',
  INSTRUCTION: '/instruction',
  CHAT: '/chat',
  CAREER_PATHS: '/career',
  FEEDBACK: '/feedback',
  LOGGED_USER_LANDING: '/home',
  PRIVACY_POLICY_LOGGED: '/privacy',
};

export const AUTH_KEYS = {
  USER: 'user',
  TOKEN: 'token',
};

export const COLORS = {
  // LAVENDER: '#68468E',
  LAVENDER: '#535789',
  DARK_BLUE: '#5277A8',
  YELLOW: '#D9CA6D',
  FUZZY: '#D96868',
  INDIGO: '#283A52',
  WHITE: '#FFFFFF',
  GUNMETAL: '#1B2532',
  GRAY: '#C7C7C7',
  DARK_GRAY: '#808080',
  TANGERINE: '#FFA310',
  LIGHT_BLUE: '#149EF2',
  FOLLY: '#F20057',
  LAVENDER_INDIGO: '#8252DD',
  PLATINUM: '#E6E7E8',
  BLACK: 'rgba(0, 0, 0, 0.88)',
  JACARTA: '#462F60',
  AMETHYST: '#93899E',
  VIOLET: '#342347',
  ERROR: '#F03738',
  SUCCESS: '#4CAF50',
  MAGNOLIA: '#FCFBFD',
  LIGHT_VIOLET: '#EDE9F2',
  LACE: '#FBF5E7',
  SOLITUDE: '#EBEEF5',
  AMOUR: '#F9ECEE',
  MAIN_BG: '#f5f5f5',
  DISABLED: 'rgba(0, 0, 0, 0.04)',
  GRAY_CARD: '#ECECEC',
  TEXT: '#1E2037',
  LAVENDER_100: '#717EBA',
  PEACOAT: '#1F2335',
  LAVENDER_20: '#525A81',
  GREY_200: '#616783',
};

export const validate_messages = {
  required: '${label} is required',
  pattern: {
    mismatch: '${label} requirements were not met',
  },
};

export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,50}$/;
export const menuItems: MenuProps['items'] = [
  {
    label: <a href='#description'>Description</a>,
    key: 'description',
  },
  {
    label: <a href='#benefits'>Benefits</a>,
    key: 'about',
  },
  {
    label: <a href='#resources'>Resources</a>,
    key: 'resources',
  },
  {
    label: <a href='#contacts'>Contacts</a>,
    key: 'contacts',
  },
];

export const menuItemsHome = [
  {
    key: '/instruction?menu=Instruction',
    label: 'Instruction',
    icon: <PrivacySvg />,
  },
  {
    key: '/myinfo?menu=My info',
    label: 'My info',
    icon: <InfoSvg />,
  },
  {
    key: '/chat?menu=Chat',
    label: 'Chat',
    icon: <ChatSvg />,
  },
  {
    key: '/career?menu=My Career Paths',
    label: 'My career paths',
    icon: <CareerSvg />,
  },
  {
    key: '/feedback?menu=Feedback',
    label: 'Feedback',
    icon: <FeedBackSvg />,
  },
];

export const optionsNames = ['martial', 'employed', 'disability'];

export const sexOptions = [
  { value: 'e6ebd66c-fa1f-4490-b965-61d9d99aafb1', label: 'Male' },
  { value: 'abcf49ec-bb99-4f8b-a337-1988c5673f13', label: 'Female' },
];

export const questionsMyInfo = [
  {
    name: 'maritalId',
    question: 'Martial status',
    answers: [
      {
        id: '1b9ac507-b525-4774-85d2-c5f745e61f69',
        label: 'Married',
      },
      {
        id: '757cbe3f-077d-4bf9-b981-579a0b649e2b',
        label: 'Single',
      },
      {
        id: '47c53188-95ae-416f-bfd4-a8de367d34f3',
        label: 'Divorced',
      },
      {
        id: '0d1d0ac7-0c8d-4ecc-a255-da87c1b5ba45',
        label: 'In a relationship',
      },
      {
        id: 'f26efa8b-14e8-4b8e-b210-976034ebe93e',
        label: 'Prefer not to say',
      },
    ],
  },
  {
    name: 'employmentId',
    question: 'Are you currently employed?',
    answers: [
      {
        id: '18849fb7-a161-49a5-a1f7-05a948efd3b9',
        label: 'Yes, full-time job',
      },
      {
        id: '053ddee3-7088-4b7f-b614-d0eaa7470f16',
        label: 'Yes, part-time job',
      },
      {
        id: '290a3c44-cbc1-4066-997a-04cfbdeb8ee6',
        label: 'Unemployed',
      },
      {
        id: '70fb3ce3-a414-47b8-9815-a3cc4e1ad25a',
        label: 'Freelance',
      },
      {
        id: 'fdaded01-a80a-4d5d-8016-43dd3eb0ba7f',
        label: 'Self-employed',
      },
      {
        id: 'b1e22598-b4b0-47be-b3de-a59ccc281260',
        label: 'Student',
      },
    ],
  },
  {
    name: 'disabilityId',
    question: 'Do you have a disability that may limit physical activities in your job?',
    answers: [
      {
        id: 'd5f825ad-4482-490c-a5de-08a773d4ef3c',
        label: 'No',
      },
      {
        id: 'edd57c15-a051-40d0-b55a-9a11b2f0609b',
        label: 'Yes',
      },
      {
        id: 'e6bc5cbe-f775-459d-a436-52f2ffdf7918',
        label: 'Prefer not to say',
      },
    ],
  },
];

export const selectProps = {
  suffixIcon: null,
  showSearch: true,
  defaultActiveFirstOption: false,
  notFoundContent: null,
  filterOption: false,
  allowClear: true,
};

export const ProfileProgressInfo =
  'The completeness of your profile is evaluated by points. You need 50 and more points to start chatting with ProfLab career coach';

export const proficiencyOptions = [
  { value: '0de33fe9-947e-48da-b984-4b46e6227a31', label: 'Native or bilingual proficiency' },
  { value: '74a90593-56db-4ffb-a751-9b800a0f237b', label: 'Full professional proficiency' },
  { value: '54ed674e-5ae6-41eb-a6f7-b113f3ab1641', label: 'Professional working proficiency' },
  { value: 'f7ee58bd-86ee-47ba-9586-5feb57c1e011', label: 'Limited working proficiency' },
  { value: 'ac5f5fec-f6c0-4858-8cc4-d3aa2bc97887', label: 'Elementary proficiency' },
];
export const swiperCoverflowProps = {
  spaceBetween: 30,
  loop: true,
  effect: 'coverflow',
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  breakpoints: {
    300: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 4,
    },
  },
  centeredSlides: true,
  centeredSlidesBounds: true,
  coverflowEffect: {
    rotate: 0,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: false,
  },
  pagination: true,
};

export const resources = [
  {
    title: 'How to Successfully Navigate a Midlife Career Change and Avoid a Crisis',
    url: ' https://online.wharton.upenn.edu/blog/midlife-career-change/',
  },
  {
    title: `19 highly successful people who prove it's never too late to change careers`,
    url: 'https://www.businessinsider.com/successful-people-who-made-a-big-career-change-2016-4',
  },
  {
    title: `How To Change Career When You've No Idea What To Do Next`,
    url: 'https://www.careershifters.org/expert-advice/how-to-change-career-when-youve-no-idea-what-to-do-next',
  },
  {
    title: 'Career Change at 40: Moving Into Your Next Stage',
    url: 'https://www.coursera.org/articles/career-change-at-40',
  },
  {
    title: `Switching Careers After 40: Three Success Stories`,
    url: 'https://www.forbes.com/sites/learnvest/2013/03/27/switching-careers-after-40-three-success-stories/?sh=2241c9d2504e',
  },
];

export const testimonals = [
  {
    img: './assets/testimonals1.png',
    comment: `I realized that it is never too late to change your career course. ProfLab results confirmed my determination to pursue a new career in marketing. It was a very valuable experience for me to register at ProfLab.
    `,
    name: 'Diana',
  },
  {
    img: './assets/testimonals2.jpg',
    comment: `I have always loved my job, but at some point I had a burnout. I needed a change although I did not want to quit medicine. ProfLab suggested that I become a healthcare management specialist. This sounds like a perfect solution for me. 
    `,
    name: 'Mickael ',
  },
  {
    img: './assets/testimonals3.png',
    comment: `I had a stressful job and have always dreamt of changing it. ProfLab gave me the right answer at the right time. And now I am learning a new profession that I will enjoy every day. 
    `,
    name: 'Maria',
  },
  {
    img: './assets/testimonals4.png',
    comment: `ProfLab gave me a useful insight into my future career path. By answering a series of questions I was able to better understand my past experience, life preferences and career goals. 
    `,
    name: 'Anna',
  },
  {
    img: './assets/testimonals5.png',
    comment: `Engaging with ProfLab career development program has been an eye-opening experience. It helped me identify my strengths and weaknesses and find the perfect job for me. 
    `,
    name: 'Karen',
  },
];
export const userlogoData = [
  './assets/quickMobaile.png',
  './assets/jobMobaile.png',
  './assets/soft.png',
  './assets/analysed.png',
];
