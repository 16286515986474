import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Typography, Row, Col, Space, Popover, Image, Flex } from 'antd';
import styled from 'styled-components';

import AsnMenu from '../../Menu';
import { useAuth } from '../../../hooks/useAuth';
import { COLORS, menuItems, PATHS } from '../../../constants';
import { ReactComponent as Logo } from '../../../assets/icons/proflab.svg';
// import { ReactComponent as Fb } from '../../../assets/icons/fb.svg';
// import { ReactComponent as Twitter } from '../../../assets/icons/twitter.svg';
// import { ReactComponent as YouTube } from '../../../assets/icons/youtube.svg';
import { ReactComponent as DotsSvg } from '../../../assets/icons/button.svg';
import { ReactComponent as LogoSvg } from '../../../assets/icons/logoFooter.svg';
import AsnButton, { MainButton } from '../../Button';
import { IMainLayout } from '../../../types/global';

const { WHITE, AMETHYST, PEACOAT, LAVENDER_20, GREY_200 } = COLORS;

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;

const AsnLayout = styled(Layout)`
  &.ant-layout {
    background: ${WHITE};
  }
`;

const width = window.innerWidth;

const MainLayout: React.FC<IMainLayout> = ({ children, logged }) => {
  const [showPopover, setShowPoppover] = useState<boolean>(false);
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const navigatePolicy = (): void => {
    if (user) {
      window.open(PATHS.PRIVACY_POLICY_LOGGED, '_blank');
    } else {
      window.open(PATHS.PRIVACY_POLICY, '_blank');
    }
  };

  return (
    <AsnLayout style={{ backgroundImage: 'url(./assets/landingBg.png)', backgroundSize: 'cover' }}>
      <Header
        style={{ paddingInline: '16px', backgroundColor: 'transparent', borderBottom: `1px solid ${WHITE}`, marginBottom: 16 }}
      >
        <Row justify={'space-between'}>
          <Col md={{ span: 17 }} sm={{ span: 16 }} style={{ display: 'flex', alignItems: 'center' }}>
            <Logo style={{ marginRight: '10%' }} />
            <AsnMenu
              className='landing'
              mode='horizontal'
              items={menuItems}
              expandIcon={false}
              style={{ width: '70%', backgroundColor: 'transparent' }}
            />
          </Col>
          {logged ? (
            <Row justify={'end'}>
              <Popover
                content={
                  <Space direction='vertical' onClick={(): void => setShowPoppover(false)}>
                    <Paragraph
                      style={{ cursor: 'pointer', marginBottom: 0 }}
                      onClick={(): void => navigate(`${PATHS.MY_INFO}?menu=My info`)}
                    >
                      My info
                    </Paragraph>
                    <Paragraph
                      style={{ cursor: 'pointer', marginBottom: 0 }}
                      onClick={(): void => navigate(`${PATHS.CHANGE_PASSWORD}?menu=Change Password`)}
                    >
                      Change password
                    </Paragraph>
                    <Paragraph style={{ cursor: 'pointer', marginBottom: 0 }} onClick={(): void => logout()}>
                      Log out
                    </Paragraph>
                  </Space>
                }
                trigger='click'
                arrow={false}
                open={showPopover}
                onOpenChange={(open: boolean): void => setShowPoppover(open)}
              >
                <Row>
                  <Image
                    preview={false}
                    src={user?.photo ?? './assets/user.png'}
                    style={{ width: 32, height: 32, borderRadius: '50%', cursor: 'pointer' }}
                  />
                </Row>
              </Popover>
            </Row>
          ) : (
            <Col md={{ span: 7 }} sm={{ span: 8 }} style={{ textAlign: 'end' }}>
              <AsnButton
                className='secondary'
                style={{ color: `${AMETHYST}`, marginRight: '16px' }}
                onClick={(): void => navigate(PATHS.SIGNIN)}
              >
                Log in
              </AsnButton>
              <MainButton text={'Sign up'} icon={<DotsSvg />} onClick={(): void => navigate(PATHS.SIGNUP)} />
            </Col>
          )}
        </Row>
      </Header>
      <Content>{children}</Content>
      <Footer id='contacts' style={{ backgroundColor: `${PEACOAT}`, padding: '24px 16px' }}>
        <div style={{ marginBottom: 16 }}>
          <LogoSvg />
        </div>
        <Flex justify='space-between' vertical={width < 992}>
          <Flex vertical style={{ width: width < 992 ? '100%' : '25%' }} gap={16}>
            <Title style={{ color: `${LAVENDER_20}`, fontSize: 18, marginBottom: 0 }}>About</Title>
            <Paragraph style={{ color: `${WHITE}`, marginBottom: 0 }}>
              Find your new professional path with the help of our innovative career development tool
            </Paragraph>
            <Paragraph style={{ color: `${GREY_200}` }}>© ProfLab 2024</Paragraph>
          </Flex>
          <Flex vertical>
            <Title style={{ color: `${LAVENDER_20}`, fontSize: 18 }}>Email</Title>
            <Paragraph style={{ color: `${WHITE}` }}>info@proflab.ai</Paragraph>
          </Flex>
          {/* <Flex vertical>
            <Title style={{ color: `${LAVENDER_20}`, fontSize: 18 }}>Phone number:</Title>
            <Paragraph style={{ color: `${WHITE}` }}> +37493635500</Paragraph>
          </Flex> */}
          <Flex vertical>
            <Title style={{ color: `${LAVENDER_20}`, fontSize: 18 }}>Address:</Title>
            <Paragraph style={{ color: `${WHITE}` }}>45 M. Mashtots Ave. 5, 0009 Yerevan, Armenia</Paragraph>
          </Flex>
          {/* <Flex>
            <Fb />
            <Twitter />
            <YouTube />
          </Flex> */}
          <Title style={{ color: `${LAVENDER_20}`, fontSize: 18, cursor: 'pointer', margin: 0 }} onClick={navigatePolicy}>
            Privacy policy
          </Title>
        </Flex>
      </Footer>
    </AsnLayout>
  );
};

export default MainLayout;
