import { Row, Col, Space, Typography } from 'antd';
import React from 'react';

import { COLORS } from '../../constants';
import { ReactComponent as LogoSvg } from '../../assets/icons/proflab.svg';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { IStart } from '../../types/chat';
import { MainButton } from '../../components/Button';

const { Text } = Typography;
const { WHITE, LIGHT_VIOLET, SOLITUDE } = COLORS;

const Start: React.FC<IStart> = ({ setStart, responses }) => {
  const wibdowWidth = window.innerWidth;

  return (
    <Row style={{ height: '100%', paddingTop: wibdowWidth < 768 ? 0 : 24 }}>
      <Col span={wibdowWidth < 768 ? 24 : 16} pull={wibdowWidth < 768 ? 0 : 4} push={wibdowWidth < 768 ? 0 : 4}>
        <Space
          direction='vertical'
          style={{
            background: `${WHITE}`,
            padding: wibdowWidth < 768 ? 10 : '32px 60px',
            width: '100%',
            marginBottom: 24,
          }}
        >
          <Row align={'middle'} justify={'center'} style={{ padding: 8 }}>
            <LogoSvg />
          </Row>
          <Row style={{ background: `${LIGHT_VIOLET}`, padding: 8 }}>
            <Text style={{ color: '#283A52', fontSize: 16 }}>
              Congratulations on reaching the next step towards discovering your career path through our AI-powered Career Coach.
            </Text>
          </Row>
          <Row style={{ background: `${SOLITUDE}`, padding: 8 }}>
            <Text style={{ fontSize: 16 }}>
              This test will take approximately 25-30 minutes of your time. It consists of both open-end and multiple choice
              questions. By the end of the test you will receive four suggestions of career paths relevant to your background,
              test results and other personal information. You will then choose one of them to receive specific options of
              educational programs and jobs currently available on the market.
            </Text>
          </Row>
          <Row style={{ background: `${LIGHT_VIOLET}`, padding: 8 }}>
            <Text style={{ fontSize: 16 }}>
              Please keep in mind, this test is not only about finding the perfect job but also about understanding yourself
              better. The results of this test are meant to provide guidance and should be considered as a starting point for
              further exploration. Our goal is to empower you to make informed choices and take confident steps towards a
              successful career.
            </Text>
          </Row>
          <Row style={{ background: `${SOLITUDE}`, padding: 8 }}>
            <Text style={{ fontSize: 16 }}>
              Thank you for choosing ProfLab. Now, let&apos;s continue this exciting journey and uncover the possibilities ahead!
            </Text>
          </Row>
        </Space>
        <Row justify={'end'} style={{ padding: wibdowWidth < 768 ? '20px 0' : 60 }}>
          <MainButton
            text={responses ? 'Next' : 'Start'}
            icon={<DotsSvg />}
            onClick={(): void => setStart(true)}
            style={{ width: wibdowWidth < 768 ? '100%' : 'auto', margin: wibdowWidth < 768 ? '0px 10px' : 'auto' }}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default Start;
