import { useQuery } from '@tanstack/react-query';

import client from '../../client';
import { TUseGetStaticData, TGetConstants } from '../../../types/api/myInfo';

const url = '/api/user/countries';

const useGetCountries: TUseGetStaticData = (search, options) => {
  const result = useQuery({
    queryKey: [url, search],
    queryFn: () => client.post(url, { search }),
    ...options,
    select: (data) => data?.data?.result,
  });

  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ([] as TGetConstants[]),
    isLoading,
    isFetching,
  };
};

export default useGetCountries;
