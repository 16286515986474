import { Flex, Typography, Rate } from 'antd';

import { COLORS } from '../../constants';
import { ITestimonalCard } from '../../types/components';

const { Title, Text } = Typography;
const { LAVENDER, WHITE } = COLORS;

const TestimonalCard: React.FC<ITestimonalCard> = ({ img, name, comment }) => {
  return (
    <div style={{ background: `${WHITE}`, padding: 16, borderRadius: 8, marginBottom: 8 }}>
      <Flex gap={16} style={{ marginBottom: 24 }}>
        <img src={img} style={{ borderRadius: '100%', objectFit: 'cover' }} width={64} height={64} />
        <Flex vertical>
          <Title level={3}>{name}</Title>
          <Rate value={name === 'Mickael' || name === 'Maria' ? 4 : 5} disabled />
        </Flex>
      </Flex>
      <Text style={{ color: `${LAVENDER}` }}>{comment}</Text>
    </div>
  );
};

export default TestimonalCard;
