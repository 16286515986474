import React, { useState, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Row, Col, Spin } from 'antd';

import { useAuth } from '../../hooks/useAuth';
import { useGetQuestions, useSubmitAnswers, useGetProfessions, useGetUserResponses } from '../../api';
import { AsnForm } from '../../components';
import { IAnswers } from '../../types/chat';
import { ReactComponent as DotsSvg } from '../../assets/icons/button.svg';
import { MainButton } from '../../components/Button';

import Start from './Start';
import FormList from './FormList';
import AnswerField from './AnswerField';
import FinalPhase from './FinalPhase';
import ResponsesView from './ResponsesView';

const Chat: React.FC = () => {
  const [start, setStart] = useState<boolean>(false);
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const status = searchParams?.get('status');
  const wibdowWidth = window.innerWidth;
  const { setAnsweredQuestion, user } = useAuth();
  const [form] = AsnForm.useForm();
  const scrollRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();

  const { data: responses, isFetching: isFetchingResponses } = useGetUserResponses(user?.id as string, {
    enabled: Boolean(user?.id && !status),
    staleTime: 1000 * 60 * 60,
  });
  const { data: professions, isFetching: isFetchingProfessions } = useGetProfessions(user?.id as string, {
    enabled: Boolean(status && user?.id),
  });
  const { data: questions, isFetching: isFetchingQuestions } = useGetQuestions({
    enabled: Boolean(!responses && !status && user?.id),
  });

  const { mutate: submitAnswers, isLoading } = useSubmitAnswers();

  const onFinish = (values: IAnswers): void => {
    const params = { menu: 'Chat', status: 'submit' };
    setSearchParams(params);
    submitAnswers(
      {
        userId: user?.id as string,
        answers: values?.answers,
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(['/api/career-coaching/user/:userId/professions']);
        },
      },
    );
  };

  useEffect(() => {
    if (questionIndex === 0) {
      setAnsweredQuestion(0);
    }
    if (responses || !!professions.length) {
      setAnsweredQuestion(28);
    }
    if (questionIndex > 1 && questionIndex !== 28) {
      const newContentElement = scrollRef?.current?.lastElementChild;
      newContentElement?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [questionIndex, responses, professions, loading]);

  return (
    <>
      {isFetchingProfessions || isFetchingQuestions || isFetchingResponses ? (
        <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
          <Spin />
        </Row>
      ) : !start && !status ? (
        <Start setStart={setStart} responses={Boolean(responses)} />
      ) : (
        <>
          {!responses && !status && (
            <Row
              style={{ position: 'relative', minHeight: 'calc(91vh - 214px)', padding: wibdowWidth < 768 ? '0 10px' : 'auto' }}
            >
              <Col span={24}>
                <AsnForm form={form} layout='vertical' onFinish={(values): void => onFinish(values as IAnswers)}>
                  <FormList questions={questions} questionIndex={questionIndex} scrollRef={scrollRef} />
                  {questionIndex === 28 && (
                    <Row justify={wibdowWidth < 768 ? 'center' : 'end'} style={{ width: '100%', padding: 32 }}>
                      <MainButton text={'Submit My Answers'} icon={<DotsSvg />} htmlType='submit' />
                    </Row>
                  )}
                </AsnForm>
              </Col>
            </Row>
          )}
          {questionIndex !== 28 && !responses && !status && (
            <AnswerField
              setAnsweredQuestion={setAnsweredQuestion}
              setQuestionIndex={setQuestionIndex}
              form={form}
              questions={questions}
              questionIndex={questionIndex}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {status && (
            <FinalPhase
              status={status}
              userId={user?.id as string}
              professions={professions}
              isLoading={isLoading}
              setSearchParams={setSearchParams}
            />
          )}
          {!!responses && !status && (
            <ResponsesView responses={responses} setSearchParams={setSearchParams} setStart={setStart} />
          )}
        </>
      )}
    </>
  );
};

export default Chat;
