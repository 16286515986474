import React from 'react';
import { Row, Col, Typography } from 'antd';

import { COLORS } from '../../constants';

const { LAVENDER } = COLORS;
const { Title, Paragraph } = Typography;

const Description: React.FC = () => {
  return (
    <Row
      style={{
        padding: '0px 16px',
        backgroundImage: 'url(./assets/logoBig.png)',
        backgroundPositionX: '80%',
        backgroundRepeat: 'no-repeat',
        marginBottom: 8,
      }}
      id='description'
    >
      <Col
        xs={{ span: 24 }}
        md={{ span: 17 }}
        style={{
          background: 'linear-gradient(white, rgba(255, 255, 255, 0.7))',
          zIndex: 1,
          borderRadius: 8,
          padding: '32px 16px',
        }}
      >
        <Title level={3} style={{ fontWeight: 500 }}>
          Description
        </Title>
        <Title level={4} style={{ fontWeight: 500 }}>
          Professional Transformation
        </Title>
        <Paragraph style={{ color: `${LAVENDER}`, fontSize: 16 }}>
          In today&apos;s constantly changing job market, finding the right career path can be a difficult task. That&apos;s why
          we are excited to introduce our innovative AI-based Career Coach, designed to be your personal guide in navigating the
          complex world of careers and professional development.
        </Paragraph>
        <Title level={4} style={{ fontWeight: 500 }}>
          Personalized Career Paths
        </Title>
        <Paragraph style={{ color: `${LAVENDER}`, fontSize: 16 }}>
          Our AI-powered Career Coach leverages advanced algorithms and machine learning to provide you with personalized career
          choices based on your previous background and education, as well as tailored to your unique skills, interests, and
          preferences.
        </Paragraph>
        <Title level={4} style={{ fontWeight: 500 }}>
          Comprehensive and Accurate Results
        </Title>
        <Paragraph style={{ color: `${LAVENDER}`, fontSize: 16 }}>
          Using the power of artificial intelligence, our Career Coach analyzes vast amounts of career data, industry trends, and
          job market information. It combines this knowledge with your input to generate comprehensive and accurate results.
        </Paragraph>
        <Paragraph style={{ color: `${LAVENDER}`, fontSize: 16 }}>
          These results will help you discover exciting career possibilities you may not have considered before.
        </Paragraph>
      </Col>
    </Row>
  );
};

export default Description;
