import React from 'react';
import { Row, Col, Typography } from 'antd';

import { COLORS } from '../../constants';
import { ISuggestion } from '../../types/careerPaths';

const { WHITE } = COLORS;
const { Title, Paragraph } = Typography;

const Suggestion: React.FC<ISuggestion> = ({ suggestion }) => {
  const wibdowWidth = window.innerWidth;
  return (
    <Row style={{ background: `${WHITE}`, padding: '32px 4%' }}>
      <Col span={24} xxl={{ pull: 2, push: 2, span: 20 }}>
        <Row>
          <Col span={wibdowWidth < 768 ? 24 : 22}>
            <Title level={5}>Suggestion</Title>
            <Paragraph style={{ fontSize: 16 }}>{suggestion}</Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Suggestion;
