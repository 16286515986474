import React from 'react';
import { Row, Col, Typography, Space, Rate, Progress, Result } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';
import { ISkillsCareer } from '../../types/careerPaths';

const { WHITE, LAVENDER, LIGHT_VIOLET } = COLORS;
const { Title, Text } = Typography;

const AsnResult = styled(Result)`
  &.ant-result {
    padding: 0;
    .ant-result-icon {
      margin-bottom: 0;
      text-align: start;
    }
  }
  svg {
    height: 20px;
    width: 20px;
    fill: ${LAVENDER};
  }
`;

const AsnProgress = styled(Progress)`
  .anticon {
    display: none;
  }
`;

const Skills: React.FC<ISkillsCareer> = ({ skills, evaluations }) => {
  const wibdowWidth = window.innerWidth;
  return (
    <Row style={{ background: `${WHITE}`, padding: '32px 4%' }}>
      <Col span={24} xxl={{ pull: 2, push: 2, span: 20 }}>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Space direction='vertical' style={{ marginBottom: '0.5em' }}>
            <Title level={5}>Skills</Title>
            <Title level={5}>
              This information outlines the importance of skills using star ratings and quantifies your proficiency level in
              percentage
            </Title>
          </Space>
          <Space direction='vertical' style={{ width: '100%' }} size={wibdowWidth < 768 ? 24 : 8}>
            {skills?.map((skill, i) => (
              <Row key={i} justify={'space-between'} gutter={wibdowWidth < 768 ? [4, 4] : [16, 16]}>
                <Col span={wibdowWidth < 768 ? 24 : 11}>
                  <Text key={i} style={{ fontSize: 16 }}>
                    {skill?.title}
                  </Text>
                </Col>
                <Col span={wibdowWidth < 768 ? 24 : 5} style={{ textAlign: wibdowWidth < 768 ? 'start' : 'end' }}>
                  <Rate disabled allowHalf defaultValue={skill?.value / 2} />
                </Col>
                <Col span={wibdowWidth < 768 ? 13 : 5} style={{ display: 'flex', alignItems: 'center' }}>
                  <AsnProgress
                    style={{ marginBottom: 0 }}
                    percent={evaluations[i]?.value * 10}
                    strokeColor={LAVENDER}
                    size={['100%', 10]}
                    trailColor={LIGHT_VIOLET}
                  />
                  {evaluations[i]?.value * 10 === 100 && <span>100%</span>}
                </Col>
                <Col span={wibdowWidth < 768 ? 11 : 3}>
                  {evaluations[i]?.value >= 7 ? (
                    <AsnResult status={'success'} />
                  ) : (
                    <Text style={{ marginBottom: 0 }}>need improvement</Text>
                  )}
                </Col>
              </Row>
            ))}
          </Space>
        </Space>
      </Col>
    </Row>
  );
};

export default Skills;
