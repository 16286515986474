import { Collapse } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';

const { WHITE } = COLORS;
const AsnCollapseMobaile = styled(Collapse)`
  &.ant-collapse-borderless {
    background: ${WHITE};
    max-width: 1200px;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    color: rgb(128, 128, 128);
  }
  &.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
    width: 28px;
    padding: 7px;
    svg {
      stroke: rgb(128, 128, 128);
    }
  }
`;

export default AsnCollapseMobaile;
