import { Row } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';

const { LAVENDER, INDIGO } = COLORS;

const Points = styled(Row)`
  justify-content: center;
  border: 1px solid ${LAVENDER};
  border-radius: 50%;
  color: ${INDIGO};
  font-size: 16;
  white-space: nowrap;
  width: ${(props): string => (props.content === 'profession' ? '70px' : '54px')};
  height: ${(props): string => (props.content === 'profession' ? '70px' : '54px')};
  align-items: center;
  flex-flow: inherit;
  font-size: 16px;
  font-weight: 600;
`;

export default Points;
