import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { message } from 'antd';

import client from '../client';
import { useAuth } from '../../hooks/useAuth';
import { TUseChangePassword, ChangePasswordVariables } from '../../types/api/auth';
import { ISuccesMessage } from '../../types/global';
import { PATHS } from '../../constants';

const url = '/api/auth/password';

const useChangePassword: TUseChangePassword = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, ChangePasswordVariables, unknown>({
    mutationFn: (values) => {
      return client.put(url, values);
    },
    onSuccess: (data) => {
      void message.success(data?.data?.result, 2);
      logout();
      navigate(PATHS.SIGNIN);
    },
    onError: (error) => {
      void message.error(error as unknown as string, 2);
    },
  });

  return mutation;
};

export default useChangePassword;
