import styled from 'styled-components';
import { Card, Space, Image, Typography, Row } from 'antd';
import dayjs from 'dayjs';

import { COLORS } from '../../constants';
import { ReactComponent as CalendarSvg } from '../../assets/icons/calendar.svg';
import { ReactComponent as OnlineSvg } from '../../assets/icons/online.svg';
import { ReactComponent as OfflineSvg } from '../../assets/icons/offline.svg';
import { ReactComponent as LocationSvg } from '../../assets/icons/location.svg';
import { IMainCard } from '../../types/components';

const { Paragraph, Text } = Typography;

const { LAVENDER } = COLORS;

const AsnCard = styled(Card)`
  &.ant-card-hoverable {
    min-width: 215px;
    border: 0.2px solid ${LAVENDER};
  }
  &.ant-card-bordered .ant-card-cover {
    margin: 0;
  }
  &.ant-card .ant-card-body {
    padding: 8px;
  }
`;

const MainCard: React.FC<IMainCard> = ({ title, price, img_url, source, date, status, url, address, location }) => {
  return (
    <AsnCard
      hoverable
      cover={<Image preview={false} alt='example' src={img_url} style={{ height: 140, objectFit: 'cover' }} />}
      onClick={(): Window | null => window.open(url, '_blank')}
    >
      <Space direction='vertical' style={{ width: '100%' }} size={0}>
        <Space style={{ width: '100%', justifyContent: 'space-between', height: 45, alignItems: 'baseline' }}>
          <Paragraph ellipsis={{ rows: 2 }} style={{ maxWidth: 140 }}>
            {source}
          </Paragraph>
          <Paragraph style={{ fontSize: 12, fontWeight: 600, color: `${LAVENDER}`, textAlign: 'end' }} ellipsis={{ rows: 2 }}>
            {price}
          </Paragraph>
        </Space>
        <Paragraph style={{ marginBottom: 16, fontWeight: 600, height: 45 }} ellipsis={{ rows: 2 }}>
          {title}
        </Paragraph>
        <Space direction='vertical' style={{ width: '100%', marginBottom: 16 }} size={0}>
          <Space
            direction='horizontal'
            style={date ? { width: '100%', justifyContent: 'space-between' } : { width: '100%', justifyContent: 'end' }}
          >
            {date && (
              <Space.Compact style={{ alignItems: 'center', gap: 2 }}>
                <CalendarSvg />
                <Text>{dayjs(date).format('YY-MM-DD')}</Text>
              </Space.Compact>
            )}
            {status && (
              <Space.Compact style={{ alignItems: 'center', gap: 2 }}>
                {status === 'offline' ? <OfflineSvg /> : <OnlineSvg />}
                <Text>{status}</Text>
              </Space.Compact>
            )}
          </Space>
          {(location || address) && (
            <Space.Compact style={{ alignItems: 'center', gap: 2 }}>
              <Row style={{ marginBottom: 23 }}>
                <LocationSvg />
              </Row>
              <Paragraph ellipsis={{ rows: 2 }} style={{ maxWidth: 180, marginBottom: 0, height: 45 }}>
                {address} {location}
              </Paragraph>
            </Space.Compact>
          )}
        </Space>
      </Space>
    </AsnCard>
  );
};

export default MainCard;
